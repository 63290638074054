import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/eventsApi";
import { useEffect, useState } from "react";
function AddEvents({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [name, setName] = useState(undefined);
  const [image, setImage] = useState(undefined);

  const queryClient = useQueryClient();

  const { data: Event } = useQuery(
    ["admin", id],
    () => api.getEvent(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateEvent, {
    onSuccess: () => {
      toast({
        title: "Successfully Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["Events"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // POST
  const { mutate: postMutate } = useMutation([], api.addEvent, {
    onSuccess: () => {
      toast({
        title: "Successfully Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["Events"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (Event) {
      setName(Event.name);   
    }
  }, [Event, Id]);

  async function handlePost() {
    const data = new FormData();
    data.append("name", name);
    data.append("image", image);
    postMutate(data);
  }

  function handleUpdate() {
    const data = new FormData();
    data.append("id", id);
    data.append("name", name);

   if(image) data.append("image", image);
    updateMutate(data);
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  function handleClose() {
    onClose();
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Event
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"md"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit Event" : "Add Event"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
                <InputLeftAddon children="Name:" />
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon>
                  {Event?.image ? (
                    <Image w={"30px"} h={"30px"} src={Event.image} alt="" />
                  ) : (
                    "Image:"
                  )}{" "}
                </InputLeftAddon>
                <Input
                  type="file"
                  pt={"1"}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </InputGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={() => handleUpdate()}
                bg={"green"}
                textColor={"white"}
              >
                Update <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() => handlePost()}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddEvents;
