import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/inqueryApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import dayjs from "dayjs";
import { AiOutlineOrderedList } from "react-icons/ai";

function ViewInquery({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [id, setId] = useState();

  //   Get Term Data for Edit

  const { data: Inquery } = useQuery(
    ["Inquery", id],
    () => api.getInquery(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  function handleClose() {
    onClose();
  }

  return (
    <>
      <Icon
        cursor={"pointer"}
        onClick={() => handleEdit()}
        as={AiOutlineOrderedList}
        w="6"
        h="6"
        fontSize={"bold"}
      />
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "View Inquery" : ""}</ModalHeader>
          <ModalCloseButton />

          {Inquery && (
            <ModalBody>
              <Tabs>
                <TabList>
                  <Tab>General info</Tab>
                  <Tab>Budget</Tab>
                  <Tab>Other</Tab>
                </TabList>
                {/* Name , Email , Phone , Desc */}
                <TabPanels>
                  <TabPanel>
                    <HStack
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"10px"}
                    >
                      <Input
                        placeholder="Full Name"
                        value={
                          Inquery.fullName.first + " " + Inquery.fullName.last
                        }
                        readOnly
                      />
                      <Input
                        placeholder="Email"
                        value={Inquery.email}
                        readOnly
                      />
                      <Input
                        placeholder="Phone Number"
                        value={Inquery.phoneNumber}
                        readOnly
                      />
                      <Input
                        placeholder="Description"
                        value={Inquery.description}
                        readOnly
                      />
                    </HStack>
                    {/* Number Of Guests */}
                    <Box my={5} mb={10}>
                      <Text my={3}>Number of guests:</Text>
                      <HStack spacing={5}>
                        <Box>
                          <Text my={2}>Adults:</Text>
                          <Input
                            type="number"
                            value={Inquery.numberOfGuests.adults}
                          />
                        </Box>
                        <Box>
                          <Text my={2}>Children:</Text>
                          <Input
                            type="number"
                            value={Inquery.numberOfGuests.childrens}
                          />
                        </Box>
                        <Box>
                          <Text my={2}>Guests:</Text>
                          <Input
                            type="number"
                            value={Inquery.numberOfGuests.infants}
                          />
                        </Box>
                      </HStack>
                      <Box my={5} mb={10}>
                        <Text my={3}>Destinations:</Text>
                        <ul>
                          {Inquery.destinations.map((d) => (
                            <li key={d._id}>{d.name}</li>
                          ))}
                        </ul>
                      </Box>
                    </Box>
                  </TabPanel>
                  {/* Budget */}
                  <TabPanel>
                    {Inquery.budget.isSpecific ? (
                      <Box my={5}>
                        <Text my={3}>Budget:</Text>
                        <HStack spacing={5}>
                          <Box>
                            <Text my={2}>Minimum:</Text>
                            <Input
                              type="number"
                              value={Inquery.budget.minimum}
                            />
                          </Box>
                          <Box>
                            <Text my={2}>Maximum:</Text>
                            <Input
                              type="number"
                              value={Inquery.budget.maximum}
                            />
                          </Box>
                        </HStack>
                      </Box>
                    ) : (
                      <Text fontSize={"lg"} align="center">
                        Budget is not specified
                      </Text>
                    )}
                  </TabPanel>
                  {/* Others */}
                  <TabPanel>
                    <Box mt={10}>
                      <HStack
                        alignItems={"center"}
                        justifyContent={"start"}
                        spacing={"20px"}
                      >
                        <Text>Date :</Text>
                        <Input
                          width={"20%"}
                          value={dayjs(Inquery.date).format("DD-MM-YYYY")}
                          readOnly
                        />
                      </HStack>
                    </Box>
                    {/* Vehicle */}
                    <Box mt={10}>
                      <HStack
                        alignItems={"center"}
                        justifyContent={"start"}
                        spacing={"20px"}
                      >
                        <Box>
                          <Text mb={"1"}>Vehilce :</Text>
                          <Input value={Inquery.vehicle} readOnly />
                        </Box>
                        <Box>
                          <Text mb={"1"}>With Driver :</Text>
                          <Input
                            value={Inquery.driver === true ? "Yes" : "No"}
                            readOnly
                          />
                        </Box>
                      </HStack>
                    </Box>
                    {/* Acomodations */}
                    <Box mt={10}>
                      <HStack
                        alignItems={"center"}
                        justifyContent={"start"}
                        spacing={"20px"}
                      >
                        <Box>
                          <Text mb={"1"}>Acomodation :</Text>
                          <Input
                            value={Inquery.accomodation === true ? "Yes" : "No"}
                            readOnly
                          />
                        </Box>
                        <Box>
                          <Text mb={"1"}>Acomodation Type :</Text>
                          <Input value={Inquery.vehicle} readOnly />
                        </Box>
                      </HStack>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          )}
        </ModalContent>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default ViewInquery;
