import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import * as api from "../components/API/staysApi";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GlobalFilter } from "../components/GlobalFilter";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddModal from "../components/UI/AddStay";
import { FiTrash } from "react-icons/fi";
import EditStay from "../components/UI/EditStay";
import AddImages from "../components/UI/AddImages1";
import AddStayDetails from "../components/UI/AddStayDetails";
import ViewStayReport from "../components/UI/ViewStayReports";
import AlertDialog1 from "../components/UI/AlertDialogModal";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import AlertDialogModal from "../components/UI/AlertDialogModal";
function Stays() {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const toast = useToast();
  const { data: staysData } = useQuery("Stays", api.getStays);

  // Update
  const { mutate: updateMutate } = useMutation([], api.updateStay, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stays"]);
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteStay, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stays"]);
      setIsOpen(false);
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  async function handleDelete(row) {
    if (row.original._id) {
      deleteMutate(row.original._id);
    }
  }

  function handleOpenAlert(row) {
    setIsOpen(true);
    setSelectedRow(row);
  }

  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 100 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      tableHooks
    );

    const isEven = (idx) => idx % 2 === 0;
    // Render the UI for your table
    return (
      <Box
        ml={10}
        my={{ base: "24" }}
        minW={{ base: "77vw" }}
        maxW={{ base: "80vw" }}
      >
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          <AddModal />
        </Flex>
        {/* Table */}
        <Table className="table" {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }
  const productsColumns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Id",
      //   accessor: "_id",
      //   Cell: ({ value }) => <div>{`${value}`}</div>
      // },

      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Category",
        accessor: "category.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "City",
        accessor: "city.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Minimum Price",
        accessor: "minimumPrice",
        Cell: ({ value }) => <div>{`$${value}`}</div>,
      },
      {
        Header: "Enable",
        accessor: "enabled",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
    ];
    return columns;
  }, []);

  function handleToggle(row) {
    const data = {
      id: row.original._id,
      enabled: !row.values.enabled,
    };
    updateMutate(data);
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }) => (
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Icon
              cursor={"pointer"}
              boxSize={"5"}
              mx={2}
              onClick={() => handleToggle(row)}
              as={row.values.enabled ? BsEyeSlash : BsEye}
            />
            {row && <ViewStayReport Id={row.original._id} />}
            {row && <AddStayDetails Id={row.original._id} />}
            {row && (
              <AddImages
                Id={row.original._id}
                type={"stays"}
                oldImages={row.original.images}
              />
            )}
            {row && (
              <EditStay
                key={row.original._id}
                Id={row.original._id}
                Edit={true}
              />
            )}

            <Icon
              boxSize={"5"}
              cursor={"pointer"}
              mx={2}
              as={FiTrash}
              onClick={() => handleOpenAlert(row)}
            />
          </Flex>
        ),
      },
    ]);
  };

  return staysData ? (
    <Box>
      {/* {AlertDialogExample(isOpen,setIsOpen,onClose,cancelRef,handleDelete,selectedRow)} */}
      <AlertDialogModal
        onClose={onClose}
        isOpen={isOpen}
        handleDelete={handleDelete}
        setIsOpen={setIsOpen}
        cancelRef={cancelRef}
        row={selectedRow}
      />
      <RTable columns={productsColumns} data={staysData} />
    </Box>
  ) : (
    <div></div>
  );
}

function AlertDialogExample(
  isOpen,
  setIsOpen,
  onClose,
  cancelRef,
  handleDelete,
  row
) {
  return (
    <>
      {/* <Icon boxSize={"5"} cursor={"pointer"} mx={2} as={FiTrash} onClick={() => setIsOpen(true) } /> */}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete(row)}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default Stays;
