import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
    Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";

import { useMutation,  useQueryClient } from "react-query";


import DatePicker from "react-multi-date-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import * as api from '../API/toursApi'
import { useState } from "react";
import { FiEdit } from "react-icons/fi";

function EditTourDate({tourDetails}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [startDate,setStartDate] = useState()
  const [startTime,setStartTime] = useState()
  const [endTime,setEndTime] = useState()
  const queryClient = useQueryClient();

  

//POST
const { mutate : postMutate  } = useMutation([],api.updateTourDate, {
    onSuccess:
      () => {
        //   queryClient.invalidateQueries(["Tours"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })




  
  
    async function handleUpdate() {

        const data = {
            tourDateId:id,
            day:startDate,
            departureTime:startTime,
            returnTime:endTime
        }
     
        postMutate(data)

    }


    function handleEdit(){
      onOpen()
      setId(tourDetails._id)
      setStartTime(tourDetails.departureTime)
      setEndTime(tourDetails.returnTime)
      setStartDate(tourDetails.day)
    
    }

  return (
    <>
  
     
         <IconButton size='md' onClick={() => handleEdit()} icon={<FiEdit />} mr="5" />

      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add'} Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
            <InputGroup alignItems={"center"}>
              <InputLeftAddon children="Start time" />
              <Flex alignItems={"center"} py="1" border="1px">
              <Icon as={AiOutlineCalendar} w="7" h="7"/> 
          <DatePicker

          format="MM/DD/YYYY"
         style={{border:"0",overflow:"hidden"}}
            value={startDate} 
        onChange={setStartDate}
        placeholder="Select a date"
  />
  </Flex>
 </InputGroup>
              
              <InputGroup>
              <InputLeftAddon children="Start time" />
                <Input type="text" placeholder="Start Time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />  
                </InputGroup>
                <InputGroup>
              <InputLeftAddon children="Return time" />
                <Input type="text" placeholder="Return Time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />  
                </InputGroup>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          
            <Button variant="outline" onClick={() => handleUpdate()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default EditTourDate;
