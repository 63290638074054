import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/tourBooking/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getBookings = async() =>  await api.get('').then(res => res.data.tourBookings)

export const getBookingById = async(id) =>  await api.get('/'+id).then(res => res.data.tourBooking)