import * as React from "react";
import { List, arrayMove } from "react-movable";
import { Icon, PlusSquareIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Box,
} from "@chakra-ui/react";

import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import * as api from "../API/teamApi";
import {  useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";


const SortTeam = ({ data, Edit, Id }) => {
  const [id, setId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  
  const [items, setItems] = useState(data);
  
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation([], api.sortTeamMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Teams"]);
      toast({
        title: 'Order resort successfully',
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  function handleEdit() {
    onOpen();
    setId(Id);
    setItems(data)
  }
  function handleClose() {
    onClose();
  }

  async function handlePost() {
    let arr = [];

    items.map((d,i) =>(
        arr[i] = {order:i+1,_id:d._id}
    ))

    const data = {
        team:arr,
    };
    postMutate(data);
  }

  useEffect(() => {
    if(Id){
        setItems(data)
    }
  },[Id,data])
  return (
    <>
      
        <Button onClick={onOpen} bg={"#FED438"}>
          Sort
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      
      <Modal size={"md"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Drag To Sort</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List
              values={items}
              onChange={({ oldIndex, newIndex }) =>
                setItems(arrayMove(items, oldIndex, newIndex))
              }
              renderList={({ children, props }) => (
                <ul {...props}>{children}</ul>
              )}
              renderItem={({ value, props }) => <li {...props}  >
                  <Box cursor="draggable" >
                  {value.name}
                  </Box>
                  </li>}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            
              <Button
                variant="outline"
                onClick={() => handlePost()}
                bg={"purple.400"}
                textColor={"white"}
              >
                Resort <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SortTeam;
