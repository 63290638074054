import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  InputLeftAddon,
  InputGroup,
  useDisclosure,
  Image,
  VStack,
  useToast,
  Text,
  Box,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/blogsApi";
import { useEffect, useState, useRef } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Editor } from "@tinymce/tinymce-react";

function EditBlog({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const queryClient = useQueryClient();
  const [nbPosts, setNbPosts] = useState(1);
  const editorRef = useRef(null);

  //POST
  const { mutate: postMutate } = useMutation([], api.addBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      onClose();
    },
  });
  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteImageBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      toast({
        title: "Removed successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  // GET BY ID
  const { data: blog } = useQuery(
    ["blog", id],
    () => api.getBlogById(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  useEffect(() => {
    if (blog) {
      setTitle(blog.title);
    }
    if (Edit) {
      setNbPosts(blog?.images.length);
    }
  }, [Id, blog]);

  function handleUpdate() {
    const data = new FormData();
    data.append("id", id);
    data.append("title", title);
    data.append("content", editorRef.current.getContent());
    [...Array(nbPosts)].map(
      (_, i) => (
        data.append("photos", document.getElementById("image" + i).files[0]),
        data.append("captions", [document.getElementById("caption" + i).value])
      )
    );
    updateMutate(data);
  }
  function handleDelete(imageId) {
    const data = {
      id,
      imageId: imageId,
    };

    deleteMutate(data);
  }
  return (
    <>
      {Edit === true ? (
        <Icon
          cursor={"pointer"}
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"blue.100"}>
          Add Blog
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal
        size={"full"}
        isOpen={isOpen}
        onClose={onClose}
        disableEnforceFocus={true}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit" : "Add"} Blog</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup>
              <InputLeftAddon children="Title:" />
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputGroup>
            {/* Info */}
            <Text mt={"30px"} fontWeight={"bold"} fontSize={"30px"}>
              Images and captions
            </Text>
            {blog &&
              blog.images &&
              blog.images.map((image, i) => (
                <HStack
                  alignItems={"start"}
                  justifyContent={"start"}
                  spacing={"10px"}
                  mt={10}
                >
                  <InputGroup>
                    {Edit && (
                      <InputLeftAddon>
                        <Image
                          src={image.imageUrl}
                          w="10"
                          h="10"
                          rounded={"full"}
                        />
                      </InputLeftAddon>
                    )}
                    <Input id={"image" + i} type="file" pt={"1"} />
                  </InputGroup>
                  <Input
                    id={"caption" + i}
                    type="text"
                    placeholder="Caption"
                    defaultValue={image.caption}
                  />
                  <Flex alignItems={"center"}>
                    <EditImage
                      Id={id}
                      image={image.imageUrl}
                      caption={image.caption}
                      imageId={image._id}
                    />
                    <Icon
                      as={AiOutlineDelete}
                      size={6}
                      cursor="pointer"
                      ml={2}
                      onClick={() => {
                        handleDelete(image._id);
                      }}
                    />
                  </Flex>
                </HStack>
              ))}
            <Box mt={"7%"} mx={"5%"} w="70%">
              <Text mb={"30px"} fontWeight={"bold"} fontSize={"30px"}>
                Content
              </Text>
              <Editor
                disabled={false}
                apiKey={"ztvmz6lwnmoiq8bqlmnv2ju7oj4csno3k4vqhuodpl2q0ju6"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={blog?.content ? blog.content : ""}
                init={{
                  height: 500,
                  menubar: true,
                  selector: "textarea",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />

              <Flex justifyContent={"space-between"}>
                {/* <Image cursor={"pointer"} onClick={() => document.getElementById("image").click()} src={newImage ? newImage : data?.image} w={"50%"} h="450px"  /> */}
                {/* <Input href={missionImage} display={"none"} type="file" onChange={(e) => handleChange(e) } id="image" /> */}
              </Flex>
              <Flex my={"5"} alignItems={"center"}>
                <Button mr={"5"} onClick={() => handleUpdate()}>
                  Save
                </Button>
                {/* <Button
                  mr={"5"}
                  onClick={() => document.getElementById("image").click()}
                >
                  Upload Image
                </Button> */}
                {/* <AddMission image={data?.image} /> */}
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="outline"
              onClick={(e) => handleUpdate(e)}
              bg={"green"}
              textColor={"white"}
            >
              Update <Icon ml={2} as={PlusSquareIcon} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default EditBlog;

export function EditImage({ image, Id, caption, imageId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState();
  const [newCaption, setNewCaption] = useState();
  const queryClient = useQueryClient();

  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateImageBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      onClose();
    },
  });

  function handleEdit() {
    onOpen();
    setId(Id);
    setNewCaption(caption);
  }

  function handleUpdate() {
    const data = new FormData();
    data.append("id", id);
    data.append("imageId", imageId);
    data.append("caption", newCaption);
    if (image.slice(0, 5) !== "https") {
      data.append("photo", image);
    }
    updateMutate(data);
  }

  return (
    <>
      <Icon
        cursor={"pointer"}
        onClick={() => handleEdit()}
        as={FiEdit}
        ml={4}
        size={6}
        fontSize={"bold"}
      />
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Edit Image Blog"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10px"}
            >
              <InputGroup>
                <InputLeftAddon children="Name:" />
                <Input
                  placeholder="Caption"
                  type="text"
                  value={newCaption}
                  onChange={(e) => setNewCaption(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon>
                  <Image src={image} w="10" h="10" rounded={"full"} />
                </InputLeftAddon>
                <Input type="file" pt="1" placeholder="Image" />
              </InputGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="outline"
              onClick={(e) => handleUpdate(e)}
              bg={"green"}
              textColor={"white"}
            >
              Update <Icon ml={2} as={PlusSquareIcon} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
