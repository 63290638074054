import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsEye, BsEyeSlash, BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/adminApi";

import { useState } from "react";

function AddArea({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState(undefined)
  const [email,setEmail] = useState(undefined)
  const [password,setPassword] = useState(undefined)
  const [pType,setPType] = useState("password");
  const [phone,setPhone] = useState(undefined)
  const [role,setRole] = useState(1)
  const queryClient = useQueryClient();



  const { data : Admin } = useQuery(
    ['admin', id],
    () => api.getAdmin(id).finally(() => {
    }),
    {   
        
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.updateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Admins"]);
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

// POST
const { mutate : postMutate  } = useMutation([],api.addAdmin, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Admins"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

    async function handlePost() {
      if((name?.length > 1 || Admin?.fullName?.length > 1)  && (email?.length > 1 || Admin?.email?.length > 1) && (password?.length > 1 || Admin?.password?.length > 1)){

        const data = {
        id:Id,
       fullName:name,
       email:email,
       password:password,
       phoneNumber:phone,
       isEmailVerified:true,
       role:role
      };
      if(Edit){
          updateMutate(data)
    }
    else{
        postMutate(data)
    }
    }
    }

    function handleEdit(){
        onOpen()
        setId(Id)
      }
   
  return (
    <>
        {Edit === true ? 
        <Icon onClick={() => handleEdit()} as={FiEdit} cursor={"pointer"} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Admin
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <Input placeholder="Name" value={name === undefined ? Admin?.fullName : name} onChange={(e) => setName(e.target.value)} />
              <Input type={"email"} placeholder="Email" value={email === undefined ? Admin?.email : email} onChange={(e) => setEmail(e.target.value)} />
              <Input type={"number"} placeholder="Phone" value={phone === undefined ? Admin?.phoneNumber : phone} onChange={(e) => setPhone(e.target.value)} />
              <InputGroup>
              <Input type={pType} placeholder="Password" value={password === undefined ? Admin?.password : password} onChange={(e) => setPassword(e.target.value)} />
              <InputRightAddon> {pType === "password" ? <BsEye onClick={() => setPType("text")} cursor="pointer" /> : <BsEyeSlash onClick={() => setPType("password")} cursor="pointer" /> } </InputRightAddon>
              </InputGroup>
              <Select onChange={(e) => setRole(e.target.value)}>
                  <option value={1}>Admin</option>
                  <option value={2}>Editor</option>
                  <option value={3}>Viewer</option>
              </Select>
              
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>{Edit ? 'Update' : 'Add'}<Icon ml={2} as={PlusSquareIcon} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
}
export default AddArea;
