import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/tourInquiry`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getInqueries = async() =>  {
    const data = await api.get('')
    const res = (data.data.tourInquiries)
  return res
}


export const getInquery = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.tourInquiry)
}

export const updateInquery = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const addInquery = async(body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteInquery = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}