import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/areas/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getAreasByCountryId = async(countryId) =>  {
 return   api.get(`country?countryId=${countryId}`).then((res) => res.data.areas)
}
export const getArea = async() => {
    return api.get('admin/all').then(res => res.data.areas)
}
export const getAreaById = async(id) => {
    return api.get('/'+id).then(res => res.data.area)
}

 export const addArea = (body) => {
  return api.post('',body).then((res) => res.data)
}
export const editArea = (body) => {
    return api.put(''+body.id,body).then((res) => res.data)
  }


export const deleteArea = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}