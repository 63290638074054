import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsCalendarDate } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {  useState } from "react";
import DatePicker from "react-multi-date-picker";
import { AiOutlineCalendar } from "react-icons/ai";
import * as api from '../API/toursApi'
import * as currenciesApi from "../API/currencyApi";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
function AddTourDates({Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [startDate,setStartDate] = useState()
  const [startTime,setStartTime] = useState()
  const [endTime,setEndTime] = useState()
  const [currencyId, setCurrencyId] = useState()
  
  const [amount, setAmount] = useState({
    adults: "",
    infants: "",
    childrens: ""
  });
  const queryClient = useQueryClient();
  const { data: currencies } = useQuery(
    "currencies",
    currenciesApi.getCurrencies
  );
  

//POST
const { mutate : postMutate  } = useMutation([],api.addTourDate, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Tours"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })


    
    async function handlePost() {

        const data = {
            tourId:id,
            dates:startDate,
            info:{
            departureTime:startTime,
            returnTime:endTime,
            price: { amount: amount, currency: currencyId },
          }
        }
     
        postMutate(data)

    }


    function handleEdit(){
      onOpen()
      setId(Id)
    
    }

  return (
    <>
  
     
        <Icon cursor="pointer"  onClick={() => handleEdit()} as={BsCalendarDate} ml={4} size={5} fontSize={"bold"} />

      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add'} Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
            <InputGroup alignItems={"center"}>
              <InputLeftAddon children="Day" />
              <Flex alignItems={"center"} py="1" border="1px">
              <Icon as={AiOutlineCalendar} w="7" h="7"/> 
          <DatePicker

          format="MM/DD/YYYY"
         style={{border:"0",overflow:"hidden"}}
            value={startDate} 
            multiple
        onChange={setStartDate}
        placeholder="Select a date"
        plugins={[
          <DatePanel Toolbar markFocused />,
          <Toolbar
            position="bottom"
            names={{
              today: "Select Today",
              deselect: "Reset",
              close: "Close"
            }}
          />
        ]}
  />
  </Flex>
 </InputGroup>
              
              <InputGroup>
              <InputLeftAddon children="Start time" />
                <Input type="text" placeholder="Start Time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />  
                </InputGroup>
                <InputGroup>
              <InputLeftAddon children="Return time" />
                <Input type="text" placeholder="Return Time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />  
                </InputGroup>
                {/* Price */}
            <Box>
              <Text my={5}>Pirce</Text>
              <VStack mb={10} spacing={5}>
                <Box>
                  <Input
                    type="number"
                    placeholder="Adults"
                    value={amount.adults}
                    onChange={(e) =>
                      setAmount({
                        ...amount,
                        adults: parseInt(e.target.value)
                      })
                    }
                  />
                  </Box>
                  <Box>
                <Input
                    type="number"
                    placeholder="Children"
                    value={amount.childrens}
                    onChange={(e) =>
                      setAmount({
                        ...amount,
                        childrens: parseInt(e.target.value)
                      })
                    }
                  />
                  </Box>
                  <Box>
                <Input
                    type="number"
                    placeholder="Infants"
                    value={amount.infants}
                    onChange={(e) =>
                      setAmount({
                        ...amount,
                        infants: parseInt(e.target.value)
                      })
                    }
                  />
                  </Box>
                <Box >
                  <Select
                    value={currencyId}
                    onChange={(e) => setCurrencyId(e.target.value)}
                  >
                    <option>Select a Currency</option>
                    {currencies?.map((curr) => (
                      <option value={curr._id} key={curr._id}>
                        {curr.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </VStack>
            </Box>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddTourDates;
