import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/highlightApi";

import { useEffect, useState } from "react";

function AddHighlight({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [desc, setDesc] = useState();
  const [image, setImage] = useState();
  const queryClient = useQueryClient();
  const { data: Highlight } = useQuery(
    ["highlight", id],
    () => api.getHighlightById(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateHighlight, {
    onSuccess: () => {
      queryClient.invalidateQueries(["AllHighlights"]);
      toast({
        title: "Added Successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  // POST
  const { mutate: postMutate } = useMutation([], api.addHighlight, {
    onSuccess: () => {
      queryClient.invalidateQueries(["AllHighlights"]);
      toast({
        title: "Added Successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  async function handlePost(event) {
    event.preventDefault();
    const data = new FormData();
    data.append("id", Id);
    data.append("name", name);
    data.append("description", desc);
    if(image?.slice(0,5) !== 'https') data.append("image", image);

    if (Edit) {
      updateMutate(data);
    } else {
      postMutate(data);
    }
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  useEffect(() => {
    if (Edit && Highlight) {
      setName(Highlight.name);
      setDesc(Highlight.description);
      setImage(Highlight.image);
    }
  }, [Highlight]);

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          cursor={"pointer"}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Highlight
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handlePost}>
        <ModalContent>
          <ModalHeader>
            {Edit ? "Edit Highlights" : "Add Highlights"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
               <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                  <FormLabel>Description</FormLabel>
                <Textarea
                  rows={"2"}
                  placeholder="Description"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                ></Textarea>
              </FormControl>
              <FormControl>
                  <FormLabel>Image</FormLabel>
                <Input
                  placeholder="Image"
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="outline"
              type="submit"
              bg={"green"}
              textColor={"white"}
            >
              {Edit ? "Update" : "Add"}
              <Icon ml={2} as={PlusSquareIcon} />
            </Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    </>
  );
}
export default AddHighlight;
