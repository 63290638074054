import { useEffect, useState } from "react";
import firebase from "firebase";
import { db } from "../firebase";
import dayjs from "dayjs";
import logo from "../assets/images/minilogo.png";
import avatar from "../assets/images/avatar.svg";
import { Box, Flex, Text, Image, IconButton, Textarea } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { FiSend } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../components/config";

function MessageDetails() {
  const token = Cookies.get("admin-token");
  const { id } = useParams();
  const [message, setMessage] = useState();
  const [posts, setPosts] = useState([]);
  //  const [userrr] = useAuthState(auth)

  function handlePost() {
    const currentDate = new Date();
    db.collection("Chats")
      .doc(id)
      .collection("Messages")
      .add({
        _id: new Date().valueOf().toString(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        text: message,
        user: { name: "cheez", _id: "cheez" },
        // photoURL:user.photoURL,
      });
    db.collection("Chats")
      .doc(`${id}`)
      .set({
        _id: `${id}`,
        members: ["cheez", id.slice(6, id.length)],
        lastMessage: message,
        lastUpdated: currentDate,
        username: "Cheez",
        photoUrl: "",
        newForCheez: false,
        newForUser: true,
      });
    axios.post(
      `${baseURL}/v1/notifications/message`,
      { receiver: id.slice(6, id.length), lastMessage: message },
      {
        headers: {
          authorization: token,
        },
      }
    );
    setMessage("");
  }

  useEffect(() => {
    if (id) {
      return db
        .collection("Chats")
        .doc(id)
        .collection("Messages")
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          const postData = [];
          snapshot.forEach((doc) =>
            postData.push({ ...doc.data(), id: doc.id })
          );
          setPosts(postData);
        });
    }
  }, [id]);

  return (
    <Box mt={"7%"} mx={"5%"} w="70vw">
      <Text>Guest Name :{posts[0]?.user?.name}</Text>
      <Box
        h={"65vh"}
        overflowY={"scroll"}
        border={"1px"}
        rounded={"md"}
        py={5}
        px={5}
      >
        {posts?.map((post) => (
          <Flex
            alignItems={"center"}
            justifyContent={post.user._id === "cheez" ? "end" : "start"}
          >
            <Image mr={1} src={post.user._id === "cheez" ? logo : avatar} />
            <Box>
              <Text
                bg={post.user._id === "cheez" ? "#f2f8a4" : "gray.200"}
                rounded={"full"}
                py={1}
                px={2}
              >
                {post.text}
              </Text>
              <Text fontSize={"10px"} ml={1} fontWeight={"300"}>
                {dayjs(post.createdAt?.seconds * 1000).format("DD/MM HH:mm")}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
      <Flex alignItems={"center"}>
        <Textarea
          mt={3}
          mr={2}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          border={"1px"}
          w={"100%"}
          rounded={"md"}
        ></Textarea>
        <IconButton onClick={() => handlePost()} size="md" icon={<FiSend />} />
      </Flex>
    </Box>
  );
}

export default MessageDetails;
