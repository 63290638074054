import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/bathrooms`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getBathrooms = async() =>  api.get().then((res) => res.data.bath)



export const getBathroomById = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.bath)
}


export const addBathroom = (body) => {
  return  api.post('',body).then((res) => res.data)
}

export const updateBathroom = (body) => {
  return  api.put(`${body.id}`, body).then((res) => res.data)
} 



export const deleteBathroom  = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}