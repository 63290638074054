import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/teamApi";
import {  useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddTeam({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
const [name,setName] = useState("")
const [role,setRole] = useState("")
// const [order,setOrder] = useState("")
const [image,setImage] = useState("")
  const [id,setId] = useState()
  const queryClient = useQueryClient();

//   Get Term Data for Edit

  const { data : TM } = useQuery(
    ['TM', id],
    () => api.getTeamMemberById(id).finally(() => {
       
    }),
    {
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.editTeamMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(["TMs"]);
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }) 
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  useEffect(() => {
    if(Edit && TM){
        setName(TM.name)
        setRole(TM.role)
        // setOrder(TM.order)
        // setImage(TM.image)
        
    }
  },[TM,Edit])

//POST
const { mutate : postMutate  } = useMutation([],api.addTeamMember, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["TMs"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
           onClose()
    },
    onError:(error) => {
    
      toast({
        title: 'Image is Too Big',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  function handleChange(e) {
    setImage(e.target.files[0])
  }

  function handleUpdate(){
    const data = new FormData()
        data.append('id',Id)
        data.append('name',name)
        data.append('role',role)
        data.append('image',image)
        // data.append('order',order)
    
    updateMutate(data)
  }

  function handleEdit(){
    onOpen()
    setId(Id)
  }
  function handleClose(){
      onClose()
      setName("")
      setRole("")
      // setOrder("")
      setImage("")
  }

    async function handlePost() {
        const data = new FormData()
          data.append('name',name)
          data.append('role',role)
          data.append('image',image)
          // data.append('order',order)

        postMutate(data)
    }
   
  return (
    <>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen}  bg={'#FED438'}>
        Add Member
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
        }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Team Member' : 'Add Team Member'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
              <InputLeftAddon children="Name:" />
              <Input placeholder="Name" defaultValue={TM?.name} onChange={(e) => setName(e.target.value)}  />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon children="Role:" />
              <Input placeholder="Role" defaultValue={TM?.role} onChange={(e) => setRole(e.target.value)}  />
              </InputGroup>
              {/* <InputGroup>
              <InputLeftAddon children="Order:" />
              <Input placeholder="Order" type="number" defaultValue={TM?.order} onChange={(e) => setOrder(e.target.value)}  />
              </InputGroup> */}
              <InputGroup>
              <InputLeftAddon >{TM?.image ?  <Image src={TM?.image} w={"37px"} h={"37px"} rounded={"full"} /> : 'Image:' }</InputLeftAddon>
                <Input type="file" name={TM?.image} placeholder="Image Url" pt={"1"}   onChange={(e) => handleChange(e)}   />
                </InputGroup>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() =>handleClose()}>
              Close
            </Button>
           { Edit ?
            <Button variant="outline" onClick={() => handleUpdate()} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddTeam;
