import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/events/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getEvents = async() =>  await api.get('').then(res => res.data.events)


export const getEvent = async(id) => {
  return  api.get(`/${id}`).then((res) => res.data.event)
}

export const addEvent = async(body) => {
    return api.post('',body).then((res) => res.data)
}

export const updateEvent = async(body) => {
  return  api.put(`/${body.get('id')}`, body).then((res) => res.data)
} 

export const deleteEvent = async(id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}

