import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";

import { Link, useLocation } from "react-router-dom";


function SidebarElement({ title, link,setShowMore,icon }) {
  
  const location = useLocation();
  let urlName = window.location.pathname;
  useEffect(() => {
    if(urlName === '/'+link){
        setShowMore({line:0,status:false})
    }
  }, [location]);
  return (
    <Box py={2} w={"15vw"} px={2}>
      <Box
        bg={urlName === "/" + link  && "yellow.300"}
        py={3}
        rounded={"lg"}
      >
       
          <Link to={`${link}`} borderbottom={"2px"} bordercolor={"white"}>
            <Flex
              textColor={urlName === "/" + link ? "black" : "white"}
              alignItems={"center"}
              px={"15px"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"15"} fontWeight={"semibold"}>
                {" "}
                {title}
              </Text>
              <Icon
                as={icon}
                w={6}
                h={6}
                color={urlName === "/" + link ? "black" : "white"}
              />
            </Flex>
          </Link>
        
      </Box>
    </Box>
  );
}

export default SidebarElement;
