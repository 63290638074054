import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as cancelApi from "../API/cancelationApi";
import * as api from "../API/productsApi";
import * as catApi from "../API/categoryApi";
import * as suppApi from "../API/suppliersApi";
import * as currenciesApi from "../API/currencyApi";

import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

function AddProduct({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState();
  const [categoryId, setCategoryId] = useState("");
  const [story, setStory] = useState();
  const [title, setTitle] = useState();
  const [usage, setUsage] = useState();
  const [ingredients, setIngredients] = useState();
  const [images, setImages] = useState();
  const [supplierId, setSupplierId] = useState();
  const [sizeCounter, setSizeCounter] = useState(1);
  const [policyId, setPolicyId] = useState();
  const [rules, setRules] = useState();
  const [sale, setSale] = useState({ salePercent: 0, onSale: false });
  const toast = useToast();

  const queryClient = useQueryClient();

  const { data: product } = useQuery(
    ["Product", id],
    () => api.getProduct(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  const { data: categories } = useQuery(
    "categories",
    catApi.getProductsCategories
  );

  const { data: suppliers } = useQuery("suppliers", suppApi.getSuppliers);
  const { data: currencies } = useQuery(
    "currencies",
    currenciesApi.getCurrencies
  );
  const { data: cancelations } = useQuery(
    "cancelations",
    cancelApi.getCancelations
  );

  const { mutate: postMutate } = useMutation([], api.addProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Products"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { mutate: updateMutate } = useMutation([], api.updateProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Products"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (Edit && product) {
      setTitle(product.title);
      setStory(product.story);
      setUsage(product.usage);
      setIngredients(product.ingredients);
      setCategoryId(product.category._id);
      setSupplierId(product.supplier._id);
      setSizeCounter(product.sizes.length);
      setPolicyId(product.cancelationPolicy);
      setRules(product.rules);
      setSale({
        salePercent: Number(product?.sale?.salePercent * 100),
        onSale: product.sale?.onSale,
      });
    }
  }, [product, Edit]);

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  const handleAddingProduct = (event) => {
    event.preventDefault();
    let arr = [];
    let quantity = 0;
    [...Array(sizeCounter)].map((_, i) => {
      arr[i] = {
        
        size: document.getElementById(`size${i}`).value,
        quantity: document.getElementById(`quantity${i}`).value,
        price: {
          amount: document.getElementById(`amount${i}`).value,
          currency: document.getElementById(`currency${i}`).value,
        },
      };
      quantity += document.getElementById(`quantity${i}`).value;
    });

    const body = {
      id,
      title,
      images,
      story,
      usage,
      ingredients,
      rules,
      sale: {
        salePercent: sale.salePercent / 100,
        onSale: sale.onSale,
      },
      quantity: quantity,
      category: categoryId,
      supplier: supplierId,
      cancelationPolicy: policyId,
      sizes: arr,
      new: true,
    };
    postMutate(body);
  };

  const handleUpdateProduct = (event) => {
    event.preventDefault();
    let arr = [];
    let quantity = 0;
    [...Array(sizeCounter)].map((_, i) => {
      arr[i] = {
        _id :document.getElementById(`sizeId${i}`).value,
        size: document.getElementById(`size${i}`).value,
        quantity: document.getElementById(`quantity${i}`).value,
        price: {
          amount: document.getElementById(`amount${i}`).value,
          currency: document.getElementById(`currency${i}`).value,
        },
      };
      quantity += document.getElementById(`quantity${i}`).value;
    });

    const body = {
      id,
      title,
      images,
      story,
      usage,
      ingredients,
      rules,
      quantity: quantity,
      category: categoryId,
      supplier: supplierId,
      sale: {
        salePercent: sale.salePercent / 100,
        onSale: sale.onSale,
      },
      cancelationPolicy: policyId,
      sizes: arr,
      new: true,
    };
    updateMutate(body);
  };

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          cursor={"pointer"}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Product
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={Edit ?   handleUpdateProduct : handleAddingProduct}>
          <ModalContent>
            <ModalHeader>{Edit ? "Edit Product" : "Add Product"  }</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              {/* Info */}
              <VStack
                alignItems={"start"}
                justifyContent={"start"}
                spacing={"15px"}
              >
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Story</FormLabel>
                  <Textarea
                    placeholder="Story"
                    value={story}
                    onChange={(e) => setStory(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Usage</FormLabel>
                  <Input
                    placeholder="Usage"
                    value={usage}
                    onChange={(e) => setUsage(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Ingredients</FormLabel>
                  <Input
                    placeholder="ingredients"
                    value={ingredients}
                    onChange={(e) => setIngredients(e.target.value)}
                  />
                </FormControl>
              </VStack>
              <HStack alignItems={"end"} my={10} spacing={10}>
                {/* is It on Sale ? */}
                <Box>
                  <Checkbox
                    defaultChecked={sale.onSale}
                    value={sale.onSale}
                    onChange={(e) =>
                      setSale({ ...sale, onSale: e.target.checked })
                    }
                  >
                    On Sale
                  </Checkbox>
                </Box>
                {/* Sale Percent */}
                <Box>
                  <InputGroup>
                    <InputLeftAddon children="Sale %" />
                    <Input
                      value={sale.salePercent}
                      pl={2}
                      type="number"
                      placeholder="Sale Percent:"
                      onChange={(e) =>
                        setSale({ ...sale, salePercent: e.target.value })
                      }
                    />
                  </InputGroup>
                </Box>
              </HStack>
              {/* Cancelation Policy */}
              <Box w="sm">
                <FormControl isRequired>
                  <FormLabel>Cancel Policy</FormLabel>
                  <Select
                    value={policyId}
                    w={"50%"}
                    onChange={(e) => setPolicyId(e.target.value)}
                  >
                    <option>Select Policy</option>
                    {cancelations?.map((curr) => (
                      <option value={curr._id} key={curr._id}>
                        {curr.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* Category and Suppliers */}
              <HStack spacing={7} w="50%" mt={"5"}>
                {/* Category */}
                <FormControl isRequired>
                  <FormLabel>Category</FormLabel>
                  <Select
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {categories?.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {/* Suppliers */}
                <FormControl isRequired>
                  <FormLabel>Supplier</FormLabel>
                  <Select
                    value={supplierId}
                    onChange={(e) => setSupplierId(e.target.value)}
                  >
                    <option value="">Select Supplier</option>
                    {suppliers?.map((sup) => (
                      <option key={sup._id} value={sup._id}>
                        {sup.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>

              <FormControl isRequired w="60%" mt={"5"}>
                <FormLabel>Rules</FormLabel>
                <Textarea
                  placeholder="Rules"
                  value={rules}
                  onChange={(e) => setRules(e.target.value)}
                />
              </FormControl>

              {/* Itinerary */}
              <Box>
                {[...Array(sizeCounter)].map((_, i) => (
                  <Box key={"size" + i}>
                    <Text my={5}>Size:</Text>
                    <HStack spacing={7} mt={5} alignItems={"end"}>
                      <Input hidden id={`sizeId${i}`} defaultValue={product?.sizes[i]?._id} />
                      <FormControl isRequired>
                        <FormLabel>Size</FormLabel>
                        <Input
                          id={`size${i}`}
                          defaultValue={product?.sizes[i]?.size}
                          placeholder="Size"
                          px={2}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel>Quantity</FormLabel>
                        <Input
                          type="number"
                          id={`quantity${i}`}
                          defaultValue={product?.sizes[i]?.quantity}
                          placeholder="Quantity"
                          px={2}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel>Amount</FormLabel>
                        <Input
                          id={`amount${i}`}
                          defaultValue={product?.sizes[i]?.price?.amount}
                          placeholder="amount"
                          px={2}
                        />
                      </FormControl>
                      {Edit ? (
                        product?.sizes && (
                          <FormControl isRequired>
                            <FormLabel>Currency</FormLabel>
                            <Select
                              defaultValue={
                                product?.sizes[i]?.price?.currency?._id
                              }
                              id={`currency${i}`}
                            >
                              <option>Select Currency</option>
                              {currencies?.map((c) => (
                                <option value={c._id} key={c._id}>
                                  {c.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )
                      ) : (
                        <Select
                          defaultValue={product?.sizes[i]?.price?.currency?._id}
                          id={`currency${i}`}
                        >
                          <option>Select Currency</option>
                          {currencies?.map((c) => (
                            <option value={c._id} key={c._id}>
                              {c.name}
                            </option>
                          ))}
                        </Select>
                      )}
                      <Flex>
                        <Button
                          mr={2}
                          colorScheme={"green"}
                          onClick={() => setSizeCounter((prev) => prev + 1)}
                        >
                          <Icon as={BsPlusCircle} />
                        </Button>
                        <Button
                          onClick={() => setSizeCounter(sizeCounter - 1)}
                          display={sizeCounter === 1 ? "none" : ""}
                          colorScheme={"red"}
                          ml={"2"}
                        >
                          <Icon as={AiOutlineDelete} />
                        </Button>
                      </Flex>
                    </HStack>
                  </Box>
                ))}
              </Box>
            </ModalBody>

            <ModalFooter mt={"5"}>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              {Edit ? (
                <Button
                  variant="outline"
                  type="submit"
                  // onClick={() => handleUpdateProduct()}
                  bg={"green"}
                  textColor={"white"}
                >
                  Update
                  <Icon ml={2} as={PlusSquareIcon} />
                </Button>
              ) : (
                <Button
                  variant="outline"
                  // onClick={() => handleAddingProduct()}
                  type="submit"
                  bg={"green"}
                  textColor={"white"}
                >
                  Add
                  <Icon ml={2} as={PlusSquareIcon} />
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
export default AddProduct;
