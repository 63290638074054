import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/calendardata`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getEvents = async() =>  await api.get('').then(res => res.data.holdEvents)
export const getEventsDetails = async() =>  await api.get('').then(res => res.data.holdEvents)

export const getStaysEvents = async() => await api.get('/stays/summury').then(res => res.data)

export const getStays = async() => await api.get('/stays/all').then(res => res.data.stays)
export const getFilteredStays = async(data) => await api.get('/stays/all'+data).then(res => res.data.stays)

export const getStay = (id) => {
  return  api.get(`admin/${id}`).then((res) => res.data)
}

export const addBlockedDates = (stay) => {
  return api.post('stay/blockedDates',stay).then((res) => res.data)
}


export const getBlockedDayByStayId = (body) => {
  return  api.get(`stay/blockedDates?stay=${body}`).then((res) => res.data)
} 
export const updateStayImage = (body) => {
  return  api.put(`admin/image/${body.get('id')}`, body).then((res) => res.data)
} 
export const addStay = (stay) => {
    return api.post('admin',stay).then((res) => res.data)
}

export const deleteStay = (id) => {
    return api.delete(`admin/${id}`).then((res) => res.data)
}

export const getUpComingStay = () => {
  return  api.get(`stay/upcoming`).then((res) => res.data.bookings)
}

export const getDepartureStay = () => {
  return  api.get(`stay/departure`).then((res) => res.data.bookings)
}

export const getUpComingTour = () => {
  return  api.get(`tour/upcoming`).then((res) => res.data.tours)
}

