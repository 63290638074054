import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
  baseURL : `${baseURL}/v1/guesthouses/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getGuestHouses = async() =>  await api.get('').then(res => res.data.guestHouses)


export const getGuestHouse = async(id) => {
  return  api.get(`/${id}`).then((res) => res.data)
}

export const addGuestHouse = async(body) => {
    return api.post('',body).then((res) => res.data)
}

export const updateGuestHouse = async(body) => {
  return  api.put(`/${body.get('id')}`, body).then((res) => res.data)
} 

export const deleteGuestHouse = async(id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}