import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/privacy-policy`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getPrivacies = async() => {
    return api.get().then(res => res.data.privacyPolicy)
}

export const getPrivacyById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.privacyPolicy)
   }

 export const addPrivacy = async(body) => {
  return api.post('',body).then((res) => res.data)
}
export const editPrivacy = async(body) => {
    return api.put('',body).then((res) => res.data)
  }


export const deletePrivacy = async(id) => {
    return api.delete(`${id}`).then((res) => res.data)
}