import { Box } from "@chakra-ui/react"
import S3FileUpload from "react-s3"
// import { config } from "../aws.config"
window.Buffer = window.Buffer || require("buffer").Buffer;
function UploadS3() {

     const config = {
    bucketName:'cheez-bucket',
    dirName:'Test', 
//    region:'us-east-1',
    region: "eu-central-1",
    accessKeyId:'AKIA6E2DJ6ZJIKQ7ROP6',
    secretAccessKey:'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'
   

}

    const handleUpload = (e) => {
        S3FileUpload.uploadFile(e.target.files[0],config).then((data) => {
        })
    }
    return (
        <Box ml={"64"} my={"24"}>
         <h1>Upload your image</h1>            
         <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleUpload(e)} />
        </Box>
    )
}

export default UploadS3
