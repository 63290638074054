import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Text,
    Image,
    Flex,
    Stack,
    Box,
  } from '@chakra-ui/react'
  import dayjs from 'dayjs'
import { useRef } from 'react'
function BookingShowMore({events,onOpen,isOpen,onClose}) {
    const firstField = useRef()
    // const dateA = new Date(event?.endDate)
    // const dateB = new Date(event?.startDate)
    // const diffTime = Math.abs(dateA - dateB );
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return (
      <>
        <Drawer
          isOpen={isOpen}
          placement='right'
          initialFocusRef={firstField}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px'>
                <Flex alignItems={"center"} justifyContent={"space-between"} pr={"6"}>
              <Text>{events?.notes?.length > 0  ? "Blocked Date" : "More Booking"}</Text>  <Image src={"https://images.unsplash.com/photo-1590490360182-c33d57733427?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGhvdGVsJTIwcm9vbXxlbnwwfHwwfHw%3D&w=1000&q=80"} w={"14"} h={"14"} rounded={"full"} /> 
              </Flex>
            </DrawerHeader>
  
            <DrawerBody>
                {!events.notes  && events && events?.map(event => (
              <Stack key={event._id} spacing='24px' mt={"10"}>
              <Flex justifyContent={"space-between"}>
                  <Box>
                <Text fontSize={"12px"} fontWeight={"400"}>Arriving {event?.arrivingIn < 1 ? (event?.arrivingIn * -1)+' days later' : 'Done' } </Text>
                  <Text fontSize={"18px"} fontWeight={"bold"}>{event?.title}</Text>
                  <Text fontSize={"12px"} fontWeight={"400"}>{event?.name+" - "+event?.area}</Text>
                  <Text fontSize={"12px"} fontWeight={"400"}>{(event?.guests > 1 ? event?.guests +" Guests " : event?.guests +" Guest ") + event?.price }</Text>
                  <Text fontSize={"12px"} fontWeight={"400"}>{dayjs(event?.startDate).format('MMM DD')+"-"+dayjs(event?.endDate).format('MMM DD')}</Text>
                </Box>
               
                </Flex>
              </Stack>
              ))}
            </DrawerBody>
  
            <DrawerFooter borderTopWidth='1px'>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Submit</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  export default BookingShowMore