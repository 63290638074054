import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import logo from "../assets/images/logo.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import * as api from "./API/staysApi";
import { useMutation } from "react-query";

function Header() {
  const history = useHistory();

  function handleSignout() {
    Cookies.remove("admin-token");
    window.location.href = "/";
  }

  const { mutate } = useMutation([], api.clearCache, {
    onSuccess: () => {},
  });

  function handleClearCache() {
    mutate();
  }

  const toast = useToast();

  return (
    <Box
      position={"fixed"}
      width={"100%"}
      zIndex={"40"}
      h={"16"}
      py={2}
      px={"5"}
      bg={"#455A64"}
    >
      <Flex justifyContent={"space-between"}>
        <Image
          onClick={() => history.push("/")}
          cursor="pointer"
          src={logo}
          alt=""
          w={"32"}
          h={12}
        />
        <HStack spacing={"5"} alignItems={"center"}>
          {/* Others Menu */}
          <Button
            bg={"#FED438"}
            onClick={() => history.push("/manual-booking")}
          >
            Create Booking
          </Button>
          <Button
            bg={"#FED438"}
            onClick={() => (window.location.href = "/messages")}
          >
            Messages
          </Button>
          <Menu>
            <MenuButton
              bg={"#FED438"}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Others
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => history.push("/currencies")}>
                Currencies
              </MenuItem>
              <MenuItem onClick={() => history.push("/rules")}>Rules</MenuItem>
              <MenuItem onClick={() => history.push("/highlights")}>
                Highlights
              </MenuItem>
              <MenuItem onClick={() => history.push("/amenities")}>
                Amenities
              </MenuItem>
              <MenuItem onClick={() => history.push("/suppliers")}>
                Suppliers
              </MenuItem>
              <MenuItem onClick={() => history.push("/contactus")}>
                Contact Us
              </MenuItem>
              <MenuItem onClick={() => history.push("/events")}>
                Special Events
              </MenuItem>
              <MenuItem onClick={() => history.push("/beds")}>Beds</MenuItem>
              <MenuItem onClick={() => history.push("/baths")}>Baths</MenuItem>
              <MenuItem onClick={() => history.push("/icons")}>Icons</MenuItem>

              {/* <MenuItem>Delete</MenuItem> */}
              {/* <MenuItem>Attend a Workshop</MenuItem> */}
            </MenuList>
          </Menu>
          <Menu>
            {/* Actions Menu */}

            <MenuButton
              bg={"#FED438"}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleSignout()}>Logout</MenuItem>
              <MenuItem onClick={() => (window.location.href = "/calendar")}>
                Calendar
              </MenuItem>
              <MenuItem onClick={() => (window.location.href = "/")}>
                Dashboard
              </MenuItem>
              <MenuItem onClick={() => handleClearCache()}>
                Clear Server Cache
              </MenuItem>
              {/* <MenuItem>Attend a Workshop</MenuItem> */}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Box>
  );
}

export default Header;
