import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/stay-extra-details`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getProducts = async() =>  await api.get('admin').then(res => res.data.products)

export const getStayDetails = (id) => {
  return  api.get(`/stay/details?stay=${id}`).then((res) => res.data.details)
}

export const updateStayDetails = (body) => {
  return  api.put(``, body).then((res) => res.data)
} 

export const addStayDetails = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteStayDetails = (body) => {
    return api.delete(``,body).then((res) => res.data)
}