import { Icon } from "@chakra-ui/icons";

import {
    Button,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useQuery } from "react-query";
import * as api from "../API/stayBookingapi";
import { useState } from "react";
import dayjs from "dayjs";
import { AiOutlineOrderedList } from "react-icons/ai";


function ViewStayBooking({ Edit, Id }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [id, setId] = useState()

    // GET BY ID
    const { data: booking } = useQuery(
        ["order", id],
        () => api.getStayBookingById(id).finally(() => { }),
        {
            enabled: Boolean(id)
        }
    );

    function handleEdit() {
        onOpen();
        setId(Id);
    }



    return (
        <>
            {Edit === true ? (
                <Icon
                    onClick={() => handleEdit()}
                    as={AiOutlineOrderedList}
                    ml={4}
                    cursor={"pointer"}
                    w="6"
                    h="6"
                    fontSize={"bold"}
                />
            ) : (
                <Button onClick={onOpen} bg={"blue.100"}>
                    View Booking
                    <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
                </Button>
            )}
            <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>View Booking</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack alignItems={"start"}>
                        <InputGroup w="30%">
                                    <InputLeftAddon  children="Stay Name" />
                                    <Input type="text" readOnly px="2" value={booking?.property?.name} />
                                </InputGroup>
                        
                            <HStack mb="5">
                                
                            <Text fontSize="18px">{booking?.user?.fullName?.first + " " + booking?.user?.fullName?.last}</Text>
                            <Text fontSize="18px" ml={10} fontWeight="semibold" color={booking?.isConfirmed ? 'green.500' : 'red.500'}>{booking?.isConfirmed ? 'Confirmed' : 'Canceled'}</Text>
                            </HStack>
                            <Text pt="5" pb="2">Stay Details</Text>
                            <HStack mt="3">
                                <InputGroup>
                                    <InputLeftAddon children="CheckIn" />
                                    <Input type="text" readOnly px="2" value={dayjs(booking?.checkInDate).format('DD-MM-YYYY')} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children="CheckOut" />
                                    <Input type="text" readOnly px="2" value={dayjs(booking?.checkOutDate).format('DD-MM-YYYY')} />
                                </InputGroup>
                            </HStack>
                            <Text pt="5" pb="2">Prices Details</Text>
                            <HStack >
                                <InputGroup>
                                    <InputLeftAddon children="Price per night" />
                                    <Input type="text" readOnly px="2" value={booking?.pricePerNight} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children="Number of Nights" />
                                    <Input type="text" readOnly px="2" value={booking?.nights} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children="Cleaning" />
                                    <Input type="text" readOnly px="2" value={booking?.cleanFeas} />
                                </InputGroup>
                            </HStack>
                            <Text pt="5" pb="2">Booking Info</Text>
                            <HStack >
                          
                            <InputGroup>
                                    <InputLeftAddon children="Total Price" />
                                    <Input type="text" readOnly px="2" value={booking?.totalPrice +" "+booking?.currency?.symbol} />
                                </InputGroup>
                                <InputGroup mt="10">
                                    <InputLeftAddon children="Adults" />
                                    <Input type="text" readOnly px="2" value={booking?.numberOfGuests.adults} />
                                </InputGroup>
                                <InputGroup mt="10">
                                    <InputLeftAddon children="Children" />
                                    <Input type="text" readOnly px="2" value={booking?.numberOfGuests.childrens} />
                                </InputGroup>
                                <InputGroup mt="10">
                                    <InputLeftAddon children="Infants" />
                                    <Input type="text" readOnly px="2" value={booking?.numberOfGuests.infants} />
                                </InputGroup>
                                </HStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
export default ViewStayBooking;
