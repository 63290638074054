import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/products/`,
    headers: {'authorization':Cookies.get('admin-token')}
})
const shipping = axios.create({
  baseURL : `${baseURL}/v1/`,
  headers: {'authorization':Cookies.get('admin-token')}
})

export const getProducts = async() =>  await api.get('admin').then(res => res.data.products)

export const getProduct = (id) => {
  return  api.get(`admin/${id}`).then((res) => res.data.products)
}

export const updateProduct = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const addProduct = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteProduct = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}

export const getShippingCost = async() => {
  return  shipping.get(`shipping-cost`).then((res) => res.data.shippingCost)
}
export const addShippingCost = async(body) => {
  return shipping.post('shipping-cost',body).then((res) => res.data)
}

export const updateShippingCost = async(body) => {
  return shipping.put('shipping-cost',body).then((res) => res.data)
}