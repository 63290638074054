import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
    Button,
    
    Input,
    InputGroup,
    InputLeftAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,

    useDisclosure,
    useToast,
    VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/bedApi";

import { useEffect, useState } from "react";

function AddBed({ Edit, Id }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const [id, setId] = useState()
    const [name, setName] = useState()



    const queryClient = useQueryClient();



    const { data: Bed } = useQuery(
        ['bed', id],
        () => api.getBedroomById(id).finally(() => {
        }),
        {

            enabled: Boolean(id),
        }
    )

    //UPDATE
    const { mutate: updateMutate } = useMutation([], api.updateBedroom, {
        onSuccess: () => {
            queryClient.invalidateQueries(["AllBeds"]);
            toast({
                title: 'Added Successfully',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            onClose()
        },
        onError: (error) => {
            toast({
                title: error.response.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }
    });

    // POST
    const { mutate: postMutate } = useMutation([], api.addBedroom, {
        onSuccess:
            () => {
                queryClient.invalidateQueries(["AllBeds"]);
                toast({
                    title: 'Added Successfully',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                onClose()
            },
        onError: (error) => {
            toast({
                title: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    })

    async function handlePost() {
        const data = {
            id: Id,
            name,
        };
        if (Edit) {
            updateMutate(data)
        }
        else {
            postMutate(data)
        }
    }

    function handleEdit() {
        onOpen()
        setId(Id)
    }

    useEffect(() => {
        if (Edit && Bed) {
            setName(Bed.name)

        }
    }, [Bed])

    return (
        <>
            {Edit === true ?
                <Icon onClick={() => handleEdit()} as={FiEdit} cursor={"pointer"} ml={4} size={6} fontSize={"bold"} />
                :
                <Button onClick={onOpen} bg={'#FED438'}>
                    Add Bed
                    <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
                </Button>
            }
            <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{Edit ? 'Edit Bed' : 'Add Bed'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Info */}
                        <VStack
                            alignItems={"start"}
                            justifyContent={"start"}
                            spacing={"15px"}
                        >
                            <InputGroup>
                                <InputLeftAddon children="Name" />
                                <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </InputGroup>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>{Edit ? 'Update' : 'Add'}<Icon ml={2} as={PlusSquareIcon} /></Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    );
}
export default AddBed;
