import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/team`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getTeamMembers = async() => {
    return api.get().then(res => res.data.team)
}

export const getTeamMemberById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.teamMember)
   }

 export const addTeamMember = async(body) => {
  return api.post('',body).then((res) => res.data).catch((error) => console.log(error.data.message))
}

export const sortTeamMember = (body) => {
  return api.post('/order',body).then((res) => res.data)
}

export const editTeamMember = (body) => {
    return api.put(''+body.get('id'),body).then((res) => res.data)
  }


export const deleteTeamMember = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}