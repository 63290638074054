import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  VStack,
  useToast,
  Text,
  Box
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/stayBookingapi";
import { useEffect, useState } from "react";
import { MdFreeCancellation } from "react-icons/md";
import DatePicker from "react-multi-date-picker";

function CancelBooking({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
 const [details,setDetails] = useState()
  const [checkIn,setCheckIn] = useState()
  const [checkOut,setCheckOut] = useState()
  const [propId,setPropId] = useState()
  const [id, setId] = useState();
  const queryClient = useQueryClient();
  
  //   Get Term Data for Edit

  const { data: bookingDetails } = useQuery(
    ["bookingDetails", id],
    () => api.getCancelDetails(id).finally(() => {}),
    {
      enabled: Boolean(id)
    }
  );

  //Post
  const { mutate: cancelMutate } = useMutation([], api.cancelBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries(["StayBooking"]);
      toast({
        title: "Booking Canceled successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      onClose();
    },
  onError:(error) => {
    toast({
      title: error.response.data.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
});



  useEffect(() => {
    if (bookingDetails) {
        setDetails(bookingDetails.policy)
    }
  }, [bookingDetails, Id]);
  
  function handleUpdate() {
   
    const body = {
        bookingId:Id,
      
    }
    cancelMutate(body)
     
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  function handleClose() {
    onClose();
  }

//   async function handlePost() {
//     const data = {
//         propertyId: Id,
//         checkInDate: checkIn,
//         checkOutDate: checkOut
//     };
//     postMutate(data);
//   }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={MdFreeCancellation}
          ml={4}
          cursor={"pointer"}
          w="6"
          h="6"
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={'#FED438'}>
          Add Career
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"md"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Cancel Booking" : "Add Career"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"4"}
            >
                <Text>Cancel Type:{" "+details?.name}</Text>
                <Box>
                <ul >
                    {details?.description?.map(desc => (
                    <li>{desc}</li>
                    ))}
                </ul>
                </Box>
                <Text>Before :{" "+details?.numberOfDays+" Days"}</Text>
                <Text>Refound Amount:{" "+details?.refundAmountPercent * 100}%</Text>
                <Text>Refound Price:{" "+bookingDetails?.refundAmount }$</Text>
                
              
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            {Edit ? (
                <HStack spacing={5}>
               
                
              <Button
                variant="outline"
                onClick={() => handleUpdate()}
                bg={"red"}
                textColor={"white"}
              >
                Cancel <Icon ml={2} as={PlusSquareIcon} />
              </Button>
              </HStack>
            ) : (
              <Button
                variant="outline"
                // onClick={() => handlePost()}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default CancelBooking;
