import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import moment from "moment";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import * as api from "../components/API/calendarApi";
import * as Countryapi from "../components/API/countryApi";
import * as Cityapi from "../components/API/citiesApi";
import * as Areaapi from "../components/API/areaApi";
import { useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Icon } from "@chakra-ui/icons";

import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import AddBlockDates from "../components/UI/AddBlockDates";
import BookingDetails from "../components/UI/BookingDetail";
import axios from "axios";
import BookingShowMore from "../components/UI/BookingShowMore";
import Cookies from "js-cookie";
import { baseURL } from "../components/config";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function BigCalendar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [eventId, setEventId] = useState();
  const [searchFor, setSearchFor] = useState("");
  const [propId, setPropId] = useState();
  const [defaultDate, setDefaultDate] = useState();
  const [title, setTitle] = useState({ name: "", id: "" });
  const token = Cookies.get("admin-token");
  const [allEvents, setAllEvents] = useState();

  const { data: Countries } = useQuery("Countries", Countryapi.getCountries);
  const { data: Cities } = useQuery("Cities", Cityapi.getCities);
  const { data: Areas } = useQuery("Areas", Areaapi.getArea);
  const { data: stays, isSuccess } = useQuery("StaysEvent", api.getStays);
  const [allStays, setAllStays] = useState();
  const [showMoreEvents, setShowMoreEvents] = useState();
  const [reloadData, setReloadData] = useState(false);

  function getStays() {
    axios
      .get(`${baseURL}/v1/calendardata/`, {
        headers: {
          authorization: token,
        },
      })
      .then((data) => {
        setAllEvents(data?.data.holdEvents);
        setAllStays(stays);
      });
  }
  function handleChangeSelect(type, id) {
    if (type === "area") {
      axios
        .get(`${baseURL}/v1/calendardata/stays/all?area=` + id, {
          headers: {
            authorization: token,
          },
        })
        .then((data) => {
          setAllStays(data?.data.stays);
        });
    } else if (type === "city") {
      axios
        .get(`${baseURL}/v1/calendardata/stays/all?city=` + id, {
          headers: {
            authorization: token,
          },
        })
        .then((data) => {
          setAllStays(data?.data.stays);
        });
    } else {
      axios
        .get(`${baseURL}/v1/calendardata/stays/all?country=` + id, {
          headers: {
            authorization: token,
          },
        })
        .then((data) => {
          setAllStays(data?.data.stays);
        });
    }
  }

  function eventStyleGetter(event, start, end, isSelected) {
    var style = {
      backgroundColor: event?.notes?.length > 0 ? "red" : "#87cefa",
      width: "full",
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "10px",
      display: "block",
    };
    return {
      style: style,
    };
  }
  function selectStayToShow(name, event, id) {
    setAllEvents(event);
    setTitle({ id: id, name: name });
  }

  const handleSelect = (event, start, end) => {
    setEventId(event);
    onOpen();

    return;
  };

  useEffect(() => {
    if (isSuccess) {
      setAllStays(stays);
    }
    getStays();
  }, [isSuccess, stays, reloadData, onClose, onClose1, onClose2]);

  async function handleAddEvent() {
    setPropId(title.id);
    if (title.id) {
      onOpen1();
    }
  }

  function handleMoreEvents(events) {
    setShowMoreEvents(events);
    onOpen2();
  }

  return (
    <Flex paddingTop={"70px"}>
      <BookingDetails
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        event={eventId}
      />
      <AddBlockDates
        setReloadData={setReloadData}
        onOpen={onOpen1}
        isOpen={isOpen1}
        onClose={onClose1}
        event={propId}
      />
      {showMoreEvents && (
        <BookingShowMore
          onOpen={onOpen2}
          isOpen={isOpen2}
          onClose={onClose2}
          events={showMoreEvents}
        />
      )}
      <Box
        height={"100vh"}
        borderRight={"2px"}
        overflow={"scroll"}
        paddingTop={"10px"}
        borderColor={"red.200"}
      >
        {/* Title and ShowALL BUTTON */}
        <Flex alignItems={"center"} justifyContent={"space-between"} px={2}>
          <Text fontWeight={"bold"}>
            {title.name ? title.name : "All Stays"}
          </Text>
          <Button m={2} size="xs" onClick={() => getStays()}>
            <Text>Show All</Text>
          </Button>
          <Popover>
            <PopoverTrigger>
              <IconButton size="sm" icon={<FiFilter />} />
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Filter By</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Tabs>
                    <TabList>
                      <Tab>Country</Tab>
                      <Tab>City</Tab>
                      <Tab>Area</Tab>
                    </TabList>

                    <TabPanels>
                      {/* Countries */}
                      <TabPanel>
                        <FormControl>
                          <FormLabel htmlFor="country">Country</FormLabel>
                          <Select
                            id="country"
                            onChange={(e) =>
                              handleChangeSelect("country", e.target.value)
                            }
                            placeholder="Select country"
                          >
                            {Countries?.map((c) => (
                              <option
                                onChange={() =>
                                  handleChangeSelect("country", c._id)
                                }
                                key={c._id}
                                value={c._id}
                              >
                                {c.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TabPanel>
                      {/* Cites */}
                      <TabPanel>
                        <FormControl>
                          <FormLabel htmlFor="country">City</FormLabel>
                          <Select
                            id="city"
                            onChange={(e) =>
                              handleChangeSelect("city", e.target.value)
                            }
                            placeholder="Select City"
                          >
                            {Cities?.map((c) => (
                              <option key={c._id} value={c._id}>
                                {c.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TabPanel>
                      {/* Cites */}
                      <TabPanel>
                        <FormControl>
                          <FormLabel htmlFor="country">Area</FormLabel>
                          <Select
                            id="city"
                            onChange={(e) =>
                              handleChangeSelect("area", e.target.value)
                            }
                            placeholder="Select Area"
                          >
                            {Areas?.map((c) => (
                              <option key={c._id} value={c._id}>
                                {c.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
        {/* Search Box */}
        <Flex
          px={2}
          _focusWithin={{ boxShadow: "inner" }}
          border={"1px"}
          borderColor={"gray.200"}
          rounded={"lg"}
          mx={2}
        >
          <Input
            type="text"
            _focus={{ outline: "none" }}
            value={searchFor}
            border={"none"}
            placeholder="search"
            onChange={(e) => setSearchFor(e.target.value)}
          />
          <Icon
            onClick={() => setSearchFor("")}
            as={AiOutlineCloseCircle}
            w={"5"}
            h={"10"}
          />
        </Flex>
        {allStays &&
          allStays
            ?.filter((name) =>
              name?.name?.toLowerCase().includes(searchFor.toLowerCase())
            )
            .map((s) => (
              <Box
                key={s._id}
                bg={title.id === s._id && "blue.200"}
                onClick={() => selectStayToShow(s.name, s.holdEvents, s._id)}
                py={"4"}
                px={"4"}
                borderBottom={"1px"}
                cursor={"pointer"}
                _hover={{ backgroundColor: "gray.100" }}
              >
                <HStack spacing={5}>
                  <Image src={s.image} w={"50px"} h={"50px"} rounded={"full"} />
                  <Box>
                    <Text>{s.name}</Text>
                    <Text fontSize={"12px"}>
                      Booking:{s?.holdEvents?.length}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            ))}
      </Box>
      {allEvents !== null && allEvents !== undefined && (
        <Box style={{ height: "100vh", width: "75vw" }}>
          <Box
            minW={{ base: "85%", "2xl": "100%" }}
            maxW={{ base: "85%", "2xl": "100%" }}
            h={{ base: "80%", "2xl": "85%" }}
            position={"relative"}
            marginLeft={"10"}
            paddingTop={"10px"}
          >
            <Box top={"2"} left={"270px"} position={"absolute"}>
              <DatePicker
                onChange={setDefaultDate}
                onlyMonthPicker
                render={
                  <InputIcon
                    style={{ cursor: "pointer", padding: "15px 2px" }}
                  />
                }
              />
            </Box>
            <Calendar
              selectable
              localizer={localizer}
              events={allEvents}
              startAccessor={(event) => {
                return moment(event.startDate).toDate();
              }}
              showMultiDayTimes={true}
              // endAccessor={event => {
              //   const date = new Date(event.endDate)
              //   const yesterDate = new Date(date)
              //    yesterDate.setDate(yesterDate.getDate())
              //   return moment(yesterDate).toDate()
              // }}
              endAccessor={(event) => {
                return moment(event.endDate).toDate();
              }}
              onNavigate={(date) => {
                setDefaultDate(date);
              }}
              date={defaultDate}
              views={{ month: true }}
              // startAccessor={("startDate")}
              // endAccessor={("endDate")}
              eventPropGetter={eventStyleGetter}
              onSelectEvent={handleSelect}
              onSelectSlot={() => handleAddEvent()}
              onShowMore={(events) => handleMoreEvents(events)}
            />
          </Box>
        </Box>
      )}
    </Flex>
  );
}

export default BigCalendar;
