import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/productSupplier/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getSuppliers = async() =>  await api.get().then(res => res.data)


export const getSupplierById = async(id) => {
  return  api.get(`/${id}`).then((res) => res.data)
}

export const updateSupplier =async(body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const addSupplier = async(body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteSupplier= async(id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}