import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/rateApi";
import * as currapi from "../API/currencyApi";

import { useEffect, useState } from "react";

function AddRate({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
  const [id,setId] = useState()
  const [from,setFrom] = useState()
  const [to,setTo] = useState()
  const [rate,setRate] = useState()
 
  const queryClient = useQueryClient();

  const { data: currencies } = useQuery(
    "currencies",
    currapi.getCurrencies
  );


  const { data : Rate } = useQuery(
    ['rate', id],
    () => api.getRateById(id).finally(() => {
    }),
    {   
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.updateRate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Rates"]);
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }) 
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

// POST
const { mutate : postMutate  } = useMutation([],api.addRate, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Rates"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  useEffect(() => { 
      if(Edit && Rate){
        setFrom(Rate.from._id)
        setTo(Rate.to._id)
        setRate(Rate.rate)
      }
  },[Rate])


    async function handlePost() {
    
        const dataPost = {
        id:Id,
       from,
       to,
       rate,
      };
      const dataUpdate = {
          id:Id,
          rate,
      }
      if(Edit){
          updateMutate(dataUpdate)
    }
    else{
        postMutate(dataPost)
    }
    }

    function handleEdit(){
        onOpen()
        setId(Id)
      }
   
  return (
    <>
        {Edit === true ? 
        <Icon onClick={() => handleEdit()} as={FiEdit} cursor={"pointer"} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Rate
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Rule ' : 'Add Rule'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              {/* From */}
          <InputGroup my="5">
                      <InputLeftAddon children="From:" />
                      <Select
                        disabled={Edit ? true : false}
                        rounded="none"
                        value={from}
                        onChange={(e) =>
                          setFrom(e.target.value)
                        }
                      >
                        <option>Select a Currency</option>
                        {currencies?.map((curr) => (
                          <option value={curr._id} key={curr._id}>
                            {curr.name}
                          </option>
                        ))}
                      </Select>
            </InputGroup>
                      {/* To */}
                      <InputGroup my="5">
                      <InputLeftAddon children="To:" />
                      <Select
                       disabled={Edit ? true : false}
                        rounded="none"
                        value={to}
                        onChange={(e) =>
                          setTo(e.target.value)
                        }
                      >
                        <option>Select a Currency</option>
                        {currencies?.map((curr) => (
                            from === curr._id ?
                               ''
                                :
                          <option value={curr._id} key={curr._id}>
                            {curr.name}
                          </option>
                            
                        ))}
                      </Select>
                      </InputGroup>
                      {/* Rate */}
                      <InputGroup my="5">
                      <InputLeftAddon children="Rate:" />
                            <Input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
                      </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>{Edit ? 'Update' : 'Add'}<Icon ml={2} as={PlusSquareIcon} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
}
export default AddRate;
