import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as catApi from "../API/categoryApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddProduct({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState()
  const queryClient = useQueryClient();
//POST
const { mutate : postMutate , isLoading } = useMutation([],catApi.addProductCategory, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["productCategories"]);
            onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })
  // UPDATE
  const { mutate: updateMutate } = useMutation([], catApi.updateProductCategoryById, {
    onSuccess: () => {
      queryClient.invalidateQueries(["productCategories"]);
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  // GET BY ID
  const { data : productCat } = useQuery(
    ['productCat', id],
    () => catApi.getProductCategoryById(id).finally(() => {
    }),
    {
        enabled: Boolean(id),
    }
    )

   
    function handleEdit(){
    
      onOpen()
      setId(Id)
    }
    

    useEffect(() => {
      if(productCat){
        setName(productCat.name)
      }
    },[Id,productCat])

    async function handlePost() {
      if(name.length > 1){
        const data = {
       name : name
      };
        postMutate(data)
    }
    }

    function handleUpdate(){
      
      const data ={
          id:Id,
          name:name
      }
      updateMutate(data)
    }
  return (
<>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Category
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
  }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Product Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <HStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10px"}
            >
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              
            </HStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? 
            <Button variant="outline" onClick={(e) => handleUpdate(e)} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={(e) => handlePost(e)} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
          }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddProduct;
