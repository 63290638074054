import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/contact-details`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getDetails = async() => {
    return api.get().then(res => res.data.details)
}



 export const addDetails = (body) => {
  return api.post('',body).then((res) => res.data)
}
export const editDetails = (body) => {
    return api.put('',body).then((res) => res.data)
  }


export const deleteDetails = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}