import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
  baseURL: `${baseURL}/v1/properties`,
  headers: { 'authorization': Cookies.get('admin-token') }
})
const api1 = axios.create({
  baseURL: `${baseURL}/v1/`,
  headers: { 'authorization': Cookies.get('admin-token') }
})

export const getStays = async () => {
  const data = await api.get('/admin')
  const res = (data.data.properties)
  return res
}


export const getStay = async (id) => {
  return api.get(`/admin/${id}`).then((res) => res.data.properties[0])
}
export const getStaysForBooking = async () => {
  return api.get('admin/all/dates').then((res) => res.data.properties);
}

export const updateStay = (body) => {
  return api.put(`/${body.id}`, body).then((res) => res.data)
}
export const updateStayImage = (body) => {
  return api.put(`admin/image/${body.get('id')}`, body).then((res) => res.data)
}
export const addStay = async (body) => {
  return api.post('', body).then((res) => res.data)
}

export const deleteStay = (id) => {
  return api.delete(`/${id}`).then((res) => res.data)
}

export const getStayGuests = async () => {
  return api1.get('/propertyBooking/admin/bookings/main/guests').then((res) => res.data.guests)
}

export const getStayGuestsByBId = async (id) => {
  return api1.get('/propertyBooking/admin/bookings/guests?bookingId=' + id).then(res => res.data.guests)
}

export const createBooking = async (body) => {
  return api1.post('/propertyBooking/admin/new/booking', body).then(res => res.data)
}

export const clearCache = async () => {
  return api.post('/clearcache').then((res) => res)
}