import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/beds/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getBedrooms = async() =>  await api.get('').then(res => res.data.beds)

export const getBedroomById = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.bed)
}


export const addBedroom = (body) => {
  return  api.post('',body).then((res) => res.data)
}

export const updateBedroom = (body) => {
  return  api.put(`${body.id}`, body).then((res) => res.data)
} 



export const deleteBedroom  = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}