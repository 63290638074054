import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/analytics/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getMonthlyRevenu = async(year,stayId) =>  await api.get(`monthly-revenu?year=${year}&property=${stayId}`).then(res => {
 return   res.data.data
})

export const getMonthlyBooking = (year,stayId) => {
  return  api.get(`monthly-bookings?year=${year}&property=${stayId}`).then((res) => res.data.data)
}

export const getOccupancyRate = (year,stayId) => {
  return  api.get(`occupancy?year=${year}&property=${stayId}`).then((res) => res.data.data)
} 

export const addProduct = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteProduct = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}