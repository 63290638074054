import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/citiesApi";
import * as countryApi from "../API/countryApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddCityModal({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState()
  const [image,setImage] = useState()
  const [newImage,setNewImage] = useState()
  const [country,setCountry] = useState()
  const queryClient = useQueryClient();
  const { data :countries} = useQuery("Countries", countryApi.getCountries);

//POST
const { mutate : postMutate  } = useMutation([],api.addCity, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Cities"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  const { data : City } = useQuery(
    ['City', id],
    () => api.getCityById(id).finally(() => {
       
    }),
    {
        enabled: Boolean(id),
    }
  )
  

//UPDATE
const { mutate: updateMutate } = useMutation([], api.updateCity, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Cities"]);
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  useEffect(() => {
    if(City){
          setName(City.name)

          setCountry(City.country)
    }
  },[City,country])


  function handleUpdate(){
    const data = new FormData()
        data.append('id',Id)
        data.append('name',name)
        data.append('image',image)
        data.append('country',country)

    updateMutate(data)
  }
  
    async function handlePost() {

        const data = new FormData()
        data.append('name',name)
        if(image){
        data.append('image',image)
      }
        data.append('country',country)
        postMutate(data)

    }

    function handleChange(e) {
      
      setImage(e.target.files[0])
      setNewImage(URL.createObjectURL(e.target.files[0]))
    }

    function handleEdit(){
      onOpen()
      setId(Id)
    
    }

  return (
    <>
    {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add City
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit':'Add'} City</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup> 
                <InputLeftAddon children="City:" />
                <Input type ="text" placeholder="City name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon >{City?.image ?  <Image src={City?.image} w={"37px"} h={"37px"} rounded={"full"} /> : 'Image:' }</InputLeftAddon>
                <Input type="file" name={City?.image} placeholder="Image Url" pt={"1"}   onChange={(e) => handleChange(e)}   />
                </InputGroup>
              <Select value={country} onChange={(e) =>setCountry(e.target.value) }>
              <option >Country</option>
                    {countries?.map(c => (
                        <option value={c._id}   key={c._id} >{c.name}</option>
                    ))}
              </Select>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            { Edit ?
            <Button variant="outline" onClick={() => handleUpdate()} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddCityModal;
