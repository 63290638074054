import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/tours/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getTours = async() =>  await api.get('admin').then(res => res.data)





export const getTour = (id) => {
  return  api.get(`admin/${id}`).then((res) => res.data.tour)
}

export const updateTour = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const addTour = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteTour = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}

export const addTourDate = (body) => {
  return api.post('/dates',body).then((res) => res.data)
}
export const updateTourDate = (body) => {
  return  api.put('/dates', body).then((res) => res.data)
} 

export const deleteTourDate = (body) => {
  return api.delete('/dates',{data:body}).then((res) => res.data)
}