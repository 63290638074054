import { Icon } from "@chakra-ui/icons";
import {
  Box, Flex, Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { HiArrowSmDown, HiArrowSmUp, HiOutlineClipboardList } from "react-icons/hi";
import {} from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BsFillBarChartFill, BsFillJournalBookmarkFill, BsFillPeopleFill } from "react-icons/bs";
import {GoPackage} from 'react-icons/go'
import SidebarElement from "./UI/SidebarElement";
import SidebarSubElement from "./UI/SidebarSubElement";
import { MdAdminPanelSettings,MdHomeWork } from "react-icons/md";
import {GiMilkCarton} from 'react-icons/gi'
import {BiTrip} from 'react-icons/bi'
import {ImHome} from 'react-icons/im'
import {AiOutlineFileSearch} from 'react-icons/ai'
function Sidebar() {
  const location = useLocation()
const url = window.location.pathname
    const [showMore, setShowMore] = useState({
        line:"",
        status:""
    });

    useEffect(() => {

    },[location])

function handleToggle(line) {
    if(showMore.line === line){
        setShowMore({line:0,status:false})
    }else{
        setShowMore({line:line,status:true})
    }
}
  return (
    <Box display={(url === '/' || url === '/calendar' || url === '/messages') && "none"} overflowX={"hidden"} position={"Sticky"} top={"0"} h={"100vh"}  bg={"#455A64"}>
      <Box h={"90vh"} overflowY={"scroll"} >
        <Box pt={16} >
      <SidebarElement setShowMore={setShowMore} title={'Reports'} link={'/dashboard'} icon={BsFillBarChartFill}  />
      <SidebarElement setShowMore={setShowMore} title={'Overview'} link={'/overview'} icon={AiOutlineFileSearch}  />
      <SidebarElement setShowMore={setShowMore} title={'Stays'} link={'/stays'} icon={ImHome}  />
      <SidebarElement setShowMore={setShowMore} title={'Guest House'} link={'/guesthouse'} icon={MdHomeWork}  />
      <SidebarElement setShowMore={setShowMore} title={'Tours'} link={'/tours'} icon={BiTrip} />
      <SidebarElement setShowMore={setShowMore} title={'Products'} link={'/products'} icon={GiMilkCarton}  />
      <SidebarElement setShowMore={setShowMore} title={'Orders'} link={'/orders'} icon={GoPackage}  />
      <SidebarElement setShowMore={setShowMore} title={'Tour Booking'} link={'/tourBooking'} icon={BsFillJournalBookmarkFill}  />
      <SidebarElement setShowMore={setShowMore} title={'Stay Booking'} link={'/stayBooking'} icon={BsFillJournalBookmarkFill}  />
      <SidebarElement setShowMore={setShowMore} title={'Inqueries'} link={'/inqueries'} icon={HiOutlineClipboardList}  />
      <SidebarElement setShowMore={setShowMore} title={'Admins'} link={'/admins'} icon={MdAdminPanelSettings}  />
      <SidebarElement setShowMore={setShowMore} title={'Users'} link={'/guests'} icon={BsFillPeopleFill}  />
    
      <Box  px={2}>
            <Box py={3} borderbottom={"2px"} bordercolor={"white"}>
              <Flex
                textColor={"white"}
                alignItems={"center"}
                cursor={"pointer"}
                px={"15px"}
                justifyContent={"space-between"}
                onClick={() => handleToggle(1)}
              >
                <Text fontSize={"15"} fontWeight={"semibold"}>
                Categories
                </Text>
                <Icon
                  as={showMore.status && showMore.line === 1 ? HiArrowSmDown : HiArrowSmUp}
                  w={6}
                  h={6}
                  color={"white"}
                />
              </Flex>
              <Box px={2} display={showMore.status && showMore.line === 1 ? "block" : "none"}>
                <SidebarSubElement
                  link={"productcategories"}
                  title={"Product Categories"}
                  line={1}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"tourcategories"}
                  title={"Tour Categories"}
                  line={1}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"staycategories"}
                  title={"Stay Categories"}
                  line={1}
                  setShowMore={setShowMore}
                />
              </Box>
            </Box>
            <Box py={3} borderbottom={"2px"} bordercolor={"white"}>
              <Flex
                textColor={"white"}
                alignItems={"center"}
                cursor={"pointer"}
                px={"15px"}
                justifyContent={"space-between"}
                onClick={() => handleToggle(2)}
              >
                <Text fontSize={"15"} fontWeight={"semibold"}>
                  Addresses
                </Text>
                <Icon
                  as={(showMore.status && showMore.line === 2) ? HiArrowSmDown : HiArrowSmUp}
                  w={6}
                  h={6}
                  color={"white"}
                />
              </Flex>
              <Box display={showMore.status && showMore.line === 2 ? "block" : "none"}>
                <SidebarSubElement
                  link={"countries"}
                  title={"Countries"}
                  line={2}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"cities"}
                  title={"Cities"}
                  line={2}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"areas"}
                  title={"Area"}
                  line={2}
                  setShowMore={setShowMore}
                />
              </Box>
            </Box>
            <Box py={3} borderbottom={"2px"} bordercolor={"white"}>
              <Flex
                textColor={"white"}
                alignItems={"center"}
                cursor={"pointer"}
                px={"15px"}
                justifyContent={"space-between"}
                onClick={() => handleToggle(3)}
              >
                <Text fontSize={"15"} fontWeight={"semibold"}>
                  CRM
                </Text>
                <Icon
                  as={(showMore.status && showMore.line === 3) ? HiArrowSmDown : HiArrowSmUp}
                  w={6}
                  h={6}
                  color={"white"}
                />
              </Flex>
              <Box display={showMore.status && showMore.line === 3 ? "block" : "none"}>
              <SidebarSubElement
                  link={"blogs"}
                  title={"Blogs"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"terms"}
                  title={"Terms"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"story"}
                  title={"Story"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"mission"}
                  title={"Mission"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"vision"}
                  title={"Vision"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"companies"}
                  title={"Companies"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"team"}
                  title={"Team"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"careers"}
                  title={"Careers"}
                  line={3}
                  setShowMore={setShowMore}
                />
                <SidebarSubElement
                  link={"privacy-policy"}
                  title={"Privacy Policy"}
                  line={3}
                  setShowMore={setShowMore}
                />
              </Box>
            </Box>
          </Box>
      </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
