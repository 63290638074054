import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  toast,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/mediaApi";

import { useEffect, useState } from "react";

function AddIcon({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [id,setId] = useState()
  const [name,setName] = useState()
 const [profileLink,setProfileLink] = useState()
  const [image,setImage] = useState()
  const queryClient = useQueryClient();


  const { data  } = useQuery(
    ['icon', id],
    () => api.getIconById(id).finally(() => {
    }),
    {   
        
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.updateIcon, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Icons"]);
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

// POST
const { mutate : postMutate  } = useMutation([],api.addIcon, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Icons"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  

  useEffect(() => {
    if(Edit && data){
      setName(data.name)
      setProfileLink(data.profileLink)
    }
},[data])


  function handleChange(e) {
    setImage(e.target.files[0])
  }

  function handleUpdate(){
    const data = new FormData()
        data.append('id',Id)
        data.append('name',name)
        data.append('profileLink',profileLink)
        data.append('images',image)
    
    updateMutate(data)
  }

  function handleEdit(){
    onOpen()
    setId(Id)
  }
  function handleClose(){
      onClose()
      setName("")
      setProfileLink("")
      
  }

    async function handlePost() {
        const data = new FormData()
          data.append('name',name)
          data.append('profileLink',profileLink)
          data.append('images',image)

        postMutate(data)
    }
   
  return (
    <>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen}  bg={'#FED438'}>
        Add Icon
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
        }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Team Member' : 'Add Team Member'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
              <InputLeftAddon children="Name:" />
              <Input placeholder="Name" defaultValue={data?.name} onChange={(e) => setName(e.target.value)}  />
              </InputGroup>

              <InputGroup>
              <InputLeftAddon children="Url:" />
              <Input placeholder="Url" value={profileLink} onChange={(e) => setProfileLink(e.target.value)}  />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon >{data?.image ?  <Image src={data?.image} w={"37px"} h={"37px"} rounded={"full"} /> : 'Image:' }</InputLeftAddon>
                <Input type="file" name={data?.image} placeholder="Image Url" pt={"1"}   onChange={(e) => handleChange(e)}   />
                </InputGroup>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() =>handleClose()}>
              Close
            </Button>
           { Edit ?
            <Button variant="outline" onClick={() => handleUpdate()} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddIcon;
