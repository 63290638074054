import { Icon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,

  Text,

  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";


function AddMission({Edit,image}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

   
  return (
    <>
       
      <Button onClick={onOpen}  bg={"blue.100"}>
        View  Image
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
        
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View  Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              {image ? <Image src={image} /> : <Text>No Image yet! upload one </Text> }
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddMission;
