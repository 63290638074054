import { Flex } from '@chakra-ui/react';

import { BrowserRouter, Route,  Switch } from 'react-router-dom';


import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AddImageTest from './components/Test/AddImageTest';

import Admins from './pages/Admins';
import Amenities from './pages/Amenities';
import Areas from './pages/Areas';
import Blogs from './pages/Blogs';
import Calendar from './pages/Calendar';
import Careers from './pages/Careers';
import Cities from './pages/Cities';
import Company from './pages/Company';
import ContactUs from './pages/ContactUs';
import Countries from './pages/Countries';
import Guests from './pages/Guests';
import Inqueries from './pages/Inqueries';
import Login from './pages/Login';
import MessageDetails from './pages/MessageDetails';
import Messages from './pages/Messages';
import Missions from './pages/Missions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductCategories from './pages/ProductCategories';
import Products from './pages/Products';
import ReactForm from './pages/ReactForm';
import Rules from './pages/Rules';
import StayCategories from './pages/StayCategories';
import Stays from './pages/Stays';
import Story from './pages/Story';
import Suppliers from './pages/Suppliers';
import Team from './pages/Team';
import Terms from './pages/Terms';
import TourCategories from './pages/TourCategories';
import Tours from './pages/Tours';
import UploadTOS3 from './pages/UploadTOS3';
import Vision from './pages/Vision';
import TourBooking from './pages/TourBookings';
import Orders from './pages/Orders';
import Icons from './pages/Icons';
import Dashboard from './pages/Dashboard';
import StayMainGuest from './pages/StayMainGuests';

import { useState } from 'react';
import { onMessageListener } from './firebase';
import Cookies from 'js-cookie';
import Currencies from './pages/Currencies';
import Rates from './pages/Rates';
import StayBookings from './pages/StaysBooking';
import Overview from './pages/Overview';
import Highlights from './pages/Highlights';
import Beds from './pages/Beds';
import Baths from './pages/Baths';
import  SortTeam  from './components/UI/SortTeam';
import GuestHouse from './pages/GuestHouse';
import Events from './pages/Events';
import ManualBooking from './pages/ManualBooking';
import AddTour from './pages/AddTour';
import AddAdmin from './pages/AddAdmin';
// import ReactNotificationComponent from './components/Notification';
// import Notifications from './components/Notifications';


function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log(payload);


  //   })
  //   .catch((err) => console.log("failed: ", err.message));

  return (
    <div>
      {/* {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )} */}
      {/* <Notifications /> */}
      <BrowserRouter>
      <Route path="/" exact component={Login} />
    <Header />
    <Flex>
    <Sidebar /> 
    <Switch>
      <Route path="/stays" component={Stays} />
      <Route path="/products" component={Products} />
      <Route path="/addimages" component={AddImageTest} />
      <Route path="/tours" exact component={Tours} />
      <Route path="/tours/addtour" component={AddTour} />
      <Route path="/admins" exact component={Admins} />
      <Route path="/admins/addadmin" component={AddAdmin} />
      <Route path="/beds" component={Beds} />
      <Route path="/baths" component={Baths} />
      <Route path="/highlights" component={Highlights} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/guests" component={Guests} />
      <Route path="/inqueries" component={Inqueries} />
      <Route path="/productcategories" component={ProductCategories} />
      <Route path="/tourcategories" component={TourCategories} />
      <Route path="/staycategories" component={StayCategories} />
      <Route path="/stayguests" component={StayMainGuest} />
      <Route path="/amenities" component={Amenities} />
      <Route path="/events" component={Events} />
      <Route path="/contactus" component={ContactUs} />
      <Route path="/countries" component={Countries} />
      <Route path="/icons" component={Icons} />
      <Route path="/vision" component={Vision} />
      <Route path="/cities" component={Cities} />
      <Route path="/rules" component={Rules} />
      <Route path="/guesthouse" component={GuestHouse} />
      <Route path="/messages" component={Messages} />
      <Route path="/message/:id" component={MessageDetails} />
      <Route path="/suppliers" component={Suppliers} />
      <Route path="/areas" component={Areas} />
      <Route path="/currencies" component={Currencies} />
      <Route path='/story' component={Story} />
      <Route path='/rates' component={Rates} />
      <Route path="/terms" component={Terms} />
      <Route path='/blogs' component={Blogs} />
      <Route path="/mission" component={Missions} />
      <Route path='/companies' component={Company} />
      <Route path='/manual-booking' component={ManualBooking} />
      <Route path='/team' component={Team} />
      <Route path='/overview' component={Overview} />
      <Route path='/tourBooking' component={TourBooking} />
      <Route path='/stayBooking' component={StayBookings} />
      <Route path='/orders' component={Orders} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/careers" component={Careers} />
      <Route path="/calendar" component={Calendar} />
      <Route path='/uploadd' component={UploadTOS3} />
      <Route path='/react-form' component={ReactForm} />
      <Route path='/sort-team' component={SortTeam} />
      
    </Switch>
    </Flex>
    </BrowserRouter>
    </div>
  );
}

export default App;
