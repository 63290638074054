import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/companyApi";
import {  useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddCompany({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
const [name,setName] = useState("")
const [desc,setDesc] = useState("")
const [dialog,setDialog] = useState("")
const [image,setImage] = useState("")
  const [id,setId] = useState()
  const queryClient = useQueryClient();

//   Get Term Data for Edit

  const { data : Company } = useQuery(
    ['Company', id],
    () => api.getCompanyById(id).finally(() => {
       
    }),
    {
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.editCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Companies"]);
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  useEffect(() => {
    if(Edit && Company){
        setName(Company.name)
        setDesc(Company.desc)
        setDialog(Company.dialog)
        // setImage(Company.image)
    }
  },[Company,Edit])

//POST
const { mutate : postMutate  } = useMutation([],api.addCompany, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Companies"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  function handleChange(e) {
    setImage(e.target.files[0])
  }

  function handleUpdate(){
    const data = new FormData()
        data.append('id',Id)
        data.append('name',name)
        data.append('desc',desc)
        data.append('image',image)
        data.append('dialog',dialog)
    
    updateMutate(data)
  }


  function handleEdit(){
    onOpen()
    setId(Id)
  }
  function handleClose(){
      onClose()
      setName(0)
      setDesc(0)
      setDialog(0)
      setImage(0)
  }

    async function handlePost() {
      const data = new FormData()

      data.append('name',name)
      data.append('desc',desc)
      data.append('image',image)
      data.append('dialog',dialog)
        postMutate(data)
    }
   
  return (
    <>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen}  bg={'#FED438'}>
        Add Company
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
        }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Company' : 'Add Company'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
              <InputLeftAddon children="Name:" />
              <Input placeholder="Name"  value={name} onChange={(e) => setName(e.target.value)}  />
              </InputGroup>
              <Textarea h={"100px"} placeholder="Description"  value={desc} onChange={(e) => setDesc(e.target.value)}  />
              <InputGroup>
              <InputLeftAddon children="Dialog:" />
              <Input placeholder="Dialog"  value={dialog} onChange={(e) => setDialog(e.target.value)}  />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon children="Image:" />
                <Input type="file" name={""} pt={"1"} placeholder="Image Url"   onChange={(e) => handleChange(e)}   />
                </InputGroup>

            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() =>handleClose()}>
              Close
            </Button>
           { Edit ?
            <Button variant="outline" onClick={() => handleUpdate()} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
        }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddCompany;
