import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/admin/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getAdmins = async() =>  await api.get('').then(res => res.data.admins)


export const getAdmin = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.admin)
}

export const addAdmin = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const updateAdmin = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const deleteAdmin = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}

export const loginAdmin = (body) => {
  return api.post('login',body).then((res) => res.data.admin)
}