import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/amenities/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getAmenities = async() =>  await api.get('').then(res => res.data.amenities)

export const getAmenityById  = async(id) => {
  return await api.get('/'+id).then(res => res.data.amenities)
}

export const updateAmenity = (body) => {
  return  api.put(`/${body.get('id')}`, body).then((res) => res.data)
} 

export const addAmenity = (stay) => {
    return api.post('',stay).then((res) => res.data)
}

export const deleteAmenity = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}