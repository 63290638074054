import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as catApi from "../API/categoryApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddProduct({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState()
  const [image,setImage] = useState()
  const queryClient = useQueryClient();
//POST
const { mutate : postMutate  } = useMutation([],catApi.addTourCategory, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["tourCategories"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
            onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })
  // UPDATE
  const { mutate: updateMutate } = useMutation([], catApi.updateTourCategoryById, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tourCategories"]);
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }) 
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  // GET BY ID
  const { data : tourCat } = useQuery(
    ['tourCat', id],
    () => catApi.getTourCategoryById(id).finally(() => {
    }),
    {
        enabled: Boolean(id),
    }
    )

   
    function handleEdit(){
    
      onOpen()
      setId(Id)
    }

    useEffect(() => {
      if(tourCat){
        setName(tourCat.name)
      }
    },[Id,tourCat])

    function handleChange(e) {
      setImage(e.target.files[0])
    }
  
    function handleUpdate(){
      const data = new FormData()
          data.append('id',Id)
          data.append('name',name)
          data.append('image',image)
      
      updateMutate(data)
    }

    async function handlePost() {
      const data = new FormData()
      data.append('name',name)
      data.append('image',image)
        postMutate(data)
    }


  return (
<>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Category
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
  }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Product Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10px"}
            >
              <InputGroup>
              <InputLeftAddon children="Name:" />
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon> {tourCat?.image ? <Image w="10" h="10" rounded={"full"} src={tourCat.image} alt="" /> : "Icons" }  </InputLeftAddon>
              <Input type="file" pt="1" placeholder="Name"  onChange={(e) => handleChange(e)} />
              </InputGroup>
              
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? 
            <Button variant="outline" onClick={(e) => handleUpdate(e)} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={(e) => handlePost(e)} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
          }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddProduct;
