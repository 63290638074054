import { Icon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Divider,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import {  useQuery } from "react-query";
import * as api from "../API/orderApi";
import { useState } from "react";
import { AiOutlineOrderedList } from "react-icons/ai";


function ViewOrder({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
    const [id,setId] = useState()

  // GET BY ID
  const { data: order } = useQuery(
    ["order", id],
    () => api.getOrderById(id).finally(() => {}),
    {
      enabled: Boolean(id)
    }
  );


  function handleEdit() {
    onOpen();
    setId(Id);
  }



  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={AiOutlineOrderedList}
          ml={4}
          cursor={"pointer"}
          w="6"
          h="6"
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"blue.100"}>
         View Order
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {order?.map((o,i) => ( 
                <Box key={o._id}>
                    <VStack alignItems={"start"} spacing={5}>
                        <InputGroup w="20%">
                            <InputLeftAddon children="Title:" /> 
                        <Input type="text" readOnly value={o?.title} />
                        </InputGroup>
                        <InputGroup w="20%">
                            <InputLeftAddon children="Size:" /> 
                        <Input type="text" readOnly value={o?.size?.size} />
                        </InputGroup>
                        <InputGroup w="20%">
                            <InputLeftAddon children="Quantity:" /> 
                        <Input type="text" readOnly value={o?.quantity} />
                        </InputGroup>
                        <InputGroup w="20%">
                            <InputLeftAddon children="Amount:" /> 
                        <Input type="text" readOnly value={o?.size?.price?.amount} />
                        </InputGroup>
                    </VStack>
                    <Divider my={10} />
                    {i === 0 && (
                      <VStack alignItems={"start"} spacing={5}>
                        <Text>
                          Address: {o.address.city.country.name} - {o.address.city.name}
                        </Text>
                        <Text></Text>
                      </VStack>
                    )}
                </Box>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ViewOrder;
