import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/highlight`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getHighlights = async() =>  api.get().then((res) => res.data.highlight)

export const getHighlightById = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.highlight)
}

export const addHighlight = async(body) => {
  return  api.post(``,body)
}

export const updateHighlight = async(body) => {
  return  api.put(`/${body.get("id")}`, body).then((res) => res.data)
} 



export const deleteHighlight = async(id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}