import { Icon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useQuery } from "react-query";
import * as api from "../API/reportsApi";
import { useState , useRef } from "react";
import {  FaChartArea } from "react-icons/fa";





import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import DatePicker from "react-multi-date-picker";
  
  ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
    
    const options = {
      scales: 
          { y:{ ticks: { beginAtZero: true, callback: function(value) 
              { return `$${value}`; } }, 
          }},
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Monthly Revenue',
        },
      },
    }

    const options2 = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Monthly Booking',
        },
      },
    };
    const options3 = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Occupacy Rate',
        },
      },
       scales: 
          { y:{ ticks: { beginAtZero: true, callback: function(value) 
              { return `%${value}`; } }, 
          }}
    };
  
    
    
    
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];
    const Tlabels = [1, 2, 3, 4, 5, 6, 7,8, 9, 10, 11, 12];

function ViewReports({ Id }) {
    let j= 0; let x = 0; let i = 0; let y =0; let a = 0 ; let b = 0;
    const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState();
 

  const { data , refetch } = useQuery("MonthlyRevenu",() => api.getMonthlyRevenu(yearRef.current,id),{
    enabled:Boolean(id),
});
const { data: booking , refetch : bookingRefetch } = useQuery("TotalBooking",() => api.getMonthlyBooking(yearRef.current,id),{
    enabled:Boolean(id),
});
const { data : occupacy , refetch :occupacyRefetch } = useQuery("Occupacy",() => api.getOccupancyRate(yearRef.current,id),{
    enabled:Boolean(id),
});

const yearRef = useRef("2022")
    function handleChange(date){
        yearRef.current = date.format("YYYY")
        refetch()
        bookingRefetch()
        occupacyRefetch()
    }
    

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  const Chartdata = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: Tlabels?.map((d) => {
            if(data && d === data[a]?.month){
              b = a;
                a++;
            return(
                data[b]?.totalRevenue
            )
        }else{
            return 0;
        }
        }),
        backgroundColor: '#FED438',
      },
      
      
    ],  
  };
  const Chartdata2 = {
    labels,
    datasets: [
      {
        label: 'Dataset 2',
        data: Tlabels?.map((d,i) => {
            if(booking && (d === booking[j]?.month)){
                x = j;
                j++;
            return(
                booking[x]?.totalBookings
                
            )
                
        }else{
            return 0;
        }
        }),
        backgroundColor: '#FED438',
      },
      
    ],  
  };
  const Chartdata3 = {
    labels,
    datasets: [
      {
        label: 'Dataset 3',
        data: Tlabels?.map((d,idx) => {
            if(occupacy && (d === occupacy[i]?.month)){
                y = i;
                i++;
            return(
                    // <p>{"%"+occupacy[y]?.occupancyRate}</p>
                    occupacy[y]?.occupancyRate
            )
                
        }else{
            return 0;
        }
        }),
        backgroundColor: '#FED438',
      },
      
    ],  
  };



  return (
    <>

        <Icon
          onClick={() => handleEdit()}
          as={FaChartArea}
          ml={4}
          cursor={"pointer"}
          size={6}
          fontSize={"bold"}
        />
       
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View Guest</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>MonthlyRevenu</Tab>
                <Tab>Booking</Tab>
                <Tab>Occupacy Rate</Tab>
              </TabList>
              <TabPanels>
                  <TabPanel> 
            {data && 
          <Box> 
          <Flex>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options} data={Chartdata} /> 
        </Box>   
        }
                </TabPanel>
              
                <TabPanel>  
                {booking && 
           <Box>
            <Flex mt={10}>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options2} data={Chartdata2} />
        </Box>
        }
                </TabPanel>
                <TabPanel>  
                {occupacy && 
           <Box>
            <Flex mt={10}>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options3} data={Chartdata3} />
        </Box>
        }
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ViewReports;
