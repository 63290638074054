import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/vision`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getVisions = async() => {
    return api.get().then(res => res.data.vision)
}

export const getVisionById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.vision)
   }

 export const addVision = (body) => {
  return api.post('',body).then((res) => res.data)
}
export const editVision = (body) => {
    return api.put('',body).then((res) => res.data)
  }


export const deleteVision = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}