import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
Box,
Button,
HStack,
Input,
InputGroup,
InputLeftAddon,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
Select,
useDisclosure,
useToast
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/countryApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddCountry({Edit, Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
const [id,setId] = useState()
const  [openId,setOpenId] = useState()
const [name,setName] = useState()
const queryClient = useQueryClient();
//POST
const { mutate : postMutate , isLoading } = useMutation([],api.addCountry, {
  onSuccess:
    () => {
        queryClient.invalidateQueries(["Countries"]);
          onClose()
  },
  onError:(error) => {
    toast({
      title: error.response.data.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
})
// GET ALL COUNTRIESS 

const { data : allCountries } = useQuery(
  ['country', id],
  () => api.getAllCountriesInTheWorld().finally(() => {
  }),
  {
      enabled: Boolean(openId),
  }
  )
  
// UPDATE
const { mutate: updateMutate } = useMutation([], api.updateCountry, {
  onSuccess: () => {
    queryClient.invalidateQueries(["Countries"]);
    onClose()
  },
  onError:(error) => {
    toast({
      title: error.response.data.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
});
// GET BY ID
const { data : stayCat } = useQuery(
  ['country', id],
  () => api.getCountryById(id).finally(() => {
  }),
  {
      enabled: Boolean(id),
  }
  )

  function handleOpen(){
    onOpen()
    setOpenId(1)
  }
  
  function handleEdit(){
  
    onOpen()
    setId(Id)
  }
  

  useEffect(() => {
    if(stayCat){
      setName(stayCat.name)
    }
  },[Id,stayCat])

  async function handlePost() {
    if(name.length > 1){
      const data = {
      name : name
    };
      postMutate(data)
  }
  }

  function handleUpdate(){
    
    const data ={
        id:Id,
        name:name
    }
    updateMutate(data)
  }
  return (
    <>
    {Edit === true ? 
    <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
    :
  <Button onClick={() => handleOpen(1)} bg={'#FED438'}>
    Add Country
    <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
  </Button>
}
  <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Add Country</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {/* Info */}
        <HStack
          alignItems={"start"}
          justifyContent={"start"}
          spacing={"10px"}
        >
          <InputGroup>
          <InputLeftAddon children="Countries:" fontSize={"16px"}/>
          <Select  value={name} onChange={(e) => setName(e.target.value)} >
            <option>Select A Country</option>
             {allCountries?.map(c => (
               <option key={c.id} value={c.id}>{c.name}</option>
             ))}
          </Select>
          </InputGroup>
          
        </HStack>
    
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onClose}>
          Close
        </Button>
        {Edit ? 
        <Button variant="outline" onClick={(e) => handleUpdate(e)} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
        :
        <Button variant="outline" onClick={(e) => handlePost(e)} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
      }
      </ModalFooter>
    </ModalContent>
  </Modal>
</>
  );
}
export default AddCountry;
