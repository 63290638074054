import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/cities/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getCitiesByCountryId = async(countryId) =>  {
 return   api.get(`country?countryId=${countryId}`).then((res) => res.data.cities)
}
export const getCities = async() =>  {
  return   api.get('/admin/all').then((res) => res.data.cities)
 }
 export const getWorldCities = async(id) =>  {
  return   api.get('world/city/'+id).then((res) => res.data.cities)
 }
 export const getCityById = async(id) =>  {
  return   api.get(''+id).then((res) => res.data.city)
 }
 export const addCity = async(body) => {
  return api.post('',body).then((res) => res.data)
}

export const deleteCity = async(id) => {
  return api.delete(`/${id}`).then(res => res.data)
}

export const updateCity = async(body) => {
  return api.put(''+body.get('id'),body).then((res) => res.data)
}

export const getStay = async(id) => {
  return  api.get(`admin/${id}`)
}

