import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  VStack,
  useToast
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/stayBookingapi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import DatePicker from "react-multi-date-picker";

function EditStayBooking({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()

  const [checkIn,setCheckIn] = useState()
  const [checkOut,setCheckOut] = useState()
  const [propId,setPropId] = useState()
  const [id, setId] = useState();
  const queryClient = useQueryClient();
  
  //   Get Term Data for Edit

  const { data: StayBooking } = useQuery(
    ["StayBooking", id],
    () => api.getStayBookingById(id).finally(() => {}),
    {
      enabled: Boolean(id)
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.editBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Careers"]);
      onClose();
    }
  });

//   POST
  const { mutate: postMutate , isSuccess } = useMutation([], api.check, {
    onSuccess: () => {
        const data = {
            bookingId: Id,
            checkInDate: checkIn,
            checkOutDate: checkOut
        };
        updateMutate(data);
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  useEffect(() => {
    if (StayBooking) {
        setCheckIn(StayBooking.checkInDate)
        setCheckOut(StayBooking.checkOutDate)
        setPropId (StayBooking.property._id)
    }
  }, [StayBooking, Id]);

  function handleUpdate() {
   
    const body = {
        id:Id,
        propertyId: propId,
        checkInDate: checkIn,
        checkOutDate: checkOut
    }
    postMutate(body)
     
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  function handleClose() {
    onClose();
  }

//   async function handlePost() {
//     const data = {
//         propertyId: Id,
//         checkInDate: checkIn,
//         checkOutDate: checkOut
//     };
//     postMutate(data);
//   }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          cursor={"pointer"}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={'#FED438'}>
          Add Booking
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit Booking" : "Add Booking"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10"}
            >
              <InputGroup>
                <InputLeftAddon children="CheckIn:" />
                <DatePicker
            format="MM/DD/YYYY"
           style={{border:"none",overflow:"hidden",width:"100%",marginTop:"5px"}}
    
      value={checkIn} 
      onChange={setCheckIn}
    />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon children="CheckOut:" />
                <DatePicker
            format="MM/DD/YYYY"
           style={{border:"none",overflow:"hidden",width:"100%",marginTop:"5px"}}
            value={checkOut} 
      onChange={setCheckOut}
    />
              </InputGroup>
              
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            {Edit ? (
                <HStack spacing={5}>
                
              <Button
                variant="outline"
                onClick={() => handleUpdate()}
                bg={"green"}
                textColor={"white"}
              >
                Update <Icon ml={2} as={PlusSquareIcon} />
              </Button>
              </HStack>
            ) : (
              <Button
                variant="outline"
                // onClick={() => handlePost()}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default EditStayBooking;
