import {
    Box,
    Button,
    Flex,
    HStack,
    Select,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
  import { useMemo } from "react";
  import * as api from '../components/API/stayBookingapi'
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
  } from "react-table";
  import { GlobalFilter } from "../components/GlobalFilter";
  import { useQuery } from "react-query";
import ViewStayBooking from "../components/UI/ViewStayBooking";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EditStayBooking from "../components/UI/EditStayBooking";
import CancelBooking from "../components/UI/CancelBooking";


  function StayBookings() {

    const { data } = useQuery("StayBooking", api.getStayBookings);

    function RTable({ columns, data }) {
      // Use the state and functions returned from useTable to build your UI
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        state: { pageIndex, pageSize }
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 10 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        tableHooks
      );
    
      const isEven = (idx) => idx % 2 === 0;
      // Render the UI for your table
      return (
        <Box  ml={10} my={{base:"20"}} minW={{base:"75vw"}} maxW={{base:"80vw"}}>
          {/* Button and Search */}
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={state.globalFilter}
            />
            <Link to="/stayguests">
            <Button  bg={'#FED438'}>
          Stay Guests
        </Button>
        </Link>
          </Flex>
          {/* Table */}
          <Table minW={"100%"} width={"full"}  {...getTableProps()} border={"2px"}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th  {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                  </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    bg={isEven(i) ? "blue.100" : "white"}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {/* Pagination */}
          <HStack mt={"10"} spacing={"10px"}>
            <Box
              className="page-item"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <Button>First</Button>
            </Box>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last
            </Button>
            <Button>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </Button>
            <Select
              cursor={"pointer"}
              w={"32"}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: "120px", height: "38px" }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Select>
          </HStack>
        </Box>
      );
    }
    
    const productsColumns = useMemo(() => {
      const columns = [

        {
          Header: 'Booking Ref',
          accessor: 'confirmationCode',
          Cell: ({ value }) => <div>{value}</div>
      },
        {
          Header: 'Stay Name',
          accessor: 'property.name',
          Cell: ({ value }) => <div>{value}</div>
      },
            {
                Header: 'Guest Name',
                accessor: 'fullName.last',
                Cell: row => {
                    return(
                        <div>{row?.row.original?.user?.fullName?.first +" "+row?.row.original?.user?.fullName?.last}</div>
                    )
                }
            },
        {
          Header: "Total Price",
          accessor: "totalPrice",
          Cell: (row) => <div>{`${row?.row.original?.totalPrice+" "+row?.row.original?.currency.symbol}`}</div>
        },
          {
            Header: "CheckIn",
            accessor: "checkInDate",
            Cell: ({ value }) => <div style={{width:"95px"}}>{`${dayjs(value).format('DD-MMM-YYYY')}`}</div>
          },
          {
            Header: "CheckOut",
            accessor: "checkOutDate",
            Cell: ({ value }) => <div style={{width:"95px"}}>{`${dayjs(value).format('DD-MMM-YYYY')}`}</div>
          },
          {
            Header: "Status",
            accessor: "isConfirmed",
            Cell: (row) => <Box color={row?.row.original?.isConfirmed ? 'green.500' : row?.row.original?.isCancelled ? 'red.500' : 'yellow.600'} fontSize={"18px"} fontWeight="semibold">{row?.row.original?.isConfirmed ? 'Confirmed' : row?.row.original?.isCancelled ? 'Canceled' : 'Pending'}</Box>
          },
      ];
      return columns;
    }, []);


    const tableHooks = (hooks) => {
      
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "action",
          Header: "Action",
          Cell: ({ row }) => (
            <HStack spacing={"5"} alignItems={"center"} justifyContent={"space-between"}>
                {/* <Icon boxSize={"5"} mr={2} onClick={() => handleToggle(row)} cursor={"pointer"} as={row.values.isDisactivated ? BsEyeSlash : BsEye}  /> */}
                {row && <CancelBooking Id={row.original._id} Edit={true}  /> }
                {row && <EditStayBooking Id={row.original._id} Edit={true}  /> }
              {row && <ViewStayBooking Id={row.original._id} Edit={true}  /> }
              {/* <Icon boxSize={"5"} cursor={"pointer"} mx={2} as ={FiTrash} onClick={() => handleDelete(row)} /> */}
            </HStack>
          )
        }
      ]);
    };
  
    return (data) ? (
      <RTable columns={productsColumns} data={data} />
    ) : (
      <div></div>
    );
  }
  
  export default StayBookings;
  