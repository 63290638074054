import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/productsApi";
import * as currenciesApi from "../API/currencyApi";
import {  useEffect, useState } from "react";

function AddDelivery({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [cost,setCost] = useState()
  const [currencyId,setCurrencyId] = useState()

  const queryClient = useQueryClient();

//   Get Term Data for Edit

const { data: shipping } = useQuery(
    "shippingCost",
    api.getShippingCost
  );

const { data: currencies } = useQuery(
    "currencies",
    currenciesApi.getCurrencies
  );


  useEffect(() => {
    if(shipping){
        setCost(shipping.cost)
        setCurrencyId(shipping.currency._id)
        
    }
  },[shipping])

 
  //Post
const { mutate : postMutate  } = useMutation([],api.addShippingCost, {
  onSuccess:
    () => {
        queryClient.invalidateQueries(["deliveryCost"]);
         onClose()
  },
  onError:(error) => {
    toast({
      title: error.response.data.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
})

//Update
const { mutate : updateMutate  } = useMutation([],api.updateShippingCost, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["deliveryCost"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

 


    async function handlePost() {
        const data =  {
            cost,
            currencyId,
        }
        if(shipping){
          updateMutate(data)
        }else{
          postMutate(data)
        }

    }
   
  return (
    <>
        
      <Button onClick={onOpen}  bg={"green.100"}>
         Shipping Cost
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Shipping Cost'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
                <InputGroup>
                <InputLeftAddon children="Cost"/>
              <Input value={cost} onChange={(e) => setCost(e.target.value)}  placeholder="amount"   px={2}  />
                </InputGroup>
                  <Select value={currencyId} onChange={(e) => setCurrencyId(e.target.value)}  >
                    <option>Select Currency</option>
                      {currencies?.map(c => (
                        <option value={c._id} key={c._id}>{c.name}</option>
                      ))}
                  </Select>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddDelivery;
