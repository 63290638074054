import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../config";

const api = axios.create({
  baseURL: `${baseURL}/v1/media`,
  headers: { Authorization: Cookies.get("admin-token") },
});
export const deleteImages = async (body) => {
  return api.delete("", { data: body }).then((res) => res.data);
};

export const addImage = async (body) => {
  return api
    .post(`?type=${body.get("type")}&typeId=${body.get("typeId")}`, body)
    .then((res) => res.data);
};

export const getIcons = async () => {
  return api.get("/social-icons").then((res) => res.data.icons);
};
export const getIconById = async (id) => {
  return api.get("/social-icons/" + id).then((res) => res.data.icon);
};

export const addIcon = async (body) => {
  return api.post(`social-icons`, body).then((res) => res.data);
};
export const updateIcon = async (body) => {
  return api
    .put(`social-icons/${body.get("id")}`, body)
    .then((res) => res.data);
};
export const resortImage = async (body) => {
  return api.put(`sort/`, body).then((res) => res.data);
};
export const deleteIcon = async (id) => {
  return api.delete(`social-icons/${id}`).then((res) => res.data);
};
