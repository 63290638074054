import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/order/admin`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getOrders = async() => {
    return api.get().then(res => res.data.orders)
}

export const getOrderById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.items)
   }
