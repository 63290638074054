import { Box, Input, Text, } from "@chakra-ui/react";
import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";


export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <Box>
      <Text fontSize={"18px"} fontWeight={"semibold"}>Search:</Text>
      <Input
        border={"2px"}
        borderColor={"black"}
        mb={"4"}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </Box>
  );
}