import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/terms`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getTerms = async() => {
    return api.get().then(res => res.data.cheezTerms)
}

export const getTermById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.cheezTerms)
   }

 export const addTerm = (body) => {
  return api.post('',body).then((res) => res.data)
}
export const editTerm = (body) => {
    return api.put('',body).then((res) => res.data)
  }


export const deleteTerm = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}