import { Icon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import * as api from "../API/staysApi";
import { useMemo, useState } from "react";
import { AiOutlineOrderedList } from "react-icons/ai";
import { GlobalFilter } from "../GlobalFilter";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";

function ViewGuestData({Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id,setId] = useState()



  
//   Get Term Data for Edit

  const { data : Guests } = useQuery(
    ['guestsData', id],
    () => api.getStayGuestsByBId(id).finally(() => {
       
    }),
    {
        enabled: Boolean(id),
    }
  )

  


  function handleEdit(){
    onOpen()
    setId(Id)
  }
  function handleClose(){
      onClose()    
  }

  const productsColumns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Id",
      //   accessor: "_id",
      //   Cell: ({ value }) => <Box _hover={{color:"blue"}}><Link to={`/term/${value}`}  >{`${value}`}</Link></Box>
      // },
      {
        Header: "Property",
        accessor: "stayBooking.property.name",
        Cell: ({ value }) => <div>{`${value}`}</div>
      },
      {
        Header: "Full Name",
        accessor: "fullName.last",
        Cell: row =>{

          return(
          <div>{row?.row.original?.fullName?.first +" "+row?.row.original?.fullName?.last}</div>
      )
          }
      },
      {
          Header: "Country",
          accessor: "country",
          Cell: ({ value }) => <div>{`${value}`}</div>
        },
        {
          Header: "City",
          accessor: "city",
          Cell: ({ value }) => <div>{`${value}`}</div>
        },
        {
          Header: "Gender",
          accessor: "gender",
          Cell: ({ value }) => <div>{`${value}`}</div>
        },
        {
          Header: "Nationality",
          accessor: "nationality",
          Cell: ({ value }) => <div>{`${value}`}</div>
        },
        {
          Header: "Document",
          accessor: "document",
          Cell: ({ value }) => <Link _hover={{color:"blue",textDecoration:"underline"}} type="file" href={`${value}`} target="_blank" rel="noreferrer" >{`Click to open the document`}</Link> 
          
          
        },
        {
          Header: "Status",
          accessor: "isConfirmed",
          Cell: ({ value }) => <div>{`${value ? 'Confirmed' : 'Canceled'}`}</div>
        },
    ];
    return columns;
  }, []);



  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize:10 }
      },
      useGlobalFilter,
      useSortBy,
      usePagination,

    );
  
    
    // Render the UI for your table
    return (
      <Box  w="full" pb={5} overflowX="scroll">
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          
        </Flex>
        {/* Table */}
        <Table className="table" w="full" overflowX="scroll"  {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr border="2px solid black" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th fontSize="15px" textColor="black" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                {column.isSorted
                  ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                  : ''}
              </span>
                </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  // bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td border="2px solid black" {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }


   
  return (
    <>

        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={AiOutlineOrderedList} ml={4} size={6} fontSize={"bold"} />
    
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View Guest Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          {Guests &&  <RTable columns={productsColumns} data={Guests} />}
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() =>handleClose()}>
              Close
            </Button>
          
            {/* <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ViewGuestData;
