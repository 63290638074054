import firebase from 'firebase'
import 'firebase/auth'
import Cookies from 'js-cookie';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDA_BjxkhMueNhoCNcPF11__sHUUtQb5ig",
    authDomain: "cheez-hospitality.firebaseapp.com",
    databaseURL: "https://cheez-hospitality.firebaseio.com",
    projectId: "cheez-hospitality",
    storageBucket: "cheez-hospitality.appspot.com",
    messagingSenderId: "864179586667",
    appId: "1:864179586667:web:0cb1c2e097a32fd371d32e",
    measurementId: "G-0CPTZ5ZW19"
  };

  const app = !firebase.apps.length 
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app()

  // const messaging = firebase.messaging()
  // const public_key = "BEjhJUme1GWdJdDXgv9B6d1CRWFHKDrkusaL4PiRv6ZNq1jJa2lSmxU--ZnopyCIruzkC66t1twckX6yt1PIV9Y"
  // export const getToken = async(setTokenFound) => {
  //   let currentToken = '';
  //   try{
  //     currentToken = await messaging.getToken({vapi_key:public_key});
  //     if(currentToken){
  //       setTokenFound(true)
  //       Cookies.set('fcm-admin-token',currentToken)
  //       console.log(currentToken)
  //     }else{
  //       setTokenFound(false)
  //     }
  //   }catch(error){
  //     console.log('An Error ocourd while retrieving token',error)
  //   }
  // }

  // export const onMessageListener = () =>
  // new Promise((resolve) => {
  //   messaging.onMessage((payload) => {
  //     resolve(payload);
  //   });
  // });


  

  const db= app.firestore();
  const auth = app.auth();

  const provider = new firebase.auth.GoogleAuthProvider();


  export {db, auth, provider}