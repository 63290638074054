import {
  Box,
  Button,
  Flex,
  HStack,
  useToast,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import * as api from "../components/API/toursApi";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GlobalFilter } from "../components/GlobalFilter";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddTour from "../components/UI/AddTour";
import { Icon } from "@chakra-ui/icons";
import { FiTrash } from "react-icons/fi";
import AddImages from "../components/UI/AddImages1";

import AddTourDates from "../components/UI/AddTourDates";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import AlertDialogModal from "../components/UI/AlertDialogModal";
import { useHistory } from "react-router-dom";
function Tours() {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState();
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const toast = useToast();
  const { data: toursData } = useQuery("Tours", api.getTours);

  const { mutate: updateMutate } = useMutation([], api.updateTour, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { mutate: deleteMutate } = useMutation([], api.deleteTour, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
      setIsOpen(false);
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  async function handleDelete(row) {
    if (row.original._id) {
      deleteMutate(row.original._id);
    }
  }
  function handleOpenAlert(row) {
    setIsOpen(true);
    setSelectedRow(row);
  }
  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      tableHooks
    );

    const isEven = (idx) => idx % 2 === 0;
    // Render the UI for your table
    return (
      <Box
        ml={10}
        my={{ base: "20" }}
        minW={{ base: "75vw" }}
        maxW={{ base: "80vw" }}
      >
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          {/* <Button onClick={() => history.push('/tours/addtour')}>Add Tour</Button> */}
          <AddTour />
        </Flex>
        {/* Table */}
        <Table className="table" {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }

  function handleToggle(row) {
    const data = {
      id: row.original._id,
      enabled: !row.original.enabled,
    };
    updateMutate(data);
  }

  const productsColumns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Id",
      //   accessor: "_id",
      //   Cell: ({ value }) => <Box _hover={{color:"blue"}}><Link to={`/product/${value}`}  >{`${value}`}</Link></Box>
      // },

      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Category",
        accessor: "category.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      // 616971d2646b20d3a14cbb1f
      {
        Header: "City",
        accessor: "city.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Price",
        accessor: "price.amount",
        Cell: ({ value }) => (
          <div>{`$${
            value.adults + " $" + value.childrens + " $" + value.infants
          }`}</div>
        ),
      },
      {
        Header: "Enable",
        accessor: "enabled",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
    ];
    return columns;
  }, []);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }) => (
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Icon
              cursor={"pointer"}
              boxSize={"5"}
              mr={2}
              onClick={() => handleToggle(row)}
              as={row.values.enabled ? BsEyeSlash : BsEye}
            />
            {row && <AddTour Id={row.original._id} Edit={true} />}
            {row && <AddTourDates Id={row.original._id} />}
            {row && (
              <AddImages
                Id={row.original._id}
                type={"tours"}
                oldImages={row.original.images}
              />
            )}
            <Icon
              boxSize={"5"}
              cursor={"pointer"}
              mx={2}
              as={FiTrash}
              onClick={() => handleOpenAlert(row)}
            />
          </Flex>
        ),
      },
    ]);
  };

  return toursData ? (
    <Box>
      <AlertDialogModal
        onClose={onClose}
        isOpen={isOpen}
        handleDelete={handleDelete}
        setIsOpen={setIsOpen}
        cancelRef={cancelRef}
        row={selectedRow}
      />
      <RTable columns={productsColumns} data={toursData} />
    </Box>
  ) : (
    <div></div>
  );
}

export default Tours;
