import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/areaApi";
import * as cityApi from '../API/citiesApi'
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
function AddArea({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id,setId] = useState()
  const [name,setName] = useState()
  const [city,setCity] = useState("")
  const toast = useToast()
  const queryClient = useQueryClient();

  const {data :cities} = useQuery("Cities", cityApi.getCities)
//POST

const { data : area } = useQuery(
  ['area', id],
  () => api.getAreaById(id).finally(() => {
     
  }),
  {
      enabled: Boolean(id),
  }
)


useEffect(() => {
  if(Edit && area) {
    setName(area.name)
    setCity(area.city)
  }
},[area])

const { mutate : postMutate  } = useMutation([],api.addArea, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Areas"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })
  const { mutate : updateMutate  } = useMutation([],api.editArea, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Areas"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  function handleEdit() {
    onOpen();
    setId(Id);
  }

    async function handlePost() {
      if(name.length > 1 && city.length > 1){
        const data = {
       name:name,
       city:city,
      };
        postMutate(data)
    }
    }
    async function handleUpdate() {
      
        const data = {
          id,
       name:name,
       city:city,
      };
        updateMutate(data)
    }
   
  return (
    <>
    {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          cursor={"pointer"}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Area
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
      )}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Area</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
              <InputLeftAddon children="name" />
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
                {/* <Textarea value={description} onChange={(e) => setDescription(e.target.value)} /> */}
              <Select value={city} onChange={(e) =>setCity(e.target.value) }>
              <option  >Select City</option>
                    {cities?.map(c => (
                        <option value={c._id}   key={c._id} >{c.name}</option>
                    ))}
              </Select>
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ?
            <Button
              variant="outline"
              onClick={() => handleUpdate()}
              bg={"green"}
              textColor={"white"}
            >
              Update 
              <Icon ml={2} as={PlusSquareIcon} />
            </Button>
            :
            <Button
              variant="outline"
              onClick={() => handlePost()}
              bg={"green"}
              textColor={"white"}
            >
              Add 
              <Icon ml={2} as={PlusSquareIcon} />
            </Button>
}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddArea;
