import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/careers`,
    headers: {'authorization':Cookies.get('admin-token')}
})


export const getCareers = async() => {
    return api.get().then(res => res.data.careers)
}

export const getCareerById = async(id) =>  {
    return   api.get(`/${id}`).then((res) => res.data.career)
   }

 export const addCareer = (body) => {
  return api.post('',body).then((res) => res.data)
}
export const editCareer = (body) => {
    return api.put(''+body.id,body).then((res) => res.data)
  }


export const deleteCareer = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}