import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {BiArrowBack} from 'react-icons/bi'
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../components/API/adminApi";

import { useState } from "react";
import { useHistory } from "react-router-dom";

function AddAdmin({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const [id, setId] = useState();
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [pType, setPType] = useState("password");
  const [phone, setPhone] = useState(undefined);
  const [role, setRole] = useState(1);
  const queryClient = useQueryClient();

  const { data: Admin } = useQuery(
    ["admin", id],
    () => api.getAdmin(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Admins"]);
      toast({
        title: "Admin Added Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admins");
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // POST
  const { mutate: postMutate } = useMutation([], api.addAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Admins"]);
      toast({
        title: "Admin Added Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admins");
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const handlePost = async (event) => {
    event.preventDefault();
    const data = {
      id: Id,
      fullName: name,
      email: email,
      password: password,
      phoneNumber: phone,
      isEmailVerified: true,
      role: role,
    };
    if (Edit) {
      updateMutate(data);
    } else {
      postMutate(data);
    }
  };

  return (
    <Box position={"fixed"} bg={"white"} h={"100vh"} w={"100vw"} zIndex={"99"}>
      <Flex alignItems={"center"} justifyContent={"space-between"} my={"5"} >
      <IconButton
        onClick={() => history.push("/admins")}
        ml={"20"}
        colorScheme="gray"
        aria-label="Back"
        icon={<BiArrowBack />}
      />
      <Text mr={"20"}  fontSize={"24"} fontWeight={"bold"}>
        Add Admin
      </Text>
      <Text></Text>
      </Flex>
      <Box py={"10"} px={"20"}>
        <form onSubmit={handlePost}>
          {/* Info */}
          <VStack
            alignItems={"start"}
            justifyContent={"start"}
            spacing={"15px"}
          >
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                value={name === undefined ? Admin?.fullName : name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type={"email"}
                placeholder="Email"
                value={email === undefined ? Admin?.email : email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Phone</FormLabel>
              <Input
                type={"number"}
                required
                placeholder="Phone"
                value={phone === undefined ? Admin?.phoneNumber : phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={pType}
                  required
                  placeholder="Password"
                  value={password === undefined ? Admin?.password : password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightAddon>
                  {" "}
                  {pType === "password" ? (
                    <BsEye onClick={() => setPType("text")} cursor="pointer" />
                  ) : (
                    <BsEyeSlash
                      onClick={() => setPType("password")}
                      cursor="pointer"
                    />
                  )}{" "}
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Role</FormLabel>
              <Select required onChange={(e) => setRole(e.target.value)}>
                <option value={1}>Admin</option>
                <option value={2}>Editor</option>
                <option value={3}>Viewer</option>
              </Select>
            </FormControl>
          </VStack>
          <Box mt={"8"}>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="outline"
              type="submit"
              bg={"green"}
              textColor={"white"}
            >
              {Edit ? "Update" : "Add"}
              <Icon ml={2} as={PlusSquareIcon} />
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
export default AddAdmin;
