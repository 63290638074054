import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  InputLeftAddon,
  InputGroup,
  useDisclosure,
  Image,
  useToast,
  Text,
  Box,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/blogsApi";
import { useEffect, useState, useRef } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Editor } from "@tinymce/tinymce-react";

function AddBlog({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const queryClient = useQueryClient();
  const [nbPosts, setNbPosts] = useState(1);
  const [content, setContent] = useState("");

  const editorRef = useRef(null);

  //POST
  const { mutate: postMutate } = useMutation([], api.addBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.addBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Blogs"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  // GET BY ID
  const { data: stayCat } = useQuery(
    ["blog", id],
    () => api.getBlogById(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  useEffect(() => {
    if (stayCat) {
      setTitle(stayCat.title);
    }
    if (Edit) {
      setNbPosts(stayCat?.images.length);
    }
  }, [Id, stayCat]);

  async function handlePost() {
    const data = new FormData();
    let images = [];
    let captions = [];
    data.append("title", title);
    [...Array(nbPosts)].map(
      (_, i) => (
        data.append("photos", document.getElementById("image" + i).files[0]),
        (captions[i] = document.getElementById("caption" + i).value)
      )
    );

    data.append("captions", JSON.stringify(captions));
    data.append("content", editorRef.current.getContent());
    postMutate(data);
  }

  function handleUpdate() {
    const data = new FormData();
    data.append("id", id);
    [...Array(nbPosts)].map(
      (_, i) => (
        data.append("image", document.getElementById("image" + i).files[0]),
        data.append("caption", document.getElementById("caption" + i).value)
      )
    );
    data.append("content", editorRef.current.getContent());
    updateMutate(data);
  }
  return (
    <>
      {Edit === true ? (
        <Icon
          cursor={"pointer"}
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Blog
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit" : "Add"} Blog</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup>
              <InputLeftAddon children="Title:" />
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputGroup>
            {/* Info */}
            {[...Array(nbPosts)].map((_, i) => (
              <HStack
                alignItems={"start"}
                justifyContent={"start"}
                spacing={"10px"}
                mt={10}
              >
                <InputGroup>
                  {Edit && (
                    <InputLeftAddon>
                      <Image
                        src={stayCat?.images[i].imageUrl}
                        w="10"
                        h="10"
                        rounded={"full"}
                      />
                    </InputLeftAddon>
                  )}
                  <Input id={"image" + i} type="file" pt={"1"} />
                </InputGroup>
                <Input
                  id={"caption" + i}
                  type="text"
                  placeholder="Caption"
                  defaultValue={stayCat?.images[i]?.caption}
                />
                <Flex alignItems={"center"}>
                  <Button
                    mb={2}
                    mr={2}
                    colorScheme={"green"}
                    onClick={() => setNbPosts((prev) => prev + 1)}
                  >
                    <Icon as={BsPlusCircle} />
                  </Button>
                  <Button
                    mb={2}
                    onClick={() => setNbPosts(nbPosts - 1)}
                    display={nbPosts === 1 ? "none" : ""}
                    colorScheme={"red"}
                  >
                    <Icon as={AiOutlineDelete} />
                  </Button>
                </Flex>
              </HStack>
            ))}
            <Box mt={"7%"} mx={"5%"} w="70%">
              <Text mb={"30px"} fontWeight={"bold"} fontSize={"30px"}>
                Content
              </Text>
              <Editor
                disabled={false}
                apiKey={"ztvmz6lwnmoiq8bqlmnv2ju7oj4csno3k4vqhuodpl2q0ju6"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content}
                init={{
                  height: 500,
                  menubar: true,
                  selector: "textarea",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />

              <Flex justifyContent={"space-between"}>
                {/* <Image cursor={"pointer"} onClick={() => document.getElementById("image").click()} src={newImage ? newImage : data?.image} w={"50%"} h="450px"  /> */}
                {/* <Input href={missionImage} display={"none"} type="file" onChange={(e) => handleChange(e) } id="image" /> */}
              </Flex>
              <Flex my={"5"} alignItems={"center"}>
                <Button mr={"5"} onClick={() => handleUpdate()}>
                  Save
                </Button>
                <Button
                  mr={"5"}
                  onClick={() => document.getElementById("image")?.click()}
                >
                  Upload Image
                </Button>
                {/* <AddMission image={data?.image} /> */}
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={(e) => handleUpdate(e)}
                bg={"green"}
                textColor={"white"}
              >
                Update <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={(e) => handlePost(e)}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddBlog;
