import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function LazyImage({ src, alt, className, onClick, style }) {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      effect="blur"
      height="100%"
      width="100%"
      className={className}
      onClick={onClick}
    />
  );
}
export default LazyImage;
