import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
  baseURL: `${baseURL}/v1/cancelpolicy`,
  headers: { 'authorization': Cookies.get('admin-token') }
})

export const getCancelations = async () => api.get().then((res) => res.data.policies)


export const getCancelationById = (id) => {
  return api.get(`/${id}`)
}
