import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/guestApi";
import { useEffect, useState, useMemo } from "react";
import { FiEdit } from "react-icons/fi";
import * as citiesApi from "../API/citiesApi";
import dayjs from "dayjs";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GlobalFilter } from "../GlobalFilter";
import * as countryApi from "../API/countryApi";

function AddProduct({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [first, setFirst] = useState();
  const [last, setLast] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [bday, setBday] = useState();
  const [nationality, setNationality] = useState();
  const [addressId, setAddressId] = useState();
  const [Index, setIndex] = useState();
  const [addr, setAddr] = useState();
  const [booking, setBooking] = useState();
  const queryClient = useQueryClient();
  // GET BY ID
  const { data: guest } = useQuery(
    ["guest", id],
    () => api.getGuest(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  const { data: countryCities } = useQuery("Cities", citiesApi.getCities);
  const { data: countries } = useQuery("Countries", countryApi.getCountries);

  const { mutate: postMutate } = useMutation([], api.addGuest, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Guests"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { mutate: updateMutate } = useMutation([], api.updateGuest, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Guests"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const productsColumns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Id",
      //   accessor: "_id",
      //   Cell: ({ value }) => <Box _hover={{color:"blue"}}><Link to={`/term/${value}`}  >{`${value}`}</Link></Box>
      // },
      {
        Header: "Booking Ref",
        accessor: "confirmationCode",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Property",
        accessor: "name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Arrival",
        accessor: "checkInDate",
        Cell: ({ value }) => (
          <div>{`${dayjs(value).format("DD-MM-YYYY")}`}</div>
        ),
      },
      {
        Header: "Departure",
        accessor: "checkOutDate",
        Cell: ({ value }) => (
          <div>{`${dayjs(value).format("DD-MM-YYYY")}`}</div>
        ),
      },
      {
        Header: "Nights",
        accessor: "nights",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Adults",
        accessor: "numberOfGuests.adults",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Children",
        accessor: "numberOfGuests.childrens",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Infants",
        accessor: "numberOfGuests.infants",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Status",
        accessor: "isConfirmed",
        Cell: ({ value }) => <div>{`${value ? "Confirmed" : "Canceled"}`}</div>,
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
    ];
    return columns;
  }, []);

  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    // Render the UI for your table
    return (
      <Box w="full" pb={5} overflowX="scroll">
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Flex>
        {/* Table */}
        <Table
          className="table"
          w="full"
          overflow="scroll"
          {...getTableProps()}
          border={"2px"}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr
                border="2px solid black"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <Th
                    fontSize="15px"
                    textColor="black"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  // bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td border="2px solid black" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }

  useEffect(() => {
    if (Edit && guest) {
      setBday(guest.user.birthday);
      setEmail(guest.user.email);
      setFirst(guest.user.fullName.first);
      setLast(guest.user.fullName.last);
      setPhone(guest.user.phoneNumber || guest.user.otherPhoneNumbers[0] || "");
      setAddr(guest.addresses);
      setNationality(guest.user.nationality);
      setBooking(guest.bookingInfo);
    }
  }, [Id, guest]);

  function handlePost() {
    
    const body = {
      fullName: {
        first: first,
        last: last,
      },
      phoneNumber: phone,
      email,
      password,
    };
    postMutate(body);
  }

  function handleChange(addrId, Idx) {
    setAddressId(addrId);
    setIndex(Idx);
  }

  function handleUpdate() {
    
    const regionAndRoad = document.getElementById("region" + Index)?.value;
    const buildingAndFloor = document.getElementById("building" + Index)?.value;
    const cityId = document.getElementById("city" + Index)?.value;
    const countryId = document.getElementById("country" + Index)?.value;

    let data;
    if (Index) {
      data = {
        id,
        fullName: {
          first: first,
          last: last,
        },
        phoneNumber: phone,
        email,
        birthday: bday,
        nationality: nationality,
        address: {
          addressId: addressId,
          regionAndRoad,
          buildingAndFloor,
          countryId,
          cityId,
        },
      };
    } else {
      data = {
        id,
        fullName: {
          first: first,
          last: last,
        },
        phoneNumber: phone,
        email,
      };
    }
    updateMutate(data);
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          cursor={"pointer"}
          w="5"
          h="5"
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add User
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          
            <ModalHeader>{Edit ? "Edit User" : "Add User"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Tabs>
                <TabList>
                  <Tab>Basic Info</Tab>
                  <Tab display={Edit ? "block" : "none"}>BookingInfo</Tab>
                </TabList>
                <TabPanels>
                  {/* BASIC INFO */}
                  <TabPanel>
                    {/* Info */}

                    <VStack
                      mt={"5"}
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"7"}
                    >
                      <VStack spacing={"5"}>
                        <HStack spacing={"2"}>
                          <FormControl isRequired>
                            <FormLabel>Firstname</FormLabel>
                            <Input
                              placeholder="First Name"
                              value={first}
                              onChange={(e) => setFirst(e.target.value)}
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                              placeholder="Last Name"
                              value={last}
                              onChange={(e) => setLast(e.target.value)}
                            />
                          </FormControl>
                        </HStack>
                        <FormControl isRequired>
                          <FormLabel>Email</FormLabel>
                          <Input
                            placeholder="Email"
                            type={"email"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Phone</FormLabel>
                          <Input
                            placeholder="Phone"
                            type={"number"}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Password</FormLabel>
                          <Input
                            placeholder="Password"
                            type="password"
                            value={password}
                            dispaly={Edit ? "none" : "block"}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormControl>
                        <Input
                          placeholder="Nationality"
                          value={nationality}
                          display={Edit ? "block" : "none"}
                          onChange={(e) => setNationality(e.target.value)}
                          w={"30%"}
                        />
                      </VStack>

                      <HStack spacing="" my={"4"}>
                        {/* <Text >{"Region and Road:"}</Text> */}
                        {/* <Text >{"Building and Floor:"} </Text> */}
                        <Text display={Edit ? "block" : "none"}>
                          {"Addresses: Please Only Edit 1 address at the time"}{" "}
                        </Text>
                      </HStack>
                      {addr?.map((add, idx) => (
                        <HStack my={"4"}>
                          <Text>{Number(idx + 1) + "-"}</Text>
                          <Input
                            type="text"
                            id={`region${idx}`}
                            defaultValue={add.regionAndRoad}
                            onChange={() => handleChange(add._id, idx)}
                          />
                          <Input
                            type="text"
                            id={`building${idx}`}
                            defaultValue={add.buildingAndFloor}
                            onChange={() => handleChange(add._id, idx)}
                          />
                          <Box w="full">
                            <Select
                              defaultValue={add.country._id}
                              id={`country${idx}`}
                              onChange={() => handleChange(add._id, idx)}
                              colorScheme={"green"}
                            >
                              <option>Select a Country</option>
                              {countries?.map((b, i) => (
                                <option key={b._id} value={b._id}>
                                  {b.name}
                                </option>
                              ))}
                            </Select>
                          </Box>
                          <Select
                            defaultValue={add.city._id}
                            id={`city${idx}`}
                            onChange={() => handleChange(add._id, idx)}
                            colorScheme={"green"}
                          >
                            <option>{"Select a City"}</option>
                            {countryCities?.map((b) => (
                              <option key={b._id} value={b._id}>
                                {b.name}
                              </option>
                            ))}
                          </Select>
                          <Checkbox defaultChecked={add.isDefault}>
                            IsDefault
                          </Checkbox>
                        </HStack>
                      ))}
                    </VStack>
                  </TabPanel>
                  {/* Booking Info */}
                  <TabPanel>
                    {booking && (
                      <RTable columns={productsColumns} data={booking} />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              {Edit ? (
                <Button
                  variant="outline"
                  type="submit"
                  onClick={() => handleUpdate()}
                  bg={"green"}
                  textColor={"white"}
                >
                  Update
                  <Icon ml={2} as={PlusSquareIcon} />
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="submit"
                  onClick={() => handlePost()}
                  bg={"green"}
                  textColor={"white"}
                >
                  Add
                  <Icon ml={2} as={PlusSquareIcon} />
                </Button>
              )}
            </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddProduct;
