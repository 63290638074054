import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";
import * as api from "../components/API/orderApi";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GlobalFilter } from "../components/GlobalFilter";
import { useQuery } from "react-query";
import ViewOrder from "../components/UI/ViewOrder";

function Orders() {
  const { data } = useQuery("Guests", api.getOrders);

  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      tableHooks
    );

    const isEven = (idx) => idx % 2 === 0;
    // Render the UI for your table
    return (
      <Box
        ml={10}
        my={{ base: "20" }}
        minW={{ base: "75vw" }}
        maxW={{ base: "80vw" }}
      >
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          {/* <AddGuest Edit={false} /> */}
        </Flex>
        {/* Table */}
        <Table minW={"100%"} width={"full"} {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }

  const productsColumns = useMemo(() => {
    const columns = [
      {
        Header: "Confimation",
        accessor: "confirmationCode",
        Cell: ({ value }) => <div>{`${value ? value : "no phone"}`}</div>,
      },
      {
        Header: "Name",
        accessor: "fullName.last",
        Cell: (row) => {
          return (
            <div>
              {row?.row.original?.user?.fullName?.first +
                " " +
                row?.row.original?.user?.fullName?.last}
            </div>
          );
        },
      },
      {
        Header: "Phone",
        accessor: "user.phoneNumber",
        Cell: (row ,{ value }) => <div>{`${value ? value : row.row.original?.user.otherPhoneNumbers?.length > 0 ? row.row.original?.user.otherPhoneNumbers[0]  : 'no phone' }`}</div>,
      },
      {
        Header: "Email",
        accessor: "user.email",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "is Confirmed",
        accessor: "isConfirmed",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
    ];
    return columns;
  }, []);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }) => (
          <HStack
            spacing={"2"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {row && <ViewOrder Id={row.original._id} Edit={true} />}
          </HStack>
        ),
      },
    ]);
  };

  return data ? <RTable columns={productsColumns} data={data} /> : <div></div>;
}

export default Orders;
