import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Modal,
  Tabs,
  TabPanel,
  TabPanels,
  Tab,
  TabList,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
  IconButton,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";

import * as api from "../API/toursApi";
import * as catApi from "../API/categoryApi";
import * as countryApi from "../API/countryApi";
import * as areaApi from "../API/areaApi";
import * as highApi from "../API/highlightApi";
import * as citiesApi from "../API/citiesApi";
import * as currenciesApi from "../API/currencyApi";
import * as cancelApi from "../API/cancelationApi";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import EditTourDate from "./EditTourDate";

function AddTour({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [countryId, setCountryId] = useState("");
  const [desc, setDesc] = useState();
  const [rules, setRules] = useState();
  const [title, setTitle] = useState();
  const [internalName, setInternalName] = useState();
  const [subtitle, setSubtitle] = useState();
  const [cityId, setCityId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [categoryId, setCategoryId] = useState();
  const [importantInfo, setImportantInfo] = useState(1);
  const [highlightsId, setHighlightsId] = useState();
  const [itineraryCounter, setItineraryCounter] = useState(1);
  const [policyId, setPolicyId] = useState();
  const [meals, setMeals] = useState();
  const [sale, setSale] = useState({ salePercent: 0, onSale: false });
  const [event, setEvent] = useState({ description: "", details: [] });
  const [detailsCount, setDetailsCount] = useState(1);
  const [WTBCounter, setWTBCounter] = useState(1);
  const [time, setTime] = useState();
  const [checkH, setCheckH] = useState();
  const [tourDates, setTourDates] = useState([]);
  const [numberOfGuests, setNumberOfGuests] = useState({
    minimum: "",
    maximum: "",
    adults: "",
    childrens: "",
    infants: "",
  });
  let isChecked = [];
  const [hCheck, setHCheck] = useState([]);
  const [pickUp, setPickUp] = useState({ locationName: "", lat: "", long: "" });
  const [amount, setAmount] = useState({
    adults: "",
    infants: "",
    childrens: "",
  });
  const [currencyId, setCurrencyId] = useState();
  const [dropOff, setDropOff] = useState({
    locationName: "",
    lat: "",
    long: "",
  });

  const queryClient = useQueryClient();

  const { data: categories } = useQuery(
    "categories",
    catApi.getToursCategories
  );
  const { data: countries } = useQuery("countries", countryApi.getCountries);
  const { data: highlights } = useQuery("highlights", highApi.getHighlights);
  const { data: cancelations } = useQuery(
    "cancelations",
    cancelApi.getCancelations
  );
  const { data: currencies } = useQuery(
    "currencies",
    currenciesApi.getCurrencies
  );
  const { data: areas } = useQuery("areas", areaApi.getArea);
  // GET CITY BY COUNTRY ID
  const { data: countryCities } = useQuery(
    ["Citystay", countryId],
    () => citiesApi.getCitiesByCountryId(countryId).finally(() => {}),
    {
      enabled: Boolean(countryId),
    }
  );

  // Add Tour
  const { mutate: postMutate } = useMutation([], api.addTour, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  //   Get Stay Data for Edit
  const { data: tour } = useQuery(
    ["Tour", id],
    () => api.getTour(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateTour, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteTourDate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    let checkHigh = [];
    if (Edit === true && tour) {
      highlights?.map(
        (h, idx) =>
          (checkHigh[idx] = tour.highlights?.find((amn) => amn === h._id)
            ? true
            : false)
      );
      setPolicyId(tour.cancelationPolicy);
      setCheckH(checkHigh);
      setTitle(tour.title);
      setInternalName(tour.internalName);
      setSubtitle(tour.subtitle);
      setDesc(tour.briefDescription);
      setCategoryId(tour.category);
      setHighlightsId(tour.highlights);
      setTourDates(tour.tourDates);
      setCountryId(tour.country);
      setCityId(tour.city);
      setTime(tour.time);
      setAreaId(tour.area);
      setImportantInfo(
        tour.importantInfo.length > 0 ? tour.importantInfo.length : 1
      );
      setPickUp({
        locationName: tour.pickUp.locationName,
        lat: tour.pickUp.lat,
        long: tour.pickUp.long,
      });
      setDropOff({
        locationName: tour.dropOff.locationName,
        lat: tour.dropOff.lat,
        long: tour.dropOff.long,
      });
      setNumberOfGuests({
        minimum: tour.numberOfGuests.minimum,
        maximum: tour.numberOfGuests.maximum,
        adults: tour.numberOfGuests.adults,
        childrens: tour.numberOfGuests.childrens,
        infants: tour.numberOfGuests.infants,
      });
      setAmount({
        adults: tour.price.amount.adults,
        childrens: tour.price.amount.childrens,
        infants: tour.price.amount.infants,
      });
      setCurrencyId(tour.price.currency);
      setItineraryCounter(
        tour.itinerary.length > 0 ? tour.itinerary.length : 1
      );
      setSale({
        salePercent: Number(tour?.sale?.salePercent * 100),
        onSale: tour.sale?.onSale,
      });
      setMeals(tour.meals);
      setEvent({
        details: tour?.program.event?.details,
        description: tour.program.event.description,
      });
      setWTBCounter(
        tour.program.whatToBring.length > 0
          ? tour.program.whatToBring.length
          : 1
      );
      setDetailsCount(
        tour.program.event.details.length > 0
          ? tour.program.event.details.length
          : 1
      );
      setRules(tour.rules);
    }
  }, [Edit, tour, highlights]);

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  function handleHighlights(id) {
    if (highlightsId?.find((hId) => hId === id)) {
      setHighlightsId(highlightsId.filter((hId) => hId !== id));
    } else {
      if (highlightsId?.length < 1) {
        setHighlightsId([id]);
      } else {
        setHighlightsId([...highlightsId, id]);
      }
    }
  }

  function handleDelete(tourid) {
    const data = {
      tourDateId: tourid,
    };
    deleteMutate(data);
  }

  // UPDATE REQUEST
  function handleUpdatingTour() {
    let arr = [];
    [...Array(itineraryCounter)].map((_, i) => {
      arr[i] = {
        description: document.getElementById(`desc${i}`).value,
        lat: parseFloat(document.getElementById(`lat${i}`).value),
        long: parseFloat(document.getElementById(`long${i}`).value),
      };
    });

    let details = [];
    [...Array(detailsCount)].map((_, i) => {
      details[i] = document.getElementById("details" + i)?.value;
    });
    let WTBRING = [];
    [...Array(WTBCounter)].map((_, i) => {
      WTBRING[i] = document.getElementById("WTB" + i)?.value;
    });

    let impInfo = [];
    [...Array(importantInfo)].map((_, i) => {
      impInfo[i] = document.getElementById("importantInfo" + i)?.value;
    });
    const body = {
      id: Id,
      title,
      internalName,
      subtitle,
      briefDescription: desc,
      price: { amount: amount, currency: currencyId },
      category: categoryId,
      highlights: highlightsId,
      area: areaId,
      city: cityId,
      country: countryId,
      numberOfGuests: numberOfGuests,
      time,
      rules,
      importantInfo: impInfo,
      pickUp,
      dropOff,
      itinerary: arr,
      sale: {
        salePercent: sale.salePercent / 100,
        onSale: sale.onSale,
      },
      meals,
      cancelationPolicy: policyId,
      program: {
        event: { description: event.description, details: details },
        whatToBring: WTBRING,
      },
    };
    updateMutate(body);
  }

  // POST REQUEST
  function handleAddingTour() {
    let arr = [];
    [...Array(itineraryCounter)].map((_, i) => {
      arr[i] = {
        description: document.getElementById(`desc${i}`).value,
        lat: parseFloat(document.getElementById(`lat${i}`).value),
        long: parseFloat(document.getElementById(`long${i}`).value),
      };
    });

    let details = [];
    [...Array(detailsCount)].map((_, i) => {
      details[i] = document.getElementById("details" + i).value;
    });
    let WTBRING = [];
    [...Array(WTBCounter)].map((_, i) => {
      WTBRING[i] = document.getElementById("WTB" + i).value;
    });

    let impInfo = [];
    [...Array(importantInfo)].map((_, i) => {
      impInfo[i] = document.getElementById("importantInfo" + i)?.value;
    });

    const body = {
      title,
      internalName,
      subtitle,
      briefDescription: desc,
      price: { amount: amount, currency: currencyId },
      category: categoryId,
      highlights: highlightsId,
      area: areaId,
      city: cityId,
      country: countryId,
      numberOfGuests: numberOfGuests,
      time,
      rules,
      importantInfo: impInfo,
      pickUp,
      dropOff,
      itinerary: arr,
      // rateAccordingToWeekDays:{rate:weeklyRate,days:weekDays},
      sale: {
        salePercent: sale.salePercent / 100,
        onSale: sale.onSale,
      },
      cancelationPolicy: policyId,
      program: {
        event: { description: event.description, details: details },
        whatToBring: WTBRING,
      },
    };
    postMutate(body);
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          cursor={"pointer"}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Tour
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tour</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Details</Tab>
                <Tab>Program</Tab>
                <Tab>Location</Tab>
                {Edit && <Tab>Tour Dates</Tab>}
              </TabList>

              <TabPanels>
                {/* Details */}
                <TabPanel>
                  {/* Info */}
                  <HStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"15px"}
                  >
                    <Input
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <Input
                      value={internalName}
                      placeholder="InternalName"
                      onChange={(e) => setInternalName(e.target.value)}
                    />
                    <Input
                      value={subtitle}
                      placeholder="Subtitle"
                      onChange={(e) => setSubtitle(e.target.value)}
                    />
                  </HStack>
                  <Box mt={"5"}>
                    <Textarea
                      value={desc}
                      placeholder="briefDescription"
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </Box>
                  {/* Category */}
                  <Box>
                    <Text my={5}>Category</Text>
                    <Select
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      w={"20%"}
                    >
                      <option>Select Category</option>
                      {categories?.map((cat) => (
                        <option key={cat._id} value={cat._id}>
                          {cat.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  {/* Highlights */}
                  <Box>
                    <Text my={5}>Highlights:</Text>
                    <Flex flexWrap={"wrap"}>
                      {Edit
                        ? checkH &&
                          highlights?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                defaultChecked={checkH[idx]}
                                onChange={() => handleHighlights(h._id)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })
                        : highlights?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                onChange={() => handleHighlights(h._id)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })}
                    </Flex>
                  </Box>
                  {/* Time */}
                  <Box my="5" w="20%">
                    <InputGroup>
                      <InputLeftAddon children="Time:" />
                      <Input
                        type="text"
                        placeholder="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </InputGroup>
                  </Box>
                  {/* Number of Guests */}
                  <Box>
                    <Text my={5}>Number of guests:</Text>
                    <HStack spacing={5}>
                      <Box>
                        <Text my={2}>Adults:</Text>
                        <Input
                          type="number"
                          placeholder="Adults"
                          value={numberOfGuests.adults}
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              adults: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Children:</Text>
                        <Input
                          type="number"
                          placeholder="Children"
                          value={numberOfGuests.childrens}
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              childrens: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Infants:</Text>
                        <Input
                          type="number"
                          placeholder="Infants"
                          value={numberOfGuests.infants}
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              infants: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Minimum:</Text>
                        <Input
                          type="number"
                          placeholder="Persons"
                          value={numberOfGuests.minimum}
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              minimum: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Maximum:</Text>
                        <Input
                          type="number"
                          placeholder="Persons"
                          value={numberOfGuests.maximum}
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              maximum: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                    </HStack>
                  </Box>
                  {/* Price */}
                  <Box>
                    <Text my={5}>Pirce</Text>
                    <HStack mb={10} spacing={5}>
                      <Box>
                        <Input
                          type="number"
                          placeholder="Adults"
                          value={amount.adults}
                          onChange={(e) =>
                            setAmount({
                              ...amount,
                              adults: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Input
                          type="number"
                          placeholder="Children"
                          value={amount.childrens}
                          onChange={(e) =>
                            setAmount({
                              ...amount,
                              childrens: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Input
                          type="number"
                          placeholder="Infants"
                          value={amount.infants}
                          onChange={(e) =>
                            setAmount({
                              ...amount,
                              infants: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Select
                          value={currencyId}
                          onChange={(e) => setCurrencyId(e.target.value)}
                        >
                          <option>Select a Currency</option>
                          {currencies?.map((curr) => (
                            <option value={curr._id} key={curr._id}>
                              {curr.name}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    </HStack>
                  </Box>

                  {/* Cancelation Policy */}
                  <Box w="sm">
                    <Text my={5}>Cancelation Policy</Text>
                    <HStack
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"10px"}
                    >
                      <Select
                        w={"50%"}
                        value={policyId}
                        onChange={(e) => setPolicyId(e.target.value)}
                      >
                        <option>Select Policy</option>
                        {cancelations?.map((curr) => (
                          <option value={curr._id} key={curr._id}>
                            {curr.name}
                          </option>
                        ))}
                      </Select>
                    </HStack>
                  </Box>

                  <Flex>
                    {/* WeekDays Rate */}
                    {/* <Box my={5} mr={"5"}>
                <Text my={5}>Weekdays Rate</Text>
                <Input
                  value={weeklyRate}
                  onChange={(e) => setWeeklyRate(e.target.value)}
                  placeholder="Weekdays Rate"
                />

                <HStack spacing={"5"} mt={"5"} alignItems={"center"}>
      
                  <Checkbox id="day0" defaultChecked={weekDays?.find(id => id === "monday") ? true : false} value="monday">Monday</Checkbox>
                  <Checkbox id="day1" defaultChecked={weekDays?.find(id => id === "tuesday") ? true : false} value="tuesday">Tuesday</Checkbox>
                  <Checkbox id="day2" defaultChecked={weekDays?.find(id => id === "wednesday") ? true : false} value="wednesday">Wednesday</Checkbox>
                  <Checkbox id="day3" defaultChecked={weekDays?.find(id => id === "thursday") ? true : false} value="thursday">Thursday</Checkbox>
                  <Checkbox id="day4" defaultChecked={weekDays?.find(id => id === "friday") ? true : false} value="friday">Friday</Checkbox>
                  <Checkbox id="day5" defaultChecked={weekDays?.find(id => id === "saturday") ? true : false} value="saturday">Saturday</Checkbox>
                  <Checkbox id="day6" defaultChecked={weekDays?.find(id => id === "sunday") ? true : false} value="sunday">Sunday</Checkbox>
                </HStack>
              </Box> */}
                  </Flex>

                  <Box my={"5"}>
                    <HStack alignItems={"end"} spacing={10}>
                      {/* is It on Sale ? */}
                      <Box>
                        <Checkbox
                          defaultChecked={sale.onSale}
                          value={sale.onSale}
                          onChange={(e) =>
                            setSale({ ...sale, onSale: e.target.checked })
                          }
                        >
                          On Sale
                        </Checkbox>
                      </Box>
                      {/* Sale Percent */}
                      <Box>
                        <Text mb={"2"}>Sale percent</Text>
                        <Input
                          value={sale.salePercent}
                          pl={2}
                          type="number"
                          placeholder="Sale Percent:"
                          onChange={(e) =>
                            setSale({ ...sale, salePercent: e.target.value })
                          }
                        />
                      </Box>
                      {/* Number of Meals */}
                      <Box>
                        <Text mb={"2"}>Number Of Meals</Text>
                        <Input
                          value={meals}
                          pl={2}
                          type="number"
                          placeholder="Number Of Meals"
                          onChange={(e) => setMeals(e.target.value)}
                        />
                      </Box>
                    </HStack>
                  </Box>
                  {/* Program */}
                </TabPanel>
                {/* Program */}
                <TabPanel>
                  <Box>
                    <Text my={5}>Program:</Text>
                    <HStack spacing={"5"} alignItems={"start"}>
                      <Textarea
                        w={"25%"}
                        h={"120px"}
                        id={`desc`}
                        defaultValue={event.description}
                        placeholder="Description"
                        px={2}
                        colorScheme={"green"}
                        onChange={(e) =>
                          setEvent({ ...event, description: e.target.value })
                        }
                      />
                      <Text my={2}>Details:</Text>

                      <VStack spacing={"2"}>
                        {[...Array(detailsCount)].map((_, i) => (
                          <Box>
                            <HStack>
                              <Input
                                id={`details${i}`}
                                placeholder="Details"
                                defaultValue={tour?.program?.event?.details[i]}
                                px={2}
                                colorScheme={"green"}
                              />
                              <Flex>
                                <Button
                                  mr={2}
                                  colorScheme={"green"}
                                  onClick={() =>
                                    setDetailsCount((prev) => prev + 1)
                                  }
                                >
                                  <Icon as={BsPlusCircle} />
                                </Button>
                                <Button
                                  onClick={() =>
                                    setDetailsCount(detailsCount - 1)
                                  }
                                  display={detailsCount === 1 ? "none" : ""}
                                  colorScheme={"red"}
                                >
                                  <Icon as={AiOutlineDelete} />
                                </Button>
                              </Flex>
                            </HStack>
                          </Box>
                        ))}
                      </VStack>
                    </HStack>
                  </Box>
                  {/* What to Bring */}
                  <Box mb={10}>
                    <Text my={5}>What to Bring:</Text>
                    {[...Array(WTBCounter)].map((_, i) => (
                      <Box>
                        <HStack spacing={7} mt={2} alignItems={"center"}>
                          <Input
                            w={"25%"}
                            id={`WTB${i}`}
                            defaultValue={tour?.program?.whatToBring[i]}
                            placeholder="What you Should bring"
                            px={2}
                            colorScheme={"green"}
                          />
                          <Box>
                            <Button
                              mr={2}
                              colorScheme={"green"}
                              onClick={() => setWTBCounter((prev) => prev + 1)}
                            >
                              <Icon as={BsPlusCircle} />
                            </Button>
                            <Button
                              onClick={() => setWTBCounter(WTBCounter - 1)}
                              display={WTBCounter === 1 ? "none" : ""}
                              colorScheme={"red"}
                            >
                              <Icon as={AiOutlineDelete} />
                            </Button>
                          </Box>
                        </HStack>
                      </Box>
                    ))}
                  </Box>

                  {/* Importnt Info */}
                  <Box mb={10}>
                    {[...Array(importantInfo)].map((_, i) => (
                      <Flex key={`importantInfoKey${i}`} my={"5"} w="50%">
                        <InputGroup>
                          <InputLeftAddon children="Important Info:" />
                          <Input
                            type="text"
                            defaultValue={
                              tour?.importantInfo?.length > 0
                                ? tour?.importantInfo[i]
                                : ""
                            }
                            id={`importantInfo${i}`}
                          />
                        </InputGroup>
                        <Flex ml="5">
                          <Button
                            mr={2}
                            colorScheme={"green"}
                            onClick={() => setImportantInfo((prev) => prev + 1)}
                          >
                            <Icon as={BsPlusCircle} />
                          </Button>
                          <Button
                            onClick={() => setImportantInfo(importantInfo - 1)}
                            display={importantInfo === 1 ? "none" : ""}
                            colorScheme={"red"}
                          >
                            <Icon as={AiOutlineDelete} />
                          </Button>
                        </Flex>
                      </Flex>
                    ))}
                  </Box>
                  <InputGroup>
                    <InputLeftAddon children="Rules:" />
                    <Textarea
                      value={rules}
                      onChange={(e) => setRules(e.target.value)}
                    />
                  </InputGroup>
                </TabPanel>
                {/* location */}
                <TabPanel>
                  {/* Country and City */}
                  <Flex width={"50%"}>
                    {/* Country */}
                    <Box mr={10} width={"25%"}>
                      <Text my={5}>Country:</Text>
                      <Select
                        value={countryId}
                        onChange={(e) => setCountryId(e.target.value)}
                        colorScheme={"green"}
                      >
                        <option>Select a Country</option>
                        {countries?.map((b, i) => (
                          <option key={b._id} value={b._id}>
                            {b.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    {/* Cities */}
                    <Box
                      width={"25%"}
                      display={countryId?.length > 0 ? "" : "none"}
                    >
                      <Text my={5}>City:</Text>
                      <Select
                        value={cityId}
                        onChange={(e) => setCityId(e.target.value)}
                        colorScheme={"green"}
                      >
                        <option>Select a City</option>
                        {countryCities?.map((b) => (
                          <option key={b._id} value={b._id}>
                            {b.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Flex>
                  {/* Areas */}
                  <Box>
                    <Text my={5}>Area:</Text>
                    <Select
                      value={areaId}
                      onChange={(e) => setAreaId(e.target.value)}
                      w={"20%"}
                    >
                      <option>Select Area</option>
                      {areas?.map((area) => (
                        <option key={area._id} value={area._id}>
                          {area.name}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  {/* Pick Up */}
                  <Box>
                    <Text my={5}>Pick Up:</Text>
                    <HStack spacing={5}>
                      <Box>
                        <Text my={2}>location Name:</Text>
                        <Input
                          type="text"
                          value={pickUp.locationName}
                          placeholder="Location Name"
                          onChange={(e) =>
                            setPickUp({
                              ...pickUp,
                              locationName: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Lat:</Text>
                        <Input
                          type="text"
                          value={pickUp.lat}
                          placeholder="Lat"
                          onChange={(e) =>
                            setPickUp({
                              ...pickUp,
                              lat: parseFloat(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Long:</Text>
                        <Input
                          type="text"
                          placeholder="Long"
                          value={pickUp.long}
                          onChange={(e) =>
                            setPickUp({
                              ...pickUp,
                              long: parseFloat(e.target.value),
                            })
                          }
                        />
                      </Box>
                    </HStack>
                  </Box>
                  {/* dropOff */}
                  <Box>
                    <Text my={5}>Drop Of:</Text>
                    <HStack spacing={5}>
                      <Box>
                        <Text my={2}>location Name:</Text>
                        <Input
                          type="text"
                          placeholder="Location Name"
                          value={dropOff.locationName}
                          onChange={(e) =>
                            setDropOff({
                              ...dropOff,
                              locationName: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Lat:</Text>
                        <Input
                          type="text"
                          placeholder="Lat"
                          value={dropOff.lat}
                          onChange={(e) =>
                            setDropOff({
                              ...dropOff,
                              lat: parseFloat(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Long:</Text>
                        <Input
                          type="text"
                          placeholder="Long"
                          value={dropOff.long}
                          onChange={(e) =>
                            setDropOff({
                              ...dropOff,
                              long: parseFloat(e.target.value),
                            })
                          }
                        />
                      </Box>
                    </HStack>
                  </Box>
                  {/* Itinerary */}
                  <Box>
                    {[...Array(itineraryCounter)].map((_, i) => (
                      <Box key={"Itinerary" + i}>
                        <Text my={5}>Itinerary:</Text>
                        <HStack spacing={7} mt={5} alignItems={"center"}>
                          <Input
                            w={"25%"}
                            id={`desc${i}`}
                            defaultValue={tour?.itinerary[i]?.description}
                            placeholder="Description"
                            px={2}
                            colorScheme={"green"}
                          />
                          <Input
                            w={"25%"}
                            id={`lat${i}`}
                            defaultValue={tour?.itinerary[i]?.lat}
                            placeholder="lat"
                            px={2}
                            colorScheme={"green"}
                          />
                          <Input
                            w={"25%"}
                            id={`long${i}`}
                            placeholder="Long"
                            defaultValue={tour?.itinerary[i]?.long}
                            px={2}
                            colorScheme={"green"}
                          />
                          <Box>
                            <Button
                              mr={2}
                              colorScheme={"green"}
                              onClick={() =>
                                setItineraryCounter((prev) => prev + 1)
                              }
                            >
                              <Icon as={BsPlusCircle} />
                            </Button>
                            <Button
                              onClick={() =>
                                setItineraryCounter(itineraryCounter - 1)
                              }
                              display={itineraryCounter === 1 ? "none" : ""}
                              colorScheme={"red"}
                            >
                              <Icon as={AiOutlineDelete} />
                            </Button>
                          </Box>
                        </HStack>
                      </Box>
                    ))}
                  </Box>
                </TabPanel>
                {Edit && (
                  <TabPanel>
                    <Box>
                      {tour?.tourDates?.map((c) => (
                        <Box
                          border="1px"
                          my={5}
                          rounded={"lg"}
                          py="5"
                          px="2"
                          w="50%"
                        >
                          <Flex justifyContent="space-between">
                            <Box>
                              <Text>
                                Day: {" " + dayjs(c.day).format("DD-MM-YYYY")}
                              </Text>
                              <Text>Departure Time : {c.departureTime}</Text>
                              <Text>Return Time : {c.returnTime}</Text>
                              <Text>
                                {c.soldOut
                                  ? "No More Seets"
                                  : "Seets Are still available"}
                              </Text>
                              <Text>Seet Remaining: </Text>
                              <Text>{`Adults:${c.numberOfGuests.adults} - Children:${c.numberOfGuests.childrens} - Infants:${c.numberOfGuests.infants}`}</Text>
                              <Text>Price:</Text>
                              <Text>{`Adults:${c.price.amount.adults} - Children:${c.price.amount.childrens} - Infants:${c.price.amount.infants}`}</Text>
                              <Text></Text>
                              <Text>
                                {c.soldOut
                                  ? "No More Seets"
                                  : "Seets Are still available"}
                              </Text>
                            </Box>
                            <Box>
                              <EditTourDate tourDetails={c} />
                              <IconButton
                                size="md"
                                onClick={() => handleDelete(c._id)}
                                icon={<FiTrash />}
                              />
                            </Box>
                          </Flex>
                        </Box>
                      ))}
                    </Box>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={() => handleUpdatingTour()}
                bg={"green"}
                textColor={"white"}
              >
                {Edit ? "Update" : "Add"}
                <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() => handleAddingTour()}
                bg={"green"}
                textColor={"white"}
              >
                {Edit ? "Update" : "Add"}
                <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddTour;
