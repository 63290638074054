import { Box, Flex, Text } from "@chakra-ui/react"
import { useQuery } from "react-query";
import * as api from '../components/API/reportsApi'
import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DatePicker from "react-multi-date-picker";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Revenue',
      },

    },
    
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Booking',
      },
    },
  };
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Occupacy Rate',
      },
    },
     scales: 
        { y:{ ticks: { beginAtZero: true, callback: function(value) 
            { return `%${value}`; } }, 
        }}
  };

  
  
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];
  const Tlabels = [1, 2, 3, 4, 5, 6, 7,8, 9, 10, 11, 12];

function Dashboard() {
    let j= 0; let x = 0; let i = 0; let y =0;
    const { data , refetch } = useQuery("MonthlyRevenu",() => api.getMonthlyRevenu(yearRef.current,""),{
        // enabled:Boolean(selectedYear),
    });
    const { data : booking , refetch : bookingRefetch } = useQuery("TotalBooking",() => api.getMonthlyBooking(yearRef.current,""),{
        // enabled:Boolean(selectedYear),
    });

    const { data : occupacy , refetch : occupacyRefetch } = useQuery("Occupacy",() => api.getOccupancyRate(yearRef.current,""),{
        // enabled:Boolean(selectedYear),
    });


    
const yearRef = useRef("2022")
    function handleChange(date){
        yearRef.current = date.format("YYYY")
        refetch()
        bookingRefetch()
        occupacyRefetch()
    }
    
   
      
     const Chartdata = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: Tlabels?.map((d,i) => {
                if(data && d === data[i]?.month){
                return(
                    data[i]?.totalRevenue
                )
            }else{
                return 0;
            }
            }),
            backgroundColor: '#FED438',
          },
          
          
        ],  
      };
      const Chartdata2 = {
        labels,
        datasets: [
          {
            label: 'Dataset 2',
            data: Tlabels?.map((d,i) => {
                if(booking && (d === booking[j]?.month)){
                    x = j;
                    j++;
                return(
                    booking[x]?.totalBookings
                    
                )
                    
            }else{
                return 0;
            }
            }),
            backgroundColor: '#FED438',
          },
          
        ],  
      };
      const Chartdata3 = {
        labels,
        datasets: [
          {
            label: 'Dataset 3',
            data: Tlabels?.map((d,idx) => {
                if(occupacy && (d === occupacy[i]?.month)){
                    y = i;
                    i++;
                return(
                        // <p>{"%"+occupacy[y]?.occupancyRate}</p>
                        occupacy[y]?.occupancyRate
                )
                    
            }else{
                return 0;
            }
            }),
            backgroundColor: '#FED438',
          },
          
        ],  
      };
    return (
        <Box  ml={10} my={{base:"20"}} minW={{base:"75vw"}} maxW={{base:"80vw"}}>
          {data && 
          <Box> 
          <Flex>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options} data={Chartdata} /> 
        </Box>   
        }
           {booking && 
           <Box>
            <Flex mt={10}>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options2} data={Chartdata2} />
        </Box>
        }

        {occupacy && 
           <Box>
            <Flex mt={10}>
               <Text mr={3}>Select Year</Text>
                <DatePicker 
                 onlyYearPicker 
                value={yearRef.current}
                onChange={(date) => handleChange(date)}
                placeholder={yearRef.current}
                />
            </Flex>
           <Bar options={options3} data={Chartdata3} />
        </Box>
        }
           
        </Box>
    )
}

export default Dashboard




