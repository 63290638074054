import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,

} from "@chakra-ui/react";
import { useState } from "react";
import * as api from '../components/API/contactApi'

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useEffect } from "react";


function ContactUs() {
  const toast = useToast()
  const queryClient = useQueryClient();
  const { data } = useQuery("Details", api.getDetails);
  const [email, setEmail] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [whatsapp, setWhatsapp] = useState()
  const [location, setLocation] = useState({ long: "", lat: "", label: "" })
  const [action,setAction] = useState("post")
  const { mutate: postMutate } = useMutation([], api.addDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Details"]);
      toast({
        title: 'Contact Details Updated.',
        description: "Updated successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  
  const { mutate: updateMutate } = useMutation([], api.editDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Details"]);
      toast({
        title: 'Contact Details Updated.',
        description: "Updated successfully.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteDetails, {
    onSuccess:
      () => {
        queryClient.invalidateQueries(["Details"]);
      },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  useEffect(() => {
    if (data) {
      setAction("update")
      setEmail(data.email)
      setPhoneNumber(data.phoneNumber)
      setWhatsapp(data.whatsapp)
      setLocation({ long: data.location.long, lat: data.location.lat, label: data.location.label })
    }
  }, [data])

  function handleUpdate() {
    const body = {
      email,
      phoneNumber,
      whatsapp,
      location,
    }
    if(action === "update"){

      updateMutate(body)
    }else{
      postMutate(body)
    }
  }

  return (
    <Box mt={"7%"} mx={"4%"} w="75vw">
      <InputGroup my={"10"}>
        <InputLeftAddon children="Email:" />
        <Input type="text" w="51%" value={email} onChange={e => setEmail(e.target.value)} />
      </InputGroup>
      <InputGroup my={"10"}>
        <InputLeftAddon children="Phone:" />
        <Input type="text" w="51%" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
      </InputGroup>
      <InputGroup my={"10"}>
        <InputLeftAddon children="Whatsapp:" />
        <Input type="text" w="51%" value={whatsapp} onChange={e => setWhatsapp(e.target.value)} />
      </InputGroup>
      <HStack spacing={"5"} my={10} alignItems="center">
        <InputGroup>
          <InputLeftAddon children="Long:" />
          <Input type="text" w="51%" value={location.long} onChange={e => setLocation({ ...location, long: e.target.value })} />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Lat:" />
          <Input type="text" w="51%" value={location.lat} onChange={e => setLocation({ ...location, lat: e.target.value })} />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Label:" />
          <Input type="text" w="51%" value={location.label} onChange={e => setLocation({ ...location, label: e.target.value })} />
        </InputGroup>
      </HStack>
      <Flex justifyContent={"end"}>
        <Button mr={"5"} mt={14} colorScheme="green" onClick={() => handleUpdate()}>Save</Button>
      </Flex>
    </Box>
  )
}

export default ContactUs;
