import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
  baseURL: `${baseURL}/v1/blogs/`,
  headers: { 'authorization': Cookies.get('admin-token') }
})

export const getBlogs = async () => await api.get('').then(res => res.data.blogs)


export const getBlogById = (id) => {
  return api.get(`${id}`).then((res) => res.data.blog)
}

export const updateBlog = async (body) => {
  return api.put(`${body.get('id')}`, body).then((res) => res.data)
}

export const updateImageBlog = async (body) => {
  return api.put(`image/${body.get('id')}`, body).then((res) => res.data)
}

export const deleteImageBlog = async (body) => {
  return api.delete(`${body.id}/image/${body.imageId}/`).then((res) => res.data)
}

export const addBlog = (blog) => {
  return api.post('', blog).then((res) => res.data)
}

export const deleteBlog = (id) => {
  return api.delete(`/${id}`).then((res) => res.data)
}