import {
  Box,
  Button,
  Flex,
  Input,
  useToast,
  Text,
} from "@chakra-ui/react";
import {  useRef, useState } from "react";
import * as api from '../components/API/storyApi'
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';
import AddMission from "../components/UI/AddMission";

function Missions() {
  const queryClient = useQueryClient();
  const [mission,setMission] = useState()
  const [action,setAction] = useState("post") 
const [image,setImage] = useState()
const [newImage,setNewIamge] = useState()
const toast = useToast()
const editorRef = useRef(null);
  const { data } = useQuery("Missions", api.getStory);
  const missionImage = useRef("")
  const { mutate: postMutate } = useMutation([], api.addStory, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stories"]);
      toast({
        title: 'Story created.',
        description: "Added successfully.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  const { mutate: updateMutate } = useMutation([], api.editStory, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stories"]);
      toast({
        title: 'Story created.',
        description: "Added successfully.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });



  

  function handleChange(e) {
    setImage(e.target.files[0])
    setNewIamge(URL.createObjectURL(e.target.files[0]))
  }
  function handleUpdate(){
    let mission = editorRef.current.getContent()
    const data = new FormData()
        data.append('story',mission)
        if(image){
        data.append('image',image)
      }
      if(action === "update"){

        updateMutate(data)
      }else{
        postMutate(data)
      }
  }

  useEffect(() => {
    
    
    if(data) {
      setMission(data.story)
      setAction("update")
      // setImage(data.image)
    }
},[data,newImage])




  return (
  <Box mt={"7%"} mx={"4%"} w="75vw">
     <Text mb={"30px"} fontWeight={"bold"} fontSize={"30px"}>Story</Text>
        <Editor
       onInit={(evt, editor) => editorRef.current = editor}
       initialValue={data?.story ? data.story : ''}
       init={{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat | help',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
       }}
     />
     
      <Flex justifyContent={"space-between"}>
      {/* <Image cursor={"pointer"} onClick={() => document.getElementById("image").click()} src={newImage ? newImage : data?.image} w={"50%"} h="450px"  /> */}
      <Input href={missionImage} display={"none"} type="file" onChange={(e) => handleChange(e) } id="image" />
      </Flex>
      <Flex my={"5"} alignItems={"center"}>
      <Button mr={"5"}  onClick={() => handleUpdate() }>Save</Button>
      <Button mr={"5"} onClick={() => document.getElementById("image").click() }>Upload Image</Button>
       <AddMission image={data?.image} /> 
      </Flex>
  </Box>
        // <RTable columns={productsColumns} data={data} />
  ) 
}

export default Missions;
