import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/countries`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getCountries = async() =>  api.get('admin/all').then((res) => res.data.countries)

export const addCountry = (body) => {
  return api.post(''+body.name).then((res) => res.data)
}
export const getAllCountriesInTheWorld = () => api.get('/world/countries').then(res => res.data.countries)

export const getCountryById = async(id) =>  api.get('/'+id).then((res) => res.data.country)

export const updateCountry = (body) => {
  return api.put('/'+body.id,body).then((res) => res.data)
}

export const getStay = (id) => {
  return  api.get(`admin/${id}`)
}

export const updateStay = (body) => {
  return  api.put(`admin/${body.get('id')}`, body).then((res) => res.data)
} 
export const updateStayImage = (body) => {
  return  api.put(`admin/image/${body.get('id')}`, body).then((res) => res.data)
} 
export const addStay = (stay) => {
    return api.post('admin',stay).then((res) => res.data)
}

export const deleteStay = (id) => {
    return api.delete(`admin/${id}`).then((res) => res.data)
}