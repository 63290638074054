import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Text,
  Image,
  Flex,
  Stack,
  Box,
  InputLeftAddon,
  InputGroup,
  Input,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import { baseURL } from "../config";
function BookingDetails({ event, onOpen, isOpen, onClose }) {
  const firstField = useRef();
  const [editMode, setEditMode] = useState(false);
  const [blockedDates, setBlockedDates] = useState();
  const [notes, setNotes] = useState(event?.notes);
  const dateA = new Date(event?.endDate);
  const dateB = new Date(event?.startDate);
  const diffTime = Math.abs(dateA - dateB);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const token = Cookies.get("admin-token");
  function handleCancel() {
    axios
      .delete(
        `${baseURL}/v1/calendardata/stay/blockedDates/` + event._id,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() => onClose());
  }
  function handleEdit() {
    const data = {
      blockedDates,
      notes,
    };
    axios
      .put(
        `${baseURL}/v1/calendardata/stay/blockedDates/` + event._id,data,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() => onClose());
  }
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {event?.notes ? "Blocked date" : "Booking Details"}
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px" mt={"10"}>
              {!event?.notes ? (
                <Flex justifyContent={"space-between"}>
                  <Box>
                    <Text fontSize={"12px"} fontWeight={"400"}>
                      Arriving{" "}
                      {event?.arrivingIn < 1
                        ? event?.arrivingIn * -1 + " days later"
                        : "Done"}{" "}
                    </Text>
                    <Text fontSize={"18px"} fontWeight={"bold"}>
                      {event?.title}
                    </Text>
                    <Text fontSize={"12px"} fontWeight={"400"}>
                      {event?.name + " - " + event?.area}
                    </Text>
                    <Text fontSize={"12px"} fontWeight={"400"}>
                      {(event?.guests > 1
                        ? event?.guests + " Guests "
                        : event?.guests + " Guest ") + event?.price}
                    </Text>
                    <Text fontSize={"12px"} fontWeight={"400"}>
                      {dayjs(event?.startDate).format("MMM DD") +
                        "-" +
                        dayjs(event?.endDate).format("MMM DD") +
                        " " +
                        diffDays +
                        "(Nights) "}
                    </Text>
                  </Box>
                  <Image
                    src={event?.image}
                    w={"14"}
                    h={"14"}
                    rounded={"full"}
                  />
                </Flex>
              ) : (
                <Flex justifyContent={"space-between"}>
                  <Box>
                    <Text fontSize={"12px"} fontWeight={"400"}>
                      {"Blocked From:" +
                        dayjs(event.startDate).format("MM-DD-YYYY") +
                        " Until " +
                        dayjs(event.endDate).format("MM-DD-YYYY")}
                    </Text>
                  </Box>
                </Flex>
              )}
              {editMode && (
                <VStack w={"full"}>
                  <Text
                    fontSize={"20px"}
                    align={"center"}
                    textDecoration={"underline"}
                    mb={"4"}
                  >
                    Edit Area
                  </Text>
                  <InputGroup>
                    <InputLeftAddon children="Dates:" />
                    <DatePicker
                      value={blockedDates}
                      onChange={setBlockedDates}
                      multiple
                      format="DD/MM/YYYY"
                      style={{
                        padding: "18px 10px",
                        width: "25.5vw",
                        zIndex:"999",
                        overflow: "hidden",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                      plugins={[
                        <DatePanel Toolbar markFocused />,
                        <Toolbar
                          position="bottom"
                          names={{
                            today: "Select Today",
                            deselect: "Reset",
                            close: "Close",
                          }}
                        />,
                      ]}
                      // render={<InputIcon  />}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children="Notes:" />
                    <Input
                      type="text"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </InputGroup>
                  <Button onClick={() => handleEdit()}>Submit</Button>
                </VStack>
              )}
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            {event?.notes?.length > 0 && (
              <>
                <Button onClick={() => handleCancel()} colorScheme="red" mr={3}>
                  Remove
                </Button>

                <Button onClick={() => setEditMode(true)} colorScheme="blue">
                  Edit
                </Button>
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default BookingDetails;
