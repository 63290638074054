import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/rate/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getRates = async() =>  await api.get('').then(res => res.data.rates)

export const getRateById = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.rate)
}

export const getDefaultRate = () => {
    return  api.get(`/website/default`).then((res) => res.data.rate)
  }

export const updateRate = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const addRate = (body) => {
    return api.post('',body).then((res) => res.data)
}

export const deleteRate = (id) => {
    return api.delete(`${id}`).then((res) => res.data)
}
