import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/currencyApi";

import { useEffect, useState } from "react";

function AddCurrency({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState()
  const [symbol,setSymbol] = useState()
  const [isDefault,setIsDefault] = useState(false)
 

  const queryClient = useQueryClient();



  const { data : Currency } = useQuery(
    ['currency', id],
    () => api.getCurrencyById(id).finally(() => {
    }),
    {   
        
        enabled: Boolean(id),
    }
  )

//UPDATE
const { mutate: updateMutate } = useMutation([], api.updateCurrency, {
    onSuccess: () => {
      queryClient.invalidateQueries(["AllCurrencies"]);
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

// POST
const { mutate : postMutate  } = useMutation([],api.addCurrency, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["AllCurrencies"]);
           onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

    async function handlePost() {
        const data = {
        id:Id,
       name,
       symbol,
       isWebSiteDefault:isDefault,
      };
      if(Edit){
          updateMutate(data)
    }
    else{
        postMutate(data)
    }
    
    }

    function handleEdit(){
        onOpen()
        setId(Id)
      }
   
      useEffect(() => {
            if(Edit && Currency){
                setName(Currency.name)
                setSymbol(Currency.symbol)
                setIsDefault(Currency.isWebSiteDefault)
            }
      },[Currency])

  return (
    <>
        {Edit === true ? 
        <Icon onClick={() => handleEdit()} as={FiEdit} cursor={"pointer"} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Currency
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
}
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Currency' : 'Add Currency'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack 
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              <Input placeholder="Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />
              <Checkbox  defaultChecked={isDefault} value={isDefault} onChange={(e) => setIsDefault(e.target.value)}>
                  isDefault
                  </Checkbox>
              
              
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>{Edit ? 'Update' : 'Add'}<Icon ml={2} as={PlusSquareIcon} /></Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
}
export default AddCurrency;
