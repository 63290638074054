import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/guest/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getGuests = async() =>  await api.get('').then(res => res.data.users)


export const getGuest = async(id) => {
  return  api.get(`/${id}`).then((res) => res.data)
}

export const addGuest = async(body) => {
    return api.post('',body).then((res) => res.data)
}

export const searchGuest = async(body) => {
  return api.post('all/dropdown',body).then((res) => res.data.users)
}

export const updateGuest = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 

export const deleteGuest = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}