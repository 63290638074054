import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function SidebarSubElement({link,title,setShowMore,line}) {
  const location = useLocation()
    let urlName = window.location.pathname;
    useEffect(() =>{
       if(urlName === '/'+link){
        
        setShowMore({line:line ,status:true})
       }
    },[link,urlName,location,line])
    
  return (
      <Link to={'/'+link}>
        <Flex
          textColor={urlName === '/'+link ? "black" : "white"}
          alignItems={"center"}
          bg={urlName === '/'+link ? 'yellow.200' : ''}
          px={"20px"}
          py={2}
          mt={2}
          rounded={"lg"}
          justifyContent={"space-between"}
        >
          <Text fontSize={"13"} fontWeight={"semibold"}>
            {title}
          </Text>
          <Icon as={AiOutlineDashboard} w={5} h={5} color={urlName === '/'+link ? "black" : "white"} />
        </Flex>
      </Link>
  );
}

export default SidebarSubElement;
