import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  VStack,
  useToast,
  Box,
  HStack,
  Text,
  Flex
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/careerApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
function AddCareer({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [desc, setDesc] = useState("");
  const [position, setPosition] = useState("");
  const [team,setTeam] = useState()
  const [profile,setProfile] = useState()
  const [duties, setDuties] = useState(1);
  const [urlName,setUrlName] = useState()
  const [id, setId] = useState();
  const queryClient = useQueryClient();
  
  //   Get Term Data for Edit

  const { data: Career } = useQuery(
    ["Career", id],
    () => api.getCareerById(id).finally(() => {}),
    {
      enabled: Boolean(id)
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.editCareer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Careers"]);
      onClose();
    }
  });

  //POST
  const { mutate: postMutate } = useMutation([], api.addCareer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Careers"]);
      onClose();
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  useEffect(() => {
    if (Career) {
      setDesc(Career.description);
      setPosition(Career.position);
      setTeam(Career.team)
      setDuties(Career?.duties.length > 0 ? Career?.duties?.length : 1);
      setProfile(Career.profile)
    }
  }, [Career, Id]);

  function handleUpdate() {
    let arr  =[];
    [...Array(duties)].map((_, i) => {
      arr[i] = document.getElementById(`duties${i}`).value
      
    })
    const data = {
      id: Id,
      position: position,
      description: desc,
      duties:arr,
      profile,
      team,
      urlName,
    };
    updateMutate(data);
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  function handleClose() {
    onClose();
  }

  async function handlePost() {
    let arr  =[];
    [...Array(duties)].map((_, i) => {
      arr[i] = document.getElementById(`duties${i}`).value
      
    })
    const data = {
      position: position,
      description: desc,
      duties:arr,
      profile,
      team,
      urlName,

    };
    postMutate(data);
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          cursor={"pointer"}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={'#FED438'}>
          Add Career
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit Career" : "Add Career"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10"}
            >
              <InputGroup>
                <InputLeftAddon children="Team:" />
                <Input
                  placeholder="Team"
                  value={team}
                  onChange={(e) => setTeam(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon children="Description:" />
              <Textarea
                placeholder="Description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              </InputGroup>
              
              <InputGroup>
                <InputLeftAddon  children="Profile:" />
                <Textarea
                  placeholder="Profile"
                  value={profile}
                  onChange={(e) => setProfile(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon children="Position:" />
                <Textarea
                  placeholder="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </InputGroup>
              <Box w={"50%"}>
              <Text my={5}>Duties:</Text>
            {[...Array(duties)].map((_, i) =>
            (
                <HStack spacing={7} mt={5} w={"full"} alignItems={"center"}>
                  <InputGroup>
                  <InputLeftAddon children="Duties:" />
                  <Input  id={`duties${i}`} defaultValue={Career?.duties[i]} placeholder="Duties" w={"full"}  px={2}  />
                  </InputGroup>
                  
                  <Flex>
                  <Button  mr={2} colorScheme={"green"} onClick={() => setDuties(prev => prev + 1)}>
                      <Icon as={BsPlusCircle} />
                    </Button>
                    <Button  onClick={() => setDuties(duties - 1)} display={duties === 1 ? "none" : ""} colorScheme={"red"}>
                        <Icon as={AiOutlineDelete} />
                    </Button>
                  </Flex>
                </HStack>
            ))}
            </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={() => handleUpdate()}
                bg={"green"}
                textColor={"white"}
              >
                Update <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() => handlePost()}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddCareer;
