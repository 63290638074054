import {  Button,InputGroup,InputLeftAddon,Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useQuery, useToast } from '@chakra-ui/react';
import axios from 'axios';

import Cookies from 'js-cookie';
import React,{useState} from 'react';
import { useEffect } from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import { useMutation } from 'react-query';
import * as api from '../API/calendarApi'
import { baseURL } from '../config';
function AddBlockDates({event,onOpen,isOpen,onClose,setReloadData}) {
 
  const [id,setId] = useState()
  const toast = useToast()
  const [blockedDates,setBlockedDates] = useState()
  const [note,setNote] = useState() 
  const [dateHolder,setDateHolder] = useState()
  // GET BY ID



  function getBlockDates() {
    axios.get(`${baseURL}/v1/calendardata/stay/blockedDates?stay=${event}`,{
      headers:{
        'authorization':Cookies.get('admin-token')
      }
    }).then(data => {
        setBlockedDates(data.data?.blockedDates[0]?.blockedDates)
        setNote(data.data?.blockedDates[0]?.notes)
    })
  }

  useEffect(() => {
    if(isOpen){
      getBlockDates()
    }
  },[isOpen])
  
//POST
const { mutate : postMutate  } = useMutation([],api.addBlockedDates, {
  onSuccess:
    () => {
         onClose();
         setReloadData(true);
  },
  onError:(error) => {
    toast({
      title: error.response.data.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
})

  function handleSubmit() {
    const dates = []
    blockedDates.map((d,i) => (
      dates[i] = d.format() +"T00:00:00.000Z"
    ) )
    const data = {
      propertyId:event,
      blockedDates:dates,
      notes:note
    }
    postMutate(data)
  }
  return (
    <>
        
      
    <Modal size={"md"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{ 'Blocking Details'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack 
            alignItems={"start"}
            justifyContent={"start"}
            spacing={"15px"}
          > 
          <InputGroup>
          <InputLeftAddon children="Dates:" />
              <DatePicker
                                value={blockedDates}
                                onChange={setBlockedDates}
                                multiple
                                format="YYYY-MM-DD"
                                style={{
                                  padding: "18px 10px",
                                  width: "20vw",
                                  overflow: "hidden",
                                  borderTopLeftRadius: "0",
                                  borderBottomLeftRadius: "0"
                                }}
                                plugins={[
                                  <DatePanel Toolbar markFocused />,
                                  <Toolbar
                                    position="bottom"
                                    names={{
                                      today: "Select Today",
                                      deselect: "Reset",
                                      close: "Close"
                                    }}
                                  />
                                ]}
                                // render={<InputIcon  />}
                              />
</InputGroup>
<InputGroup>
<InputLeftAddon children="note" />
<Input type="text" placeholder="Notes" onChange={e => setNote(e.target.value)} value={note} />
  </InputGroup>
          </VStack>
     
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="green" mr={3} onClick={() => handleSubmit()}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
  )
}

export default AddBlockDates;
