import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/`,
    headers: {Authorization:Cookies.get('admin-token')}
})

// STAYS

export const getStaysCategories = async() =>  api.get('propertycategories').then((res) => res.data.categories)


export const getStayCategoryById = async(id) => api.get('propertycategories/'+id).then((res) => res.data.category)

export const updateStayCategoryById = async(body) => {
 return  api.put('propertycategories/'+body.id,body).then((res) => res.data.category)
}

export const ToggleStayCategory = (body) => {
  return api.put('propertycategories/'+body.id,body).then((res) => res.data)
}

export const deleteStayCategory = (id) => {
  return  api.delete(`propertycategories/${id}`)
}

export const addStayCategory = (body) => {
  return api.post('propertycategories',body).then((res) => res.data)
}

// PRODUCTS

export const getProductsCategories = async() =>  api.get('productcategories').then((res) => res.data.categories)

export const getProductCategoryById = async(id) => api.get('productcategories/'+id).then((res) => res.data.category)

export const updateProductCategoryById = async(body) => {
 return  api.put('productcategories/'+body.id,body).then((res) => res.data.category)
}


export const ToggleProductCategory = (body) => {
  return api.put('productcategories/'+body.id,body).then((res) => res.data)
}

export const deleteProductCategory = (id) => {
  return  api.delete(`productcategories/${id}`)
}

export const addProductCategory = (data) => {
  return api.post('productcategories',data).then((res) => res.data)
}

// TOURS 

export const getToursCategories = async() =>  api.get('tourcategories/all/admin').then((res) => res.data.categories)

export const getTourCategoryById = async(id) => api.get('tourcategories/'+id).then((res) => res.data.category)

export const updateTourCategoryById = async(body) => {
  return  api.put('tourcategories/'+body.get('id'),body).then((res) => res.data.category)
 }

export const addTourCategory = (data) => {
  return api.post('tourcategories',data).then((res) => res.data)
}
export const deleteTourCategory = (id) => {
  return  api.delete(`tourcategories/${id}`)
}
export const ToggleTourCategory = (data) => {
  return api.put('tourcategories/'+data.id,data).then((res) => res.data)
}




export const getStay = (id) => {
  return  api.get(`admin/${id}`)
}

export const updateStay = (updatedEmp) => {
  return  api.put(`admin/${updatedEmp.get('id')}`, updatedEmp).then((res) => res.data)
} 
export const updateStayImage = (updatedEmp) => {
  return  api.put(`admin/image/${updatedEmp.get('id')}`, updatedEmp).then((res) => res.data)
} 


export const deleteStay = (id) => {
    return api.delete(`admin/${id}`).then((res) => res.data)
}