import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/currencies/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getCurrencies = async() =>  api.get('admin/view/currency').then((res) => res.data.currencies)


export const getCurrencyById = (id) => {
  return  api.get(`/${id}`).then((res) => res.data.currency)
}

export const updateCurrency = (body) => {
  return  api.put(`/${body.id}`, body).then((res) => res.data)
} 
export const updateCurrencyImage = (body) => {
  return  api.put(`/image/${body.get('id')}`, body).then((res) => res.data)
} 
export const addCurrency = (stay) => {
    return api.post('',stay).then((res) => res.data)
}

export const deleteCurrency = (id) => {
    return api.delete(`/${id}`).then((res) => res.data)
}