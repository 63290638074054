import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as supApi from "../API/suppliersApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddSupplier({Edit,Id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [id,setId] = useState()
  const [name,setName] = useState()
  const [desc,setDesc] = useState()
//   const [image,setImage] = useState()
  const queryClient = useQueryClient();
//POST
const { mutate : postMutate  } = useMutation([],supApi.addSupplier, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Suppliers"]);
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
            onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })
  // UPDATE
  const { mutate: updateMutate } = useMutation([], supApi.updateSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Suppliers"]);
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }) 
      onClose()
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });
  // GET BY ID
  const { data : supp } = useQuery(
    ['supplier', id],
    () => supApi.getSupplierById(id).finally(() => {
    }),
    {
        enabled: Boolean(id),
    }
    )

   
    function handleEdit(){
    
      onOpen()
      setId(Id)
    }
    

    useEffect(() => {
      if(supp){
        setName(supp.name)
        setDesc(supp.description)
      }
    },[Id,supp])
  
    function handleUpdate(){
    const data ={ 
        id,
        name,
        description:desc
    }
      
      updateMutate(data)
    }

    async function handlePost() {
        const data ={ 
            name,
            description:desc
        }
        postMutate(data)
    }


  return (
<>
        {Edit === true ? 
        <Icon cursor={"pointer"} onClick={() => handleEdit()} as={FiEdit} ml={4} size={6} fontSize={"bold"} />
        :
      <Button onClick={onOpen} bg={'#FED438'}>
        Add Supplier
        <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
      </Button>
  }
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? 'Edit Supplier' :'Add Supplier'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"10px"}
            >
              <InputGroup>
              <InputLeftAddon children="Name:" />
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
              <InputGroup>
              <InputLeftAddon children="Description:" />
              <Input type="text" pt="1" placeholder="Name" value={desc} onChange={(e) => setDesc(e.target.value)} />
              </InputGroup>
              
            </VStack>
        
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? 
            <Button variant="outline" onClick={(e) => handleUpdate(e)} bg={"green"} textColor={"white"}>Update <Icon ml={2} as={PlusSquareIcon} /></Button>
            :
            <Button variant="outline" onClick={(e) => handlePost(e)} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>
          }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddSupplier;
