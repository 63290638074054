import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";
import { BsImage } from "react-icons/bs";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../Test/App.css";
import * as api from "../API/mediaApi";
import { useMutation } from "react-query";
import { FiMinus } from "react-icons/fi";
import LazyImage from "./LazyLoadImage";

function AddImages({ type, Id, oldImages }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [oldies, setOldies] = useState(oldImages);
  const toast = useToast();
  const [selectedImages, setSelectedImages] = useState([]); // Track selected images
  const [mode, setMode] = useState("resort");
  // Add Images
  const { mutate: postMutate } = useMutation([], api.addImage, {
    onSuccess: (response) => {
      setOldies(response.images);
      setImages([]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // resort Images
  const { mutate: resortMutate } = useMutation([], api.resortImage, {
    onSuccess: (response) => {
      setOldies(response.images);
      toast({
        title: "Images resorted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteImages, {
    onSuccess: (response) => {
      setOldies(response.images);
      setSelectedImages([]);
      setMode("resort");
      toast({
        title: "Images removed successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const [images, setImages] = useState([]);
  // Memoize image URLs to prevent unnecessary recalculations
  const imagesUrl = useMemo(
    () => images.map((image) => URL.createObjectURL(image)),
    [images]
  );

  const onSortOldies = (oldIndex, newIndex) => {
    setOldies((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const onSortEnd = (oldIndex, newIndex) => {
    setImages((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  function onChange(event) {
    setImages([...images, ...event.target.files]);
  }

  async function onSubmit() {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("typeId", Id);
    images.map((file) => {
      formData.append("images", file);
    });
    postMutate(formData);
  }

  function resort() {
    const data = {
      type,
      typeId: Id,
      images: oldies,
    };
    resortMutate(data);
  }

  let img1;

  function handleDelete(index) {
    img1 = images;
    img1.splice(index, 1);
    setImages([...img1]);
  }

  const handleCheckboxChange = (image) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(image)
        ? prevSelected.filter((i) => i !== image)
        : [...prevSelected, image]
    );
  };

  const bulkDeleteImages = () => {
    let data = {
      images: selectedImages,
      type: type,
      typeId: Id,
    };
    deleteMutate(data);
  };

  const CheckboxElement = ({ index, image }) => {
    return (
      <Checkbox
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
        iconSize="3rem"
        isChecked={selectedImages.includes(image)}
        onChange={() => handleCheckboxChange(image)}
        mr={2}
        _checked={{
          bg: "red.500",
          borderColor: "red.500",
          color: "white",
          "& > span": {
            bg: "red.500",
            borderColor: "red.500",
            "&:hover": {
              bg: "red.400", // Hover effect
            },
          },
        }}
        sx={{
          width: "24px",
          height: "24px",
          "& > span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
          },
          "&:hover": {
            bg: "red.400",
          },
        }}
      />
    );
  };
  return (
    <>
      <Icon cursor={"pointer"} as={BsImage} onClick={onOpen} />

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Images</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={"20"}>
            <Box ml="10px" mt="20px">
              <Box mb="20px">
                <Input
                  type="file"
                  multiple
                  accept="image/*"
                  pt={1}
                  onChange={(e) => onChange(e)}
                />
              </Box>

              <Box
                className="uploadedImages"
                hidden={images.length === 0}
                mb={"10"}
              >
                <Box mt={"4"} mb={"4"}>
                  <Text fontSize={"18"} fontWeight={"semibold"}>
                    Uploaded Images Draft
                  </Text>
                </Box>

                {imagesUrl?.map((image, i) => (
                  <Box w="200px" h="200px" style={{ position: "relative" }}>
                    <Image
                      draggable={false}
                      src={image}
                      alt=""
                      objectFit={"cover"}
                      width={"100%"}
                      height={"100%"}
                    />
                    <Icon
                      cursor={"pointer"}
                      as={FiMinus}
                      w={8}
                      h={8}
                      onClick={() => handleDelete(i)}
                      color={"white"}
                      style={{
                        background: "#E53E3E",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "0",
                        right: "-15px",
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Divider orientation="horizontal" />

              <Flex
                wrap={"wrap"}
                hidden={mode === "resort"}
                gap={"24px"}
                mt={"10"}
              >
                {oldies?.map((img, i) => (
                  <Box
                    w="250px"
                    h="250px"
                    style={{ position: "relative" }}
                    key={i}
                  >
                    <CheckboxElement index={i} image={img} />
                    <Image
                      style={{
                        border: selectedImages.includes(img)
                          ? "red 2px solid"
                          : "none",
                      }}
                      cursor="pointer"
                      onClick={() => handleCheckboxChange(img)}
                      draggable={false}
                      objectFit={"cover"}
                      width={"100%"}
                      height={"100%"}
                      src={img}
                    />
                  </Box>
                ))}
              </Flex>
              <div hidden={mode === "delete"}>
                <SortableList
                  onSortEnd={onSortOldies}
                  draggedItemClassName="draggedItem"
                  className="draggedItem"
                  style={{ gap: "24px" }}
                >
                  {oldies?.map((img, i) => (
                    <SortableItem key={img + i}>
                      <Box w="250px" h="250px" style={{ position: "relative" }}>
                        <LazyImage
                          src={img}
                          alt={img}
                          effect="blur"
                          height="100%"
                          width="100%"
                          style={{ cursor: "grab", objectFit: "cover" }}
                        />
                        {/* <Image
                          cursor={"grab"}
                          src={img}
                          objectFit={"cover"}
                          loading="lazy"
                        /> */}
                      </Box>
                    </SortableItem>
                  ))}
                </SortableList>
              </div>
            </Box>
          </ModalBody>

          <ModalFooter
            position={"fixed"}
            bottom={0}
            background={"white"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            {mode === "resort" && (
              <Button
                ml={5}
                onClick={() => setMode("delete")}
                colorScheme="red"
              >
                Remove photos
              </Button>
            )}
            <div hidden={mode === "resort"}>
              <Button
                ml={5}
                onClick={() => {
                  setMode("resort");
                  setSelectedImages([]);
                }}
                colorScheme="gray"
              >
                Cancel
              </Button>
              <Button
                ml={5}
                onClick={() => bulkDeleteImages()}
                colorScheme="red"
                disabled={selectedImages.length === 0}
              >
                Delete{" "}
                {selectedImages.length > 0 ? `(${selectedImages.length})` : ""}
              </Button>
            </div>

            <div>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={onSubmit}
                colorScheme="green"
                disabled={images.length === 0}
              >
                Upload
              </Button>
              <Button ml={5} onClick={() => resort()} colorScheme="purple">
                Resort
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddImages;
