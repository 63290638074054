import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  VStack,
  Image,
  Checkbox,
  Switch,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/amenitiesApi";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

function AddAmenities({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [isFacility, setIsFacility] = useState(false);

  const [image, setImage] = useState();
  const queryClient = useQueryClient();

  //   Get Term Data for Edit

  const { data: Amenity } = useQuery(
    ["Amenity", id],
    () => api.getAmenityById(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  //UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateAmenity, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Amenities"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  //POST
  const { mutate: postMutate } = useMutation([], api.addAmenity, {
    onSuccess: () => {
      toast({
        title: "Successfully Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      queryClient.invalidateQueries(["Amenities"]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (Amenity) {
      setName(Amenity.name);
      setIsFeatured(Amenity.isFeatured);
      setIsFacility(Amenity.isFacility);
      setImage(Amenity.image);
    }
  }, [Amenity, Id]);

  function handleUpdate() {
    const data = new FormData();
    data.append("id", id);
    data.append("name", name);
    data.append("isFeatured", isFeatured);
    data.append("isFacility", isFacility);
    data.append("image", image);
    updateMutate(data);
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  function handleClose() {
    onClose();
  }

  async function handlePost() {
    const data = new FormData();
    data.append("name", name);
    data.append("isFeatured", isFeatured);
    data.append("isFacility", isFacility);
    data.append("image", image);
    postMutate(data);
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Amenities
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Edit ? "Edit Amenities" : "Add Amenities"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Info */}
            <VStack
              alignItems={"start"}
              justifyContent={"start"}
              spacing={"15px"}
            >
              <InputGroup>
                <InputLeftAddon children="Name:" />
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <Flex alignItems={"center"}>
                  <InputLeftAddon mr={"4"} children="isFeatured:" />
                  <Switch
                    size={"lg"}
                    isChecked={isFeatured}
                    onChange={(e) => setIsFeatured(e.target.checked)}
                    id="email-alerts"
                  />
                </Flex>
              </InputGroup>
              <InputGroup>
                <Flex alignItems={"center"}>
                  <InputLeftAddon mr={"4"} children="isFacility:" />
                  <Switch
                    size={"lg"}
                    defaultChecked={isFacility}
                    isChecked={isFacility ? true : false}
                    onChange={(e) => setIsFacility(e.target.checked)}
                    id="facility"
                  />
                </Flex>
              </InputGroup>
              <InputGroup>
                <InputLeftAddon>
                  {image ? (
                    <Image w={"30px"} h={"30px"} src={image} alt="" />
                  ) : (
                    "Image:"
                  )}{" "}
                </InputLeftAddon>
                <Input
                  type="file"
                  pt={"1"}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </InputGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={() => handleUpdate()}
                bg={"green"}
                textColor={"white"}
              >
                Update <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() => handlePost()}
                bg={"green"}
                textColor={"white"}
              >
                Add <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddAmenities;
