import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import * as api from "../components/API/amenitiesApi";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { GlobalFilter } from "../components/GlobalFilter";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddAmenities from "../components/UI/AddAmenities";
import { FiTrash } from "react-icons/fi";
import AlertDialogModal from "../components/UI/AlertDialogModal";

function Amenities() {
  const queryClient = useQueryClient();
  const { data } = useQuery("Amenities", api.getAmenities);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const { mutate: updateMutate } = useMutation([], api.updateAmenity, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Amenities"]);
    },
  });
  // Delete
  const { mutate: deleteMutate } = useMutation([], api.deleteAmenity, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Amenities"]);
      setIsOpen(false);
    },
  });

  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      usePagination,
      tableHooks
      // useSortBy
    );

    const isEven = (idx) => idx % 2 === 0;
    // Render the UI for your table
    return (
      <Box
        ml={10}
        my={{ base: "20" }}
        minW={{ base: "75vw" }}
        maxW={{ base: "80vw" }}
      >
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          <AddAmenities Edit={false} />
        </Flex>
        {/* Table */}
        <Table className="table" {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }

  const productsColumns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Id",
      //   accessor: "_id",
      //   Cell: ({ value }) => <Box _hover={{color:"blue"}}><Link to={`/productcategory/${value}`}  >{`${value}`}</Link></Box>
      // },

      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "is Feature",
        accessor: "isFeatured",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "is Facility",
        accessor: "isFacility",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
    ];
    return columns;
  }, []);

  function handleOpenAlert(row) {
    setIsOpen(true);
    setSelectedRow(row);
  }

  function handleToggle(row) {
    const enabled = !row.values.enabled;

    const data = new FormData();
    data.append("id", row.original._id);
    data.append("enabled", enabled);

    updateMutate(data);
  }
  async function handleDelete(row) {
    if (row.original._id) {
      deleteMutate(row.original._id);
    }
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }) => (
          <HStack
            spacing={"2"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/* <Icon cursor={"pointer"} boxSize={"5"} mr={2} onClick={() => handleToggle(row)} as={row.values.enabled ? BsEyeSlash : BsEye}  /> */}
            {row && <AddAmenities Id={row.original._id} Edit={true} />}
            <Icon
              boxSize={"5"}
              cursor={"pointer"}
              mx={2}
              as={FiTrash}
              onClick={() => handleOpenAlert(row)}
            />
          </HStack>
        ),
      },
    ]);
  };

  return data ? (
    <Box>
      <AlertDialogModal
        onClose={onClose}
        isOpen={isOpen}
        handleDelete={handleDelete}
        setIsOpen={setIsOpen}
        cancelRef={cancelRef}
        row={selectedRow}
      />
      <RTable columns={productsColumns} data={data} />
    </Box>
  ) : (
    <div></div>
  );
}

export default Amenities;
