import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../config'

const api = axios.create({
    baseURL : `${baseURL}/v1/propertyBooking/`,
    headers: {'authorization':Cookies.get('admin-token')}
})

export const getStayBookings = async() =>  await api.get('').then(res => res.data.propertyBookings)

export const getStayBookingById = async(id) =>  await api.get('/'+id).then(res => res.data.propertyBookings)

export const editBooking = async(body) =>  {
    return   api.put('admin/edit/booking',body).then((res) => res.data.city)
   }
   export const check = (body) => {
    return api.post(`admin/check-availability?bookingId=${body.id}`,body).then((res) => res.data)
  }
  export const cancelBooking = (body) => {
    return api.post(`admin/cancel/booking`,body).then((res) => res.data)
  }

  export const getCancelDetails = async(id) =>  await api.get('cancel/policy/'+id).then(res => res.data)