import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";
import * as api from "../components/API/calendarApi";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GlobalFilter } from "../components/GlobalFilter";
import { useQuery } from "react-query";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import dayjs from "dayjs";

function Overview() {
  const { data: UpcomingStays } = useQuery("upstays", api.getUpComingStay);
  const { data: DepartureStays } = useQuery("depstays", api.getDepartureStay);
  const { data: UpcomingTours } = useQuery("uptours", api.getUpComingTour);

  function RTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    const isEven = (idx) => idx % 2 === 0;
    // Render the UI for your table
    return (
      <Box mt={4} minW={{ base: "75vw" }} maxW={{ base: "80vw" }}>
        {/* Button and Search */}
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
        </Flex>
        {/* Table */}
        <Table className="table" {...getTableProps()} border={"2px"}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  bg={isEven(i) ? "blue.100" : "white"}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* Pagination */}
        <HStack mt={"10"} spacing={"10px"}>
          <Box
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <Button>First</Button>
          </Box>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            Last
          </Button>
          <Button>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </Button>
          <Select
            cursor={"pointer"}
            w={"32"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </Box>
    );
  }

  const upcomingColumns = useMemo(() => {
    const columns = [
      {
        Header: "Property Name",
        accessor: "property.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Guest Name",
        accessor: "user.fullName.first",
        Cell: (row) => {
          return (
            <div>
              {row?.row.original?.user?.fullName?.first +
                " " +
                row?.row.original?.user?.fullName?.last}
            </div>
          );
        },
      },
      {
        Header: "Number Of Guests",
        accessor: "numberOfGuests.adults",
        Cell: (row) => (
          <HStack>
            <Text fontWeight={"semibold"}>
              Adults: <span>{row?.row.original?.numberOfGuests?.adults}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Children:{" "}
              <span>{row?.row.original?.numberOfGuests?.childrens}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Infants: <span>{row?.row.original?.numberOfGuests?.infants}</span>
            </Text>
          </HStack>
        ),
      },
      {
        Header: "checkIn",
        accessor: "checkInDate",
        Cell: ({ value }) => (
          <div>{`${dayjs(value).format("DD-MM-YYYY")}`}</div>
        ),
      },
    ];
    return columns;
  }, []);

  const departureColumns = useMemo(() => {
    const columns = [
      {
        Header: "Property Name",
        accessor: "property.name",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },
      {
        Header: "Guest Name",
        accessor: "user.fullName.first",
        Cell: (row) => {
          return (
            <div>
              {row?.row.original?.user?.fullName?.first +
                " " +
                row?.row.original?.user?.fullName?.last}
            </div>
          );
        },
      },
      {
        Header: "Number Of Guests",
        accessor: "numberOfGuests.adults",
        Cell: (row) => (
          <HStack>
            <Text fontWeight={"semibold"}>
              Adults: <span>{row?.row.original?.numberOfGuests?.adults}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Children:{" "}
              <span>{row?.row.original?.numberOfGuests?.childrens}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Infants: <span>{row?.row.original?.numberOfGuests?.infants}</span>
            </Text>
          </HStack>
        ),
      },
      {
        Header: "checkOut",
        accessor: "checkOutDate",
        Cell: ({ value }) => (
          <div>{`${dayjs(value).format("DD-MM-YYYY")}`}</div>
        ),
      },
    ];
    return columns;
  }, []);

  const toursColumns = useMemo(() => {
    const columns = [
      {
        Header: "Tour Name",
        accessor: "title",
        Cell: ({ value }) => <div>{`${value}`}</div>,
      },

      {
        Header: "Number Of Guests",
        accessor: "numberOfGuests.adults",
        Cell: (row) => (
          <HStack>
            <Text fontWeight={"semibold"}>
              Adults: <span>{row?.row.original?.numberOfGuests?.adults}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Children:{" "}
              <span>{row?.row.original?.numberOfGuests?.childrens}</span>
            </Text>
            <Text fontWeight={"semibold"}>
              Infants: <span>{row?.row.original?.numberOfGuests?.infants}</span>
            </Text>
          </HStack>
        ),
      },
      {
        Header: "Sold Out",
        accessor: "soldout",
        Cell: ({ value }) => (
          <Text fontWeight="bold" color={value ? "red.400" : "green.500"}>
            {value ? "Yes" : "No"}
          </Text>
        ),
      },
      {
        Header: "Day",
        accessor: "day",
        Cell: ({ value }) => (
          <div>{`${dayjs(value).format("DD-MM-YYYY")}`}</div>
        ),
      },
    ];
    return columns;
  }, []);

  return UpcomingStays ? (
    <Box ml={10} my={{ base: "20" }}>
      <Flex justifyContent={"space-between"} mb={10} mt={4}>
        <Box
          border={"2px solid"}
          w={"25%"}
          py={6}
          borderColor={"#FED438"}
          rounded={"md"}
          shadow={"lg"}
        >
          <Text textAlign={"center"} fontSize={"50px"} color={"red.400"}>
            {UpcomingStays?.length}
          </Text>
          <Text textAlign={"center"} fontSize={"14px"}>
            Upcoming Checkin
          </Text>
          <Text textAlign={"center"} fontSize={"11px"} textColor={"gray.600"} mt={"1"} fontWeight={"sm"}>Check In Next Three Days</Text>
        </Box>
        <Box
          border={"2px solid"}
          w={"25%"}
          py={6}
          borderColor={"#FED438"}
          rounded={"md"}
          shadow={"lg"}
        >
          <Text textAlign={"center"} fontSize={"50px"} color={"blue.400"}>
            {DepartureStays?.length}
          </Text>
          <Text textAlign={"center"} fontSize={"14px"}>
            Upcoming Checkout
          </Text>
          <Text textAlign={"center"} fontSize={"11px"} textColor={"gray.600"} mt={"1"} fontWeight={"sm"}>Check Out Next Three Days</Text>
        </Box>
        <Box
          border={"2px solid"}
          w={"25%"}
          py={6}
          borderColor={"#FED438"}
          rounded={"md"}
          shadow={"lg"}
        >
          <Text textAlign={"center"} fontSize={"50px"} color={"purple.400"}>
            {UpcomingTours?.length}
          </Text>
          <Text textAlign={"center"} fontSize={"14px"}>
            Upcoming Tour
          </Text>
          <Text textAlign={"center"} fontSize={"11px"} textColor={"gray.600"} mt={"1"} fontWeight={"sm"}>Tours Next Five Days</Text>
        </Box>
      </Flex>
      <Tabs>
        <TabList>
          <Tab>Upcoming Stays</Tab>
          <Tab>Departure Stays</Tab>
          <Tab>Upcoming Tours</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {UpcomingStays && (
              <RTable columns={upcomingColumns} data={UpcomingStays} />
            )}
          </TabPanel>
          <TabPanel>
            {DepartureStays && (
              <RTable columns={departureColumns} data={DepartureStays} />
            )}
          </TabPanel>
          <TabPanel>
            {UpcomingTours && (
              <RTable columns={toursColumns} data={UpcomingTours} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <div></div>
  );
}

export default Overview;
