import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Select,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
  } from "@chakra-ui/react";
  import { useMemo, useRef, useState } from "react";
  import * as api from '../components/API/bedApi'
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
  } from "react-table";
  import { GlobalFilter } from "../components/GlobalFilter";
  import { useMutation, useQuery, useQueryClient } from "react-query";
  
import {FiTrash } from "react-icons/fi";
import AlertDialogModal from "../components/UI/AlertDialogModal";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import AddBed from "../components/UI/AddBed";



  function Beds() {
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedRow,setSelectedRow] = useState()
    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()
    const toast = useToast()
    const { data } = useQuery("AllBeds", api.getBedrooms);

    const { mutate: updateMutate } = useMutation([], api.updateBedroom, {
      onSuccess: () => {
        queryClient.invalidateQueries(["AllBeds"]);
        toast({
            title: 'updated Successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
      },
    });
    // Delete
    const { mutate:deleteMutate } = useMutation([],api.deleteBedroom, {
        onSuccess:
          () => {
              queryClient.invalidateQueries(["AllBeds"]);
              toast({
                title: 'deleted Successfully',
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
              setIsOpen(false)
        },
        onError:(error) => {
          toast({
            title: error.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
    })

    function RTable({ columns, data }) {
      // Use the state and functions returned from useTable to build your UI
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        state: { pageIndex, pageSize }
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 10 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        tableHooks
      );
    
      const isEven = (idx) => idx % 2 === 0;
      // Render the UI for your table
      return (
        <Box  ml={10} my={{base:"20"}} minW={{base:"75vw"}} maxW={{base:"80vw"}}>
          {/* Button and Search */}
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={state.globalFilter}
            />
            <HStack spacing={"5"}>
           
            <AddBed Edit={false} />
            </HStack>
          </Flex>
          {/* Table */}
          <Table minW={"100%"} width={"full"}  {...getTableProps()} border={"2px"}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th  {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                  </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    bg={isEven(i) ? "blue.100" : "white"}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {/* Pagination */}
          <HStack mt={"10"} spacing={"10px"}>
            <Box
              className="page-item"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <Button>First</Button>
            </Box>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last
            </Button>
            <Button>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </Button>
            <Select
              cursor={"pointer"}
              w={"32"}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: "120px", height: "38px" }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Select>
          </HStack>
        </Box>
      );
    }
    
    const productsColumns = useMemo(() => {
      const columns = [
        // {
        //   Header: "Id",
        //   accessor: "_id",
        //   Cell: ({ value }) => <Box _hover={{color:"blue"}}><Link to={`/admin/${value}`}  >{`${value}`}</Link></Box>
        // },
  
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }) => <div>{`${value}`}</div>
        },
        
          {
            Header: "Enabled",
            accessor: "enabled",
            Cell: ({ value }) => <div>{`${value}`}</div>
          },
      ];
      return columns;
    }, []);

  function handleToggle(row){
        const data = {
          id:row.original._id,
          enabled: !row.values.enabled
        }
        updateMutate(data)
     }
     function handleOpenAlert(row){
      setIsOpen(true)
      setSelectedRow(row)
    }
 async function handleDelete(row){
        if(row.original._id){
            deleteMutate(row.original._id)
        }
    }

    const tableHooks = (hooks) => {
      
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "action",
          Header: "Action",
          Cell: ({ row }) => (
            <HStack spacing={"2"} alignItems={"center"} justifyContent={"space-between"}>
                 <Icon cursor={"pointer"} boxSize={"5"} mx={2} onClick={() => handleToggle(row)} as={row.original.enabled ? BsEyeSlash : BsEye}  />
              {row && <AddBed Id={row.original._id} Edit={true}  /> }
              <Icon boxSize={"5"} cursor={"pointer"} mx={2} as={FiTrash} onClick={() => handleOpenAlert(row)} />
            </HStack>
          )
        }
      ]);
    };
  
    return (data) ? (
      <Box>
<AlertDialogModal onClose={onClose} isOpen={isOpen} handleDelete={handleDelete} setIsOpen={setIsOpen} cancelRef={cancelRef} row={selectedRow} />
      <RTable columns={productsColumns} data={data} />
      </Box>
    ) : (
      <div></div>
    );
  }
  
  export default Beds;
  